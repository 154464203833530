
import { Col } from "../../basic";
import { TransformedCoverSheetData, CoverSheetEmployer, CoverSheetApplicant, ScheduleCEntity, SCorporation } from "@/data/coversheet";
import { EmployerCard } from "./EmployerCard";
import { ScheduleCEntityCard } from "./ScheduleCEntityCard";
import { SCorporationCard } from "./SCorporationCard";

type Props = {
  primary: TransformedCoverSheetData["primaryApplicant"];
  joint: TransformedCoverSheetData["jointApplicants"];
};

export function IncomeSection({ primary, joint }: Props): JSX.Element {
  return (
      <Col gap="md" alignVertical="top">
          
      <Col gap="sm" alignVertical="top">
              <h3>{ primary.fullname }</h3>
        {primary.w2Employers.map((employer: CoverSheetEmployer, index: number) => (
          <EmployerCard key={index} employer={employer} />
        ))}
        {primary.scheduleCEntities && primary.scheduleCEntities.map((entity: ScheduleCEntity, index: number) => (
          <ScheduleCEntityCard key={`schedule-c-${index}`} entity={entity} />
        ))}
        {primary.sCorporations && primary.sCorporations.map((entity: SCorporation, index: number) => (
          <SCorporationCard key={`s-corporation-${index}`} entity={entity} />
        ))}
      </Col>

      {joint.length > 0 && (
        <Col gap="sm" alignVertical="top">
          {joint.map((applicant: CoverSheetApplicant, index: number) => (
            <Col key={index} gap="sm" alignVertical="top">
              <h3>{applicant.fullname}</h3>
              {applicant.w2Employers.map((employer: CoverSheetEmployer, employerIndex: number) => (
                <EmployerCard key={employerIndex} employer={employer} />
              ))}
              {applicant.scheduleCEntities && applicant.scheduleCEntities.map((entity: ScheduleCEntity, entityIndex: number) => (
                <ScheduleCEntityCard key={`schedule-c-${entityIndex}`} entity={entity} />
              ))}
              {applicant.sCorporations && applicant.sCorporations.map((entity: SCorporation, entityIndex: number) => (
                <SCorporationCard key={`s-corporation-${entityIndex}`} entity={entity} />
              ))}
            </Col>
          ))}
        </Col>
      )}
    </Col>
  );
}
