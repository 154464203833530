import { ChildProps } from "@/utils/reducerWithEffect";
import { Model } from "./model";
import {
  Action,
  RealEstateAgentNameChanged,
  RealEstateAgentPhoneChanged,
  ReferralSourceChanged,
  WorkingWithRealEstateAgentChanged,
} from "./action";
import {
  Col,
  InputField,
  Label,
  PhoneInputField,
  RadioButton,
  Select,
} from "@/components/basic";
import {
  EqReferralSource,
  referralSourceOptions,
  showReferralSource,
} from "@/data/payload";
import * as O from "fp-ts/Option";
import { constant, flow } from "fp-ts/function";
import { ViewResolver } from "@/utils/viewResolver";

export type Props = ChildProps<Model, Action>;

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  return (
    <div className="grid-2 gap-xs collapse-gap-md">
      <Col gap="xs">
        <Label>Who referred you to the Loan Officer? *</Label>
        <Select
          placeholder="Please select"
          options={referralSourceOptions}
          selected={O.fromEither(model.referralSource.val)}
          valueEq={EqReferralSource}
          renderLabel={showReferralSource}
          onChange={flow(ReferralSourceChanged, dispatch)}
        />
      </Col>
      <></>

      <Col gap="xs" className={ViewResolver({
          viewModes: [["Default"], ["Mobile-Portrait", "Mobile-Landscape"]],
          resolvedContent: ["column-span-2", ""],
        })}>
        <Label>Are you currently working with a real estate agent?</Label>
        <RadioButton
          label="Yes"
          checked={model.isWorkingWithAgent === true}
          onChange={flow(
            constant(true),
            WorkingWithRealEstateAgentChanged,
            dispatch,
          )}
        />
        <RadioButton
          label="No"
          checked={model.isWorkingWithAgent === false}
          onChange={flow(
            constant(false),
            WorkingWithRealEstateAgentChanged,
            dispatch,
          )}
        />
      </Col>

      {model.isWorkingWithAgent && (
        <>
          <InputField
            label="Primary contact"
            field={model.realEstateAgentName}
            onChange={flow(RealEstateAgentNameChanged, dispatch)}
          />

          <PhoneInputField 
            label="Contact number"
            field={model.realEstateAgentPhoneNumber}
            onChange={flow(RealEstateAgentPhoneChanged, dispatch)}
          />
        </>
      )}
    </div>
  );
}
