import { loginRequest, msalConfig } from "@/auth/authConfig";
import {
  ApplicationDocument,
  DocumentId,
  DocumentTypeId,
  applicationDocumentCodec,
} from "@/data/applicationDocument";
import { coverSheetDataCodec } from "@/data/coversheet";
import {
  ApplicationListingResponse,
  ApplicationsFilterPayload,
  applicationListingResponseCodec,
  applicationsFilterCodec,
} from "@/data/applicationsList";
import {
  BranchId,
  Client,
  ClientId,
  ClientLogoResponse,
  ClientStatusResponse,
  ClientsReassignment,
  PaymentProviderMeta,
  TeamId,
  User,
  UserId,
  clientCodec,
  clientLogoResponseCodec,
  clientStatusResponseCodec,
  paymentProviderMetaCodec,
  paymentProviderRequestCodec,
  userCodec,
} from "@/data/client";
import {
  ConsentItem,
  GetVerifyConsentPayload,
  SaveConsentsPayload,
  consentCodec,
  getVerifyConsentCodec,
  saveConsentsCodec,
} from "@/data/consents";
import {
  DashboardAnalytics,
  dashboardAnalyticsCodec,
} from "@/data/dashboardAnalytics";
import {
  AccountSettingsPayload,
  AccountUserPayload,
  Application,
  ApplicationId,
  BorrowerVerifyTokenResponse,
  BranchPayload,
  ClientSettingsPayload,
  CreateClientPayload,
  CreateClientResponse,
  EmployersPatchPayload,
  ReferringLoanOfficerInfo,
  SaveLoanOfficerNotesPayload,
  SaveSurveyResult,
  SurveyPayload,
  TeamPayload,
  UserPayload,
  accountSettingsPayloadCodec,
  accountUserPayloadCodec,
  applicationCodec,
  borrowerVerifyTokenResponseCodec,
  branchPayloadCodec,
  createClientPayloadCodec,
  createClientResponseCodec,
  editBranchPayloadCodec,
  editTeamPayloadCodec,
  editUserPayloadCodec,
  employerGeneralPayload,
  referringLoanOfficerInfoCodec,
  saveLoanOfficerNotesPayloadCodec,
  saveSurveyResultCodec,
  surveyPayloadCodec,
  teamPayloadCodec,
  userPayloadCodec,
} from "@/data/payload";
import { SurveyTip, surveyTipCodec } from "@/data/surveyTips";
import {
  AuthenticationResult,
  IPublicClientApplication,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import * as A from "fp-ts/lib/Array";
import { identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";
import * as T from "fp-ts/lib/Task";
import { Task } from "fp-ts/lib/Task";
import * as TE from "fp-ts/lib/TaskEither";
import { TaskEither } from "fp-ts/lib/TaskEither";
import * as t from "io-ts";
import * as tt from "io-ts-types";

import {
  AssignmentsPayload,
  assignmentsPayloadCodec,
} from "@/data/application-assignments";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import {
  ApiError,
  AuthenticationError,
  FormDataPayload,
  Get,
  JsonPayload,
  Patch,
  Post,
  RedirectState,
  formDataFromClientSettings,
  formDataFromFile,
  formDataFromFiles,
  makeAuthenticatedRequest,
  makeDownloadRequest,
  makeRequest,
} from "./request";
import applicationTransformer from "./transformers/application";

export type Api = {
  getUser: TE.TaskEither<ApiError, User>;
  tryGetUser: TE.TaskEither<ApiError, Option<User>>;
  checkToken: TE.TaskEither<ApiError, O.Option<string>>;
  getApplicationCoverSheetData: (applicationId: ApplicationId) => TE.TaskEither<ApiError, t.TypeOf<typeof coverSheetDataCodec>>;
  saveSurvey: (
    payload: SurveyPayload
  ) => TE.TaskEither<ApiError, SaveSurveyResult>;
  saveLoanOfficerNote: (
    payload: SaveLoanOfficerNotesPayload
  ) => TE.TaskEither<ApiError, void>;
  getCurrentApplication: TE.TaskEither<ApiError, Option<Application>>;
  submitApplication: (
    applicationId: ApplicationId
  ) => TE.TaskEither<ApiError, unknown>;
  getDocuments: (
    applicationId: ApplicationId
  ) => TE.TaskEither<ApiError, ApplicationDocument[]>;
  submitDocuments: (
    applicationId: ApplicationId
  ) => (files: File[]) => TE.TaskEither<ApiError, void>;
  submitImage: (
    applicationId: ApplicationId
  ) => (files: FormData) => TE.TaskEither<ApiError, void>;
  submitManualClassification: (
    applicationId: ApplicationId,
    documentTypeId: DocumentTypeId
  ) => (files: File) => TE.TaskEither<ApiError, void>;
  deleteDocument: (
    applicationId: ApplicationId,
    documentId: DocumentId
  ) => TE.TaskEither<ApiError, void>;
  exportDocuments: (
    applicationId: ApplicationId
  ) => TE.TaskEither<ApiError, string>;
  downloadDocument: (
    applicationId: ApplicationId,
    documentId: DocumentId
  ) => TE.TaskEither<ApiError, string>;
  downloadAllDocuments: (
    applicationId: ApplicationId
  ) => TE.TaskEither<ApiError, string>;
  postApplicationsList: (
    payload: ApplicationsFilterPayload
  ) => TE.TaskEither<ApiError, ApplicationListingResponse>;
  getApplication: (
    applicationId: ApplicationId
  ) => TE.TaskEither<ApiError, Application>;
  archiveApplication: (
    applicationId: ApplicationId
  ) => TE.TaskEither<ApiError, unknown>;
  assignUsersToApplication: (
    payload: AssignmentsPayload
  ) => TE.TaskEither<ApiError, unknown>;
  getBorrowerApplication: TE.TaskEither<ApiError, Application>;
  getDashboardAnalytics: (
    startDate: DateTime,
    endDate: DateTime
  ) => TE.TaskEither<ApiError, DashboardAnalytics>;
  getCurrentClient: TE.TaskEither<ApiError, Client>;
  getPaymentProviderUrl: TE.TaskEither<ApiError, PaymentProviderMeta>;
  createNewBranch: (payload: BranchPayload) => TE.TaskEither<ApiError, unknown>;
  updateBranch: (
    clientId: ClientId,
    branchId: BranchId
  ) => (payload: BranchPayload) => TE.TaskEither<ApiError, unknown>;
  deleteBranch: (branchId: BranchId) => TE.TaskEither<ApiError, unknown>;
  createNewTeam: (payload: TeamPayload) => TE.TaskEither<ApiError, unknown>;
  updateTeam: (
    teamId: TeamId
  ) => (payload: TeamPayload) => TE.TaskEither<ApiError, unknown>;
  deleteTeam: (teamId: TeamId) => TE.TaskEither<ApiError, unknown>;
  createNewUser: (payload: UserPayload) => TE.TaskEither<ApiError, unknown>;
  verifyBorrowerToken: (
    token: string
  ) => TE.TaskEither<ApiError, BorrowerVerifyTokenResponse>;
  updateUser: (
    userId: UserId
  ) => (payload: UserPayload) => TE.TaskEither<ApiError, unknown>;
  deleteUser: (userId: UserId) => TE.TaskEither<ApiError, unknown>;
  deleteUserWithReassignment: (
    userId: UserId,
    reassignment: ClientsReassignment
  ) => TE.TaskEither<ApiError, unknown>;
  saveAccountUser: (
    payload: AccountUserPayload
  ) => TE.TaskEither<ApiError, unknown>;
  saveAccountSettings: (
    payload: AccountSettingsPayload
  ) => TE.TaskEither<ApiError, unknown>;
  createClient: (
    payload: CreateClientPayload
  ) => TE.TaskEither<ApiError, CreateClientResponse>;
  getClientStatus: () => TE.TaskEither<ApiError, ClientStatusResponse>;
  getClientLogo: () => TE.TaskEither<ApiError, ClientLogoResponse>;
  updateClientSettings: (
    clientSettingsPayload: ClientSettingsPayload
  ) => TE.TaskEither<ApiError, unknown>;
  initPaymentSetup: TE.TaskEither<ApiError, unknown>;
  surveyTips: TE.TaskEither<ApiError, SurveyTip[]>;
  getConsents: TE.TaskEither<ApiError, ConsentItem[]>;
  getVerificationStatus: TE.TaskEither<ApiError, GetVerifyConsentPayload>;
  putEmployer: (
    payload: EmployersPatchPayload
  ) => TE.TaskEither<ApiError, unknown>;
  putVerificationStatus: (
    payload: SaveConsentsPayload
  ) => TE.TaskEither<ApiError, unknown>;
  unfinishApplicationExit: () => TE.TaskEither<ApiError, unknown>;
  getReferringLoanOfficerInfo: (
    userId: number
  ) => TE.TaskEither<ApiError, ReferringLoanOfficerInfo>;

};

const getUserTask = (token: string): TE.TaskEither<ApiError, User> =>
  pipe(
    token,
    makeAuthenticatedRequest(
      `${import.meta.env.VITE_API_URL}/user`,
      { method: "GET" },
      userCodec.decode
    )
  );

const makeApi = (
  tokenTriggerAuth: TaskEither<ApiError, string>, // token acquisition task that triggers auth if necessary
  tryTokenActiveSession: TaskEither<ApiError, Option<string>> // token acquisition task that uses active session if available and does not trigger auth
): Api => ({
  getApplicationCoverSheetData: (applicationId) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Application/${applicationId}/CoverSheetData`,
          { method: "GET" },
          coverSheetDataCodec.decode
        )
      )
    ),
  getUser: pipe(tokenTriggerAuth, TE.chain(getUserTask)),
  tryGetUser: pipe(
    tryTokenActiveSession,
    TE.chain(O.traverse(TE.ApplicativeSeq)(getUserTask))
  ),
  checkToken: pipe(tryTokenActiveSession),
  saveSurvey: (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/Survey`,
          pipe(payload, JsonPayload(surveyPayloadCodec.encode), Post),
          saveSurveyResultCodec.decode
        )
      )
    ),
  saveLoanOfficerNote: (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Application/LoanOfficerNote`,
          pipe(
            payload,
            JsonPayload(saveLoanOfficerNotesPayloadCodec.encode),
            Post
          ),
          t.voidType.decode
        )
      )
    ),

  getCurrentApplication: pipe(
    tokenTriggerAuth,
    TE.chain(
      makeAuthenticatedRequest(
        `${import.meta.env.VITE_API_URL}/api/application`,
        { method: "GET" },
        tt.optionFromNullable(applicationCodec).decode
      )
    )
  ),
  submitApplication: (applicationId) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${
            import.meta.env.VITE_API_URL
          }/api/application/${applicationId}/submit`,
          { method: "GET" },
          t.unknown.decode
        )
      )
    ),

  getDocuments: (applicationId: ApplicationId) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${
            import.meta.env.VITE_API_URL
          }/api/Application/${applicationId}/RequiredDocuments`,
          { method: "GET" },
          t.array(applicationDocumentCodec).decode
        )
      )
    ),
  submitDocuments: (applicationId: ApplicationId) => (files) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${
            import.meta.env.VITE_API_URL
          }/api/Application/${applicationId}/File`,
          pipe(files, formDataFromFiles, Post),
          t.unknown.decode
        )
      ),
      TE.map(() => undefined)
    ),
  submitImage: (applicationId: ApplicationId) => (files) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${
            import.meta.env.VITE_API_URL
          }/api/Application/${applicationId}/File`,
          pipe(files, FormDataPayload, Post),
          t.unknown.decode
        )
      ),
      TE.map(() => undefined)
    ),
  submitManualClassification:
    (applicationId: ApplicationId, documentTypeId: DocumentTypeId) => (file) =>
      pipe(
        tokenTriggerAuth,
        TE.chain(
          makeAuthenticatedRequest(
            `${
              import.meta.env.VITE_API_URL
            }/api/Application/${applicationId}/File/${documentTypeId}`,
            pipe(file, formDataFromFile, Post),
            t.unknown.decode
          )
        ),
        TE.map(() => undefined)
      ),

  deleteDocument: (applicationId: ApplicationId, documentId: DocumentId) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${
            import.meta.env.VITE_API_URL
          }/api/Application/${applicationId}/File/${documentId}`,
          { method: "DELETE" },
          t.null.decode
        )
      ),
      TE.map(() => undefined)
    ),
  exportDocuments: (applicationId: ApplicationId) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeDownloadRequest(
          `${
            import.meta.env.VITE_API_URL
          }/api/Application/${applicationId}/Export`,
          { method: "GET" }
        )
      )
    ),
  downloadDocument: (applicationId: ApplicationId, documentId: DocumentId) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeDownloadRequest(
          `${
            import.meta.env.VITE_API_URL
          }/api/Application/${applicationId}/File/${documentId}`,
          { method: "GET" }
        )
      )
    ),
  downloadAllDocuments: (applicationId: ApplicationId) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeDownloadRequest(
          `${
            import.meta.env.VITE_API_URL
          }/api/Application/${applicationId}/DownloadFiles`,
          { method: "GET" }
        )
      )
    ),
  postApplicationsList: (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/User/Applications`,
          pipe(payload, JsonPayload(applicationsFilterCodec.encode), Post),
          applicationListingResponseCodec.decode
        )
      )
    ),
  getApplication: (applicationId: ApplicationId) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Application/${applicationId}`,
          { method: "GET" },
          applicationCodec.decode,
          applicationTransformer
        )
      )
    ),

  archiveApplication: (applicationId: ApplicationId) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Application/${applicationId}/Archive`,
          pipe(undefined, JsonPayload(t.void.encode), Patch),
          t.unknown.decode
        )
      )
    ),

  assignUsersToApplication: (payload: AssignmentsPayload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Application/Assignments`,
          pipe(payload, JsonPayload(assignmentsPayloadCodec.encode), Post),
          t.unknown.decode
        )
      )
    ),
  getBorrowerApplication: pipe(
    tokenTriggerAuth,
    TE.chain(
      makeAuthenticatedRequest(
        `${import.meta.env.VITE_API_URL}/api/Application`,
        { method: "GET" },
        applicationCodec.decode,
        applicationTransformer
      )
    )
  ),
  getDashboardAnalytics: (startDate, endDate) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${
            import.meta.env.VITE_API_URL
          }/api/Dashboard/Analytics/${startDate.toISODate()}/${endDate.toISODate()}`,
          { method: "GET" },
          dashboardAnalyticsCodec.decode
        )
      )
    ),
  getCurrentClient: pipe(
    tokenTriggerAuth,
    TE.chain(
      makeAuthenticatedRequest(
        `${import.meta.env.VITE_API_URL}/api/Client`,
        { method: "GET" },
        clientCodec.decode
      )
    )
  ),
  getPaymentProviderUrl: pipe(
    tokenTriggerAuth,
    TE.chain(
      makeAuthenticatedRequest(
        `${import.meta.env.VITE_API_URL}/api/Client/BillingInfo`,
        pipe(
          { returnToPath: "/account-settings/billing" },
          JsonPayload(paymentProviderRequestCodec.encode),
          Post
        ),
        paymentProviderMetaCodec.decode
      )
    )
  ),
  createNewBranch: (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Client/Branches`,
          pipe(payload, JsonPayload(branchPayloadCodec.encode), Post),
          t.unknown.decode
        )
      )
    ),

  updateBranch: (clientId, branchId) => (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Client/Branches`,
          pipe(
            { ...payload, clientId, branchId },
            JsonPayload(editBranchPayloadCodec.encode),
            Patch
          ),
          t.unknown.decode
        )
      )
    ),

  deleteBranch: (branchId) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Client/Branches/${branchId}`,
          { method: "DELETE" },
          t.unknown.decode
        )
      )
    ),

  createNewTeam: (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Client/Teams`,
          pipe(payload, JsonPayload(teamPayloadCodec.encode), Post),
          t.unknown.decode
        )
      )
    ),

  updateTeam: (teamId) => (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Client/Teams`,
          pipe(
            { ...payload, teamId },
            JsonPayload(editTeamPayloadCodec.encode),
            Patch
          ),
          t.unknown.decode
        )
      )
    ),

  deleteTeam: (teamId) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Client/Teams/${teamId}`,
          { method: "DELETE" },
          t.unknown.decode
        )
      )
    ),

  createNewUser: (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/User`,
          pipe(payload, JsonPayload(userPayloadCodec.encode), Post),
          t.unknown.decode
        )
      )
    ),

  updateUser: (userId) => (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/User`,
          pipe(
            { ...payload, userId },
            JsonPayload(editUserPayloadCodec.encode),
            Post
          ),
          t.unknown.decode
        )
      )
    ),
  verifyBorrowerToken: (token) =>
    pipe(
      makeRequest(
        `${import.meta.env.VITE_API_URL}/User/ValidateInviteToken/${token}`,
        Get(),
        borrowerVerifyTokenResponseCodec.decode
      )
    ),

  deleteUserWithReassignment: (userId, reassignment) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/User/${userId}/${
            reassignment.reassignTo
          }`,
          { method: "DELETE" },
          t.unknown.decode
        )
      )
    ),

  deleteUser: (userId) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/User/${userId}`,
          { method: "DELETE" },
          t.unknown.decode
        )
      )
    ),

  saveAccountUser: (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/User/GeneralSettings`,
          pipe(payload, JsonPayload(accountUserPayloadCodec.encode), Patch),
          t.unknown.decode
        )
      )
    ),

  saveAccountSettings: (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/User/Settings`,
          pipe(payload, JsonPayload(accountSettingsPayloadCodec.encode), Patch),
          t.unknown.decode
        )
      )
    ),

  createClient: (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Client/Create`,
          pipe(payload, JsonPayload(createClientPayloadCodec.encode), Post),
          createClientResponseCodec.decode
        )
      )
    ),

  getClientStatus: () =>
    makeRequest(
      `${import.meta.env.VITE_API_URL}/api/Client/Status`,
      Get(),
      clientStatusResponseCodec.decode
    ),

  getClientLogo: () =>
    makeRequest(
      `${import.meta.env.VITE_API_URL}/api/Client/Logo`,
      Get(),
      clientLogoResponseCodec.decode
    ),

  updateClientSettings: (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Client/Update`,
          pipe(payload, formDataFromClientSettings, Patch),
          t.unknown.decode
        )
      )
    ),

  initPaymentSetup: pipe(
    tokenTriggerAuth,
    TE.chain(
      makeAuthenticatedRequest(
        `${import.meta.env.VITE_API_URL}/api/Client/InitPaymentMethodSetup`,
        Get(),
        t.unknown.decode
      )
    )
  ),
  surveyTips: makeRequest(
    `${import.meta.env.VITE_API_URL}/Survey/Tips`,
    Get(),
    t.array(surveyTipCodec).decode
  ),

  getConsents: makeRequest(
    `${import.meta.env.VITE_API_URL}/api/Client/Consents`,
    Get(),
    t.array(consentCodec).decode
  ),
  getVerificationStatus: pipe(
    tokenTriggerAuth,
    TE.chain(
      makeAuthenticatedRequest(
        `${import.meta.env.VITE_API_URL}/api/Application/Verify`,
        Get(),
        getVerifyConsentCodec.decode
      )
    )
  ),
  putEmployer: (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Application/Employer`,
          pipe(payload, JsonPayload(employerGeneralPayload.encode), Patch),
          t.unknown.decode
        )
      )
    ),
  putVerificationStatus: (payload) =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/api/Application/Verify`,
          pipe(payload, JsonPayload(saveConsentsCodec.encode), Post),
          t.unknown.decode
        )
      ),
      TE.map(() => undefined)
    ),
  unfinishApplicationExit: () =>
    pipe(
      tokenTriggerAuth,
      TE.chain(
        makeAuthenticatedRequest(
          `${import.meta.env.VITE_API_URL}/User/UnfinishedApplicationExit`,
          Get(),
          t.unknown.decode
        )
      )
    ),
  getReferringLoanOfficerInfo: (userId) =>
    makeRequest(
      `${import.meta.env.VITE_API_URL}/Survey/LoanOfficerInfo/${userId}`,
      Get(),
      referringLoanOfficerInfoCodec.decode
    ),

});

const acquireToken =
  (loginRedirectTask: LoginTriggerer) =>
  (client: IPublicClientApplication): TE.TaskEither<ApiError, string> => {
    return pipe(
      TE.tryCatch(() => {
        const accounts = client.getAllAccounts();
        if (accounts.length > 0) {
          const userAccount = accounts[0];
          return client.acquireTokenSilent({
            ...loginRequest,
            account: userAccount,
          });
        } else {
          return client.ssoSilent(loginRequest);
        }
      }, identity),

      TE.matchE(
        (err) => {
          if (err instanceof InteractionRequiredAuthError) {
            loginRedirectTask({
              authority: import.meta.env.VITE_LO_SIGNIN_AUTHORITY,
              state: { redirectPath: "/" },
              loginHint: "",
            })();
            return TE.right("Redirect to MFA initiated");
          }

          return TE.left(err);
        },
        (result: AuthenticationResult) => {
          return TE.right(result.accessToken);
        }
      ),
      TE.bimap(AuthenticationError, (idToken) => idToken)
    );
  };

// get token from the active user session
// returns None if no active session.
// Does not trigger auth
const tryAcquireToken = (
  client: IPublicClientApplication
): TE.TaskEither<ApiError, Option<string>> => {
  return pipe(
    client.getAllAccounts(),
    A.head,
    O.traverse(TE.ApplicativeSeq)((account) =>
      pipe(
        TE.tryCatch(
          () => client.acquireTokenSilent({ ...loginRequest, account }),
          identity
        )
      )
    ),
    TE.bimap(
      AuthenticationError,
      O.map((result) => {
        return result.accessToken;
      })
    )
  );
};

export type LoginTriggerer = (o: {
  authority: string;
  state: Partial<RedirectState>;
  loginHint: string;
  redirectUri?: string;
}) => TaskEither<Error, void>;

export function useApi(): [
  Api,
  Task<void>,
  LoginTriggerer,
  TaskEither<ApiError, AuthenticationResult | null>,
] {
  const [_authClient, setAuthClient] =
    useState<IPublicClientApplication | null>(null);

  const authClientPromise = useMemo(
    () => PublicClientApplication.createPublicClientApplication(msalConfig),
    []
  );

  useEffect(() => {
    authClientPromise.then((client) => {
      setAuthClient(client);
      pipe(
        client.getAllAccounts(),
        A.head,
        O.traverse(TE.ApplicativeSeq)((account) =>
          pipe(
            TE.tryCatch(
              () =>
                client.acquireTokenSilent({
                  ...loginRequest,
                  account,
                  forceRefresh: true,
                  refreshTokenExpirationOffsetSeconds: 7200,
                }),
              identity
            )
          )
        )
      );
    });
  }, [authClientPromise]);

  const logoutTask = useMemo(
    () =>
      pipe(
        () => authClientPromise,
        T.chain(
          (authClient) => () =>
            authClient.logoutRedirect({ postLogoutRedirectUri: "/" })
        )
      ),
    [authClientPromise]
  );

  const handleRedirectTask = useMemo(
    () =>
      pipe(
        TE.tryCatch(() => authClientPromise, AuthenticationError),
        TE.chain((authClient) =>
          TE.tryCatch(
            () => authClient.handleRedirectPromise(),
            AuthenticationError
          )
        )
      ),
    [authClientPromise]
  );

  const isProd = import.meta.env.PROD;
  const performUserFlow = useMemo(
    (): LoginTriggerer =>
      ({ authority, state, loginHint }) =>
        pipe(
          () => authClientPromise,
          T.chain((authClient) =>
            TE.tryCatch(
              () => {
                const newState = isProd
                  ? JSON.stringify({
                      ...state,
                      originDomain: window.location.host,
                    })
                  : JSON.stringify({
                      ...state,
                    });
                const redirectUri = isProd
                  ? "https://login.appcatcher.com"
                  : undefined;
                return authClient.acquireTokenRedirect({
                  ...loginRequest,
                  state: encodeURIComponent(newState),
                  authority,
                  extraQueryParameters: {
                    appCatcherLoginHint: loginHint,
                  },
                  loginHint: "",
                  redirectUri,
                });
              },
              () => new Error("")
            )
          )
        ),
    [authClientPromise, isProd]
  );

  const api = useMemo(
    () =>
      makeApi(
        pipe(() => authClientPromise, T.chain(acquireToken(performUserFlow))),
        pipe(() => authClientPromise, T.chain(tryAcquireToken))
      ),
    [authClientPromise, performUserFlow]
  );

  return [api, logoutTask, performUserFlow, handleRedirectTask];
}
