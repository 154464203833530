import { Col, Icon, Row, TogglePanel } from "@/components/basic";
import { deferredToOption } from "@/utils/deferred";
import { pipe } from "fp-ts/lib/function";
import { constant } from "lodash-es";
import { ContentProps } from "../view";
import * as NEA from "fp-ts/lib/NonEmptyArray";
import * as O from "fp-ts/lib/Option";
import * as A from "fp-ts/lib/Array";

export function VerificationSectionView(props: ContentProps): JSX.Element {
  const { model } = props;
  return (
    <div style={{ order: "2" }}>
      <TogglePanel id="verification-section" title="Verification" defaultOpen={true}>
        <div>
          {pipe(
            model.primaryApplicant.consents,
            NEA.fromArray,
            O.fold(
              () => (
                <Col>
                  <p> Consents are not provided yet by the applicant</p>
                </Col>
              ),
              (array) => (
                <>
                  {array.map((consent, index) => {
                    const matchingConsentItem = pipe(
                      model.constentMeta,
                      deferredToOption,
                      O.chain(O.fromEither),
                      O.chain((meta) =>
                        pipe(
                          meta,
                          A.findFirst(
                            (m) =>
                              m.consentVersionId == consent.consentVersionId
                          )
                        )
                      )
                    );
                    return pipe(
                      matchingConsentItem,
                      O.fold(constant(<Col>
                        <p> No matching consents available.</p>
                      </Col>), (matchingConsent) => (
                        <Row
                          alignVertical="center"
                          gap="xs"
                          padding="xs"
                          key={index}
                        >
                          <Icon type="check" />
                          <Col padding="sm">
                            <>
                              <span> {matchingConsent.title}</span>
                              <span className="text-bold">
                                {consent.consentProvided ? "Yes" : "No"}
                              </span>
                            </>
                          </Col>
                        </Row>
                      ))
                    );
                  })}
                </>
              )
            )
          )}
        </div>
      </TogglePanel>
    </div>
  );
}
