import { Row, Col } from "@/components/basic";
import { Tag } from "@/components/basic/Tag";
import { ViewResolver } from "@/utils/viewResolver";
import { pipe } from "fp-ts/lib/function";
import { mapDeferred, deferredToOption, Deferred } from "@/utils/deferred";
import { Fragment, useMemo } from "react";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import * as A from "fp-ts/lib/Array";
import { DateTime } from "luxon";
import { ConsentItem, SurveyConsentItem } from "@/data/consents";
import { ApiResult } from "@/utils/request";
import { Application } from "@/data/payload";
import { useOnly } from "@/utils/new-only";

type ApplicationSummaryProps = {
  consents: SurveyConsentItem[];
  constentMeta: Deferred<ApiResult<ConsentItem[]>>;
  payload: Application;
  only?: O.Option<boolean>;
}

export function ApplicationSummary(props: ApplicationSummaryProps) {
  const { payload, constentMeta, consents } = props;

   const shouldDisplay = useOnly(props.only);

  const isCreditCheckAuthorized = useMemo(() => {
    return pipe(
      constentMeta,
      mapDeferred(
        E.map((meta) =>
          meta.find(({ consentType }) => consentType == "SoftCreditPull")
        )
      ),
      deferredToOption,
      O.chain(O.fromEither),
      O.chain(O.fromNullable),
      O.chain((v) =>
        O.fromNullable(
          consents.find(
            (c) => c.consentVersionId == v.consentVersionId
          )
        )
      ),
      O.isSome
    );
  }, [constentMeta, consents]);

  const overviewClassNmes = ViewResolver({
    viewModes: [
      ["Default"],
      ["Tablet-Portrait", "Tablet-Landscape"],
      ["Mobile-Portrait", "Mobile-Landscape"],
    ],
    resolvedContent: ["flex-wrap", "grid-3", "grid"],
  });

  const shouldSwitchToGrid = ViewResolver({
    viewModes: [["Default"], ["Desktop-Portrait", "Desktop-Landscape"]],
    resolvedContent: [true, false],
  });
  return ( shouldDisplay &&
    <>
      <Col className="application-section">
        <Row
          padding="fallback"
          className="text-smd grid-item-3 color-act-70 text-mbold applicant-name"
          wrap
        >
          <span>{payload.survey.primaryApplicant.fullName}</span>
          {A.size(payload.survey.jointApplicants) ? (
            <>
              {pipe(
                payload.survey.jointApplicants,
                A.map((application) => (
                  <Fragment key={application.jointApplicantId}>
                    <span>, {application.applicant.fullName}</span>
                  </Fragment>
                ))
              )}
            </>
          ) : (
            <></>
          )}
        </Row>
        <Row
          gap="xs"
          alignVertical="baseline"
          className={overviewClassNmes}
          switchToGrid={shouldSwitchToGrid}
          padding="sm"
          alignHorizontal="space-between"
        >
          <Col gap="xxxs">
            <b>Application Number</b>
            <span className="padding-vertical-xxxs">
              {payload.applicationId ? payload.applicationId : "-"}
            </span>
          </Col>
          <Col gap="xxxs">
            <b>Status</b>
            <Tag
              type="neutral"
              iconAlign="none"
              icon={O.some("info")}
              className="text-sm font-weight-500"
            >
              {payload.status}
            </Tag>
          </Col>
          <Col gap="xxxs">
            <b>Credit Check</b>
            <span className="padding-vertical-xxxs">
              {isCreditCheckAuthorized ? (
                <Tag
                  iconAlign="right"
                  icon={O.some("check")}
                  className="text-sm font-weight-500"
                  type="neutral"
                >
                  {" "}
                  Authorized
                </Tag>
              ) : (
                <Tag
                  iconAlign="right"
                  icon={O.some("xmark")}
                  className="text-sm font-weight-500"
                  type="neutral"
                >
                  {" "}
                  Not Authorized
                </Tag>
              )}
              {/* <i className="fa-solid fa fa-check ml-left-xxs"></i> */}
            </span>
          </Col>
          <Col gap="xxxs">
            <b>Type</b>
            <span className="padding-vertical-xxxs">
              {payload.survey.applicationType}
            </span>
          </Col>
          <Col gap="xxxs">
            <b>Started</b>
            <Tag
              type="neutral"
              iconAlign="none"
              icon={O.some("info")}
              className="text-sm font-weight-500"
            >
              {relativeDate(
                payload.createdDateUtc ? O.some(payload.createdDateUtc) : O.none
              )}
            </Tag>
          </Col>
          <Col gap="xxxs">
            <b>Assigned to</b>
            <span className="padding-vertical-xxxs">
              <i className="fa-regular fa-user ml-right-xxs"></i>
              {pipe(
                payload.loanOfficer,
                O.fold(
                  () => "-",
                  (info) =>
                    info.fullName
                      ? info.fullName
                      : info.email
                        ? info.email
                        : "-"
                )
              )}
            </span>
          </Col>
        </Row>
      </Col>
    </>
  );
}

function relativeDate(date: O.Option<DateTime>) {
  return O.fold(
    () => "Today",
    (exportedDate: DateTime) => {
      const now = DateTime.local();
      const diffInDays = now.diff(exportedDate, "days").days;

      if (diffInDays < 1) {
        return "Today";
      } else if (diffInDays < 2) {
        return "1 day ago";
      } else {
        return `${Math.floor(diffInDays)} days ago`;
      }
    }
  )(date);
}
