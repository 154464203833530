import { Row, Col } from "@/components/basic";
import { ContentProps } from "../view";
import { ApplicationSectionsView } from "./application-sections";
import { ApplicationSummary } from "./application-summary";
import { LoanOfficerNotesView } from "./lo-notes";
import { useRights } from "@/utils/use-rights";
import { SectionView } from "./section";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import { SectionType } from "../model";
import { ViewResolver } from "@/utils/viewResolver";

export function SummaryContainerView(props: ContentProps): JSX.Element {
  const { model, dispatch } = props;
  const payload = model.application;

  const { isBackOfficeUser } = useRights();

  const isBorrowerSection: SectionType[] = [
    "Personal Information",
    "Employer Information",
    "Financial Information",
    "Mortgage Information",
  ];

  const notesOnly = pipe(
    isBackOfficeUser,
    O.map((v) => v && isBorrowerSection.includes(model.section.type))
  );

  const ContentView = (
    <>
      <Col padding="xxs" className={
          ViewResolver({
            viewModes: [
              ["Desktop-Landscape"],
              [
                "Default"
              ],
            ],
            resolvedContent: ['overflow-y-auto w-25', ''],
          })
        }>
        <Col>
          <ApplicationSectionsView {...props}></ApplicationSectionsView>
        </Col>
      </Col>
      <Col gap="md" padding="xxs" className="w-100 overflow-y-auto">
        <ApplicationSummary
          consents={model.primaryApplicant.consents}
          constentMeta={model.constentMeta}
          payload={model.application}
          only={isBackOfficeUser}
        />
        <Col className={
          ViewResolver({
            viewModes: [
              ["Default"],
              [
                "Mobile-Portrait",
                "Mobile-Landscape",
                "Tablet-Landscape"
              ],
            ],
            resolvedContent: ['summary-scrollable-content overflow-y-auto', 'summary-scrollable-content overflow-y-visible'],
          })
        } gap="md" padding="fallback">
          <LoanOfficerNotesView
            isEditingLoNotes={model.isEditingLoNotes}
            loNotesFormField={model.loNotesFormField}
            loanOfficerNote={payload.survey.loanOfficerNote}
            dispatch={dispatch}
            mode={model.mode}
            only={notesOnly}
          />
          <SectionView {...props}></SectionView>
        </Col>
      </Col>
    </>
  );

  const DesktopView = (
    <Row gap="sm" className="overflow-hidden">
      {ContentView}
    </Row>
  );

  const MobileView = (
    <Col gap="xxs" className="overflow-hidden">
      {ContentView}
    </Col>
  );

  const ViewWrapper = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [DesktopView, MobileView],
  });

  return <>{ViewWrapper}</>;
}
