import {
  Button,
  Col,
  Icon,
  InputField,
  Label,
  Select,
} from "@/components/basic";
import {
  Branch,
  BranchId,
  EqBranchId,
  EqSetExtensionRole,
  EqTeamId,
  ExtensionRole,
  OrdBranchId,
  OrdTeamId,
  showExtensionRole,
  Team,
  TeamId
} from "@/data/client";
import { ChildProps } from "@/utils/reducerWithEffect";
import { flow, pipe } from "fp-ts/lib/function";
import * as M from "fp-ts/lib/Map";
import * as O from "fp-ts/lib/Option";
import {
  Action,
  BranchChanged,
  EmailChanged,
  FeatureBreakdownClosed,
  FeatureBreakdownOpened,
  FirstNameChanged,
  LastNameChanged,
  PhoneChanged,
  RoleSelected,
  TeamChanged,
} from "./action";
import { Model } from "./model";

export type Props = ChildProps<Model, Action> & {
  branches: Map<BranchId, Branch>;
  teams: Map<TeamId, Team>;
};

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const isEmpty = M.size(props.teams);

  return model.featureBreakdownVisible ? (
    <Col padding="xs" gap="xs">
      <div className="grid-4 row-gap-xxxs column-gap-sm">
        <Label>
          <b>Feature Breakdown</b>
        </Label>
        <Label>
          <b>Super Usert</b>
        </Label>
        <Label>
          <b>Loan Officer</b>
        </Label>
        <Label>
          <b>Support/Team</b>
        </Label>
        <hr className="column-span-4" />
        <span>View borrower profiles assigned to them</span>
        <Icon type="circle-check" />
        <Icon type="circle-check" />
        <Icon type="circle-check" />
        <hr className="column-span-4" />
        <span>View all uploaded documents for a given borrower</span>
        <Icon type="circle-check" />
        <Icon type="circle-check" />
        <Icon type="circle-check" />
        <hr className="column-span-4" />
        <span>Re-assign a borrower to another user</span>
        <Icon type="circle-check" />
        <Icon type="circle-check" />
        <></>
        <hr className="column-span-4" />
        <span>Export to LOS</span>
        <Icon type="circle-check" />
        <Icon type="circle-check" />
        <Icon type="circle-check" />
        <hr className="column-span-4" />
        <span>Add users to a team</span>
        <Icon type="circle-check" />
        <Icon type="circle-check" />
        <></>
        <hr className="column-span-4" />
        <span>Contact info of staff and MLO</span>
        <Icon type="circle-check" />
        <Icon type="circle-check" />
        <Icon type="circle-check" />
        <hr className="column-span-4" />
        <span>
          Receive notifications about a borrower's application status (started,
          docs received, completed)
        </span>
        <Icon type="circle-check" />
        <Icon type="circle-check" />
        <Icon type="circle-check" />
        <hr className="column-span-4" />
        <span>Create teams</span>
        <Icon type="circle-check" />
        <></>
        <></>
        <hr className="column-span-4" />
        <span>View the full list of borrowers</span>
        <Icon type="circle-check" />
        <></>
        <></>
        <hr className="column-span-4" />
        <span>Modify all user roles</span>
        <Icon type="circle-check" />
        <></>
        <></>
      </div>
      <Col alignHorizontal="center">
        <Button
          type="primary"
          onClick={O.some(flow(FeatureBreakdownClosed, dispatch))}
        >
          Back
        </Button>
      </Col>
    </Col>
  ) : (
    <Col padding="xs" gap="xs">
      <div className="grid-2 gap-xs">
        <InputField
          label="First name"
          field={model.firstName}
          onChange={flow(FirstNameChanged, dispatch)}
        />
        <InputField
          label="Last name"
          field={model.lastName}
          onChange={flow(LastNameChanged, dispatch)}
        />
        <InputField
          label="Email"
          field={model.email}
          onChange={flow(EmailChanged, dispatch)}
        />

        <InputField
          label="Phone"
          field={model.phone}
          onChange={flow(PhoneChanged, dispatch)}
        />

        <Col gap="xs">
          <Label>Role</Label>
          <Select
            options={[
              new Set<ExtensionRole>().add(ExtensionRole.LoanOfficer),
              new Set<ExtensionRole>().add(ExtensionRole.Support),
              new Set<ExtensionRole>().add(ExtensionRole.SuperUser),
              new Set<ExtensionRole>()
                .add(ExtensionRole.SuperUser)
                .add(ExtensionRole.LoanOfficer),
            ]}
            selected={pipe(model.role.val, O.fromEither)}
            onChange={flow(RoleSelected, dispatch)}
            valueEq={EqSetExtensionRole}
            renderLabel={showExtensionRole}
          />
          <span
            className="link"
            onClick={flow(FeatureBreakdownOpened, dispatch)}
          >
            View feature breakdown
          </span>
        </Col>

        <Col gap="xs">
          <Label>Branch</Label>
          <Select
            options={M.keys(OrdBranchId)(props.branches)}
            selected={model.branchId}
            onChange={flow(BranchChanged, dispatch)}
            valueEq={EqBranchId}
            renderLabel={(branchId) =>
              pipe(
                props.branches,
                M.lookup(OrdBranchId)(branchId),
                O.fold(
                  () => `BranchID {${branchId}}`,
                  ({ name }) => name,
                ),
              )
            }
          />
        </Col>

        {isEmpty > 0 && (
          <Col gap="xs">
            <Label>Team</Label>
            <Select
              options={M.keys(OrdTeamId)(props.teams)}
              selected={model.teamId}
              onChange={flow(TeamChanged, dispatch)}
              valueEq={EqTeamId}
              renderLabel={(teamId) =>
                pipe(
                  props.teams,
                  M.lookup(OrdTeamId)(teamId),
                  O.fold(
                    () => `TeamID {${teamId}}`,
                    ({ name }) => name,
                  ),
                )
              }
            />
          </Col>
        )}
      </div>
    </Col>
  );
}
