import { Col } from "../basic";
import { ContentProps } from "./view";
import { ActionPanelView } from "./sections/action-panel";
import { SummaryContainerView } from "./sections/summary-container";
import { Started } from "@/utils/asyncOperationStatus";
import { flow } from "lodash-es";
import { useEffect, useMemo } from "react";
import { PollEmployers } from "./action";
import { EmployerResolverModalSectionView, ExportModalView, ExportWarningModalSectionView, ManualClassificationModalSectionView } from "./sections/modal-sections";

const EMPLOYER_RESOLVE_POLL_DELAY_SECS = 10;

export function ContentViewV2(props: ContentProps): JSX.Element {
  const { model, dispatch } = props;

  const pollEmployeesAction = useMemo(
    () =>
      flow(
        Started,
        PollEmployers(model.applicationId, props.onFlashAdded),
        dispatch
      ),
    [model.applicationId, props.onFlashAdded, dispatch]
  );

  useEffect(() => {
    pollEmployeesAction();
    const intervalId = setInterval(() => {
      pollEmployeesAction();
    }, 1000 * EMPLOYER_RESOLVE_POLL_DELAY_SECS);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Col gap="xs" className="w-100 overflow-hidden">
      <ActionPanelView {...props}></ActionPanelView>
      <SummaryContainerView {...props}></SummaryContainerView>
      <EmployerResolverModalSectionView {...props} />
      <ExportWarningModalSectionView {...props}/>
      <ExportModalView {...props} />
      <ManualClassificationModalSectionView {...props}/>
    </Col>
  );
}
