import * as DeleteUser from "@/components/UserManagement/UsersListing/modals/DeleteUser";
import * as EditUser from "@/components/UserManagement/UsersListing/modals/EditUser";
import {
  ClientsReassignment,
  EqUserId,
  OrdUserId,
  UserId
} from "@/data/client";
import { constFalse, flow, identity, pipe } from "fp-ts/lib/function";
import * as M from "fp-ts/lib/Map";
import * as O from "fp-ts/lib/Option";
import { useCallback, useMemo } from "react";
import { DialogViewProps } from "../types";

import { ApiCallingModal } from "@/components/basic/Modal/ApiCallingModal";
import { getFullName } from "@/utils/user";
import {
  ConfirmReassignedUser,
  EditUserAction,
  ReassignToChanged,
} from "../action";

export function UserManagementModals(props: DialogViewProps): JSX.Element {
  const reassignableUsers = useMemo(
    () =>
      pipe(
        props.users,
        M.filter((v) =>
          pipe(
            v.status,
            O.map((v) => v == "Active"),
            O.fold(constFalse, identity),
          ),
        ),
        M.keys(OrdUserId),
      ),
    [props.users],
  );

  const modalTitle = pipe(props.dialog, (v) => {
    switch (v.type) {
      case "NewUserDialog":
        return O.some("Create User");
      case "DeleteUserDialog":
        return O.some(v.deleteDone ? `Completed` : `Please Confirm`);
      case "EditUserDialog":
        return O.some("Edit User");
      default:
        return O.none;
    }
  });

  const lookupUserName = useCallback(
    (userId: UserId) =>
      pipe(
        props.users,
        M.lookup(OrdUserId)(userId),
        O.fold(() => "", getFullName),
      ),
    [props.users],
  );

  const cancelHandler = O.some(() => props.onCloseModal());

  switch (props.dialog.type) {
    case "NewUserDialog":
    case "EditUserDialog":
      {
        const model = props.dialog.dialogModel;
        const validatedModel = pipe(EditUser.result(model), O.fromEither);

        return (
          <ApiCallingModal
            actionTitle={
              props.dialog.type == "NewUserDialog" ? "Create New User" : "Save"
            }
            onClose={cancelHandler}
            title={modalTitle}
            dialogApiResult={props.dialogApiResult}
            actionHander={O.some(() =>
              pipe(
                validatedModel,
                O.map((payload) =>
                  props.onSaveUser(
                    props.dialog.type == "NewUserDialog"
                      ? O.none
                      : O.some(props.dialog.userId),
                  )(payload),
                ),
              ),
            )}
            cancelHandler={cancelHandler}
          >
            <EditUser.View
              model={props.dialog.dialogModel}
              branches={props.branches}
              teams={props.teams}
              dispatch={flow(EditUserAction, props.dispatch)}
            />
          </ApiCallingModal>
        );
      }
      break;

    case "DeleteUserDialog": {
      const { userId, reassignTo, reassignmentConfirmed, deleteDone } =
        props.dialog;
      if (!reassignmentConfirmed || deleteDone) {
        return (
          <DeleteUser.View
            user={M.lookup(EqUserId)(userId, props.users)}
            userOptions={reassignableUsers}
            onClose={cancelHandler}
            reassignTo={props.dialog.reassignTo}
            deleteDone={props.dialog.deleteDone}
            assignedLoans={props.dialog.assignedLoans}
            reassignmentConfirmed={props.dialog.reassignmentConfirmed}
            lookupUserName={lookupUserName}
            onChangeReassign={flow(ReassignToChanged, props.dispatch)}
            onConfirmReassign={flow(ConfirmReassignedUser, props.dispatch)}
          />
        );
      }
      return (
        <ApiCallingModal
          actionTitle="Delete"
          onClose={cancelHandler}
          title={modalTitle}
          dialogApiResult={props.dialogApiResult}
          actionHander={O.some(() => {
            if (O.isNone(reassignTo)) {
              props.onDeleteUser(userId, {});
            } else {
              pipe(
                O.of((uid: UserId) => (reassign: ClientsReassignment) => {
                  props.onDeleteUser(uid, reassign);
                }),
                O.ap(O.some(userId)),
                O.ap(pipe(reassignTo, O.map(ClientsReassignment))),
              );
            }
          })}
          cancelHandler={cancelHandler}
        >
          <DeleteUser.View
            user={M.lookup(EqUserId)(userId, props.users)}
            userOptions={reassignableUsers}
            onClose={O.none}
            reassignTo={props.dialog.reassignTo}
            lookupUserName={lookupUserName}
            reassignmentConfirmed={props.dialog.reassignmentConfirmed}
            assignedLoans={props.dialog.assignedLoans}
            deleteDone={props.dialog.deleteDone}
            onConfirmReassign={flow(ConfirmReassignedUser, props.dispatch)}
            onChangeReassign={flow(ReassignToChanged, props.dispatch)}
          />
        </ApiCallingModal>
      );
    }
  }
}
