import { apply, constant, flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import { ApplicationListItem } from "@/data/applicationsList";
import { ApplicationId, ApplicationStatusType } from "@/data/payload";
import { Started } from "@/utils/asyncOperationStatus";
import { RouterContext } from "@/utils/router-context";
import { useContext } from "react";
import { IconType } from "../basic";
import { OptionMenu } from "../basic/Menu/OptionMenu";
import {
  Action,
  ArchiveApplication,
  AssignUserSelected,
  ExportDocuments,
  ShowExportWarning,
} from "./action";

export function ApplicationRowContextMenu(props: {
  dispatch: (action: Action) => void;
  application: ApplicationListItem;
  onSummaryOptionSelected: (applicationId: ApplicationId) => void
}): JSX.Element {
  const { dispatch, application } = props;
  const { router } = useContext(RouterContext);

  const archiveHandler = pipe(
    application,
    O.fromPredicate((v) => v.status != ApplicationStatusType.Archived),
    O.map(() => {
      return () =>
        dispatch(
          pipe(
            ArchiveApplication,
            apply(application.applicationId),
            apply(Started()),
          ),
        );
    }),
  );
  const assignHandler = pipe(
    application,
    O.fromPredicate((v) => v.status != ApplicationStatusType.Archived),
    O.map(() => {
      return () => dispatch(AssignUserSelected(application));
    }),
  );

  const summaryHandler = pipe(
    application,
    O.fromPredicate((v) => [ApplicationStatusType.Complete, ApplicationStatusType.Exported].includes(v.status)),
    O.map(() => {
      return () => props.onSummaryOptionSelected(application.applicationId)
    })
  );

  const exportHandler = pipe(
    application,
    O.fromPredicate((v) => [ApplicationStatusType.Complete, ApplicationStatusType.Archived, ApplicationStatusType.Exported].includes(v.status)),
    O.map(() => {
      return () => {
        if (
          ![
            ApplicationStatusType.Complete,
            ApplicationStatusType.Exported,
          ].includes(application.status)
        ) {
          return flow(
            ShowExportWarning(application.applicationId),
            dispatch,
          )();
        } else {
          return flow(
            Started,
            ExportDocuments(application.applicationId),
            dispatch,
          )();
        }
      }
    })
  )
  return (
    <OptionMenu
      menuItems={[
        {
          icon: "rectangle-list" as IconType,
          label: "Summary",
          onClick: summaryHandler
        },
        {
          icon: "eye" as IconType,
          label: "View",
          onClick: O.some(
            flow(
              constant(`/summary/${application.applicationId}`),
              router.navigate,
            ),
          ),
        },
        {
          icon: "file-export" as IconType,
          label: "Export",
          onClick: exportHandler,
        },
        {
          icon: "user" as IconType,
          label: "Assign",
          onClick: assignHandler,
        },
        {
          icon: "trash-can" as IconType,
          label: "Archive",
          onClick: archiveHandler,
        },
      ]}
    ></OptionMenu>
  );
}
