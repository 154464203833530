import { TogglePanel, Col, Table, Row, Label, Button } from "../../basic";
import { TableColumn } from "../../basic/Table/table";
import { CoverSheetPaystub } from "@/data/coversheet";
import { formatCurrency, formatDate } from "@/utils/formatters";
import * as O from 'fp-ts/Option';
import { ViewResolver } from "@/utils/viewResolver";
import { useState } from "react";

type EarningItem = CoverSheetPaystub['earningItems'][0];

type Props = {
  paystub: CoverSheetPaystub;
};

function getDesktopColumns(): TableColumn<EarningItem>[] {
  return [
    {
      columnKey: "description",
      header: <div className="text-left">Description</div>,
      view: (item: EarningItem) => <div className="text-left">{item.description}</div>
    },
    {
      columnKey: "category",
      header: <div className="text-left">Category</div>,
      view: (item: EarningItem) => <div className="text-left">{item.category}</div>
    },
    {
      columnKey: "hours",
      header: <div className="text-right">Hours</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: EarningItem) => <div className="text-right">{item.hours}</div>
    },
    {
      columnKey: "amount",
      header: <div className="text-right">Amount</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: EarningItem) => <div className="text-right">{formatCurrency(item.amount)}</div>
    },
    {
      columnKey: "ytdHours",
      header: <div className="text-right">YTD Hours</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: EarningItem) => <div className="text-right">{item.yearToDateHours}</div>
    },
    {
      columnKey: "ytdAmount",
      header: <div className="text-right">YTD Amount</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: EarningItem) => <div className="text-right">{formatCurrency(item.yearToDateAmount)}</div>
    }
  ];
}

function getMobileColumns(expandedRows: Set<string>, toggleRow: (key: string) => void): TableColumn<EarningItem>[] {
  return [
    {
      columnKey: "summary",
      header: <div className="text-left">Earning Items</div>,
      view: (item: EarningItem) => {
        const rowKey = `${item.description}-${item.category}`;
        const isExpanded = expandedRows.has(rowKey);

        return (
          <Col className="data-table-cell mobile-column" padding="sm" gap="xs">
            <Row className="space-between center">
              <Col>
                <Label className="text-mbold">{item.description}</Label>
                <Label>{item.category}</Label>
              </Col>
              <Col alignHorizontal="right">
                <Label>{formatCurrency(item.amount)}</Label>
                <Button type="flat" className="show-more-button" onClick={O.some(() => toggleRow(rowKey))}>
                  {isExpanded ? "Show Less" : "Show More"}
                </Button>
              </Col>
            </Row>
            {isExpanded && (
                    <Col gap="xs" className="expanded-details">
                        <Row className="flex-container-space-between">
                            <Label><span className="text-xs">Hours</span></Label>
                            <Label><span className="text-xs">{item.hours}</span></Label>
                        </Row>
                        <Row className="flex-container-space-between">
                            <Label><span className="text-xs">YTD Hours</span></Label>
                            <Label ><span className="text-xs">{item.yearToDateHours}</span></Label>
                        </Row>
                        <Row className="flex-container-space-between">
                            <Label><span className="text-xs">YTD Amount</span></Label>
                            <Label><span className="text-xs">{formatCurrency(item.yearToDateAmount)}</span></Label>
                        </Row>
                    </Col>
                )}
              </Col>
          );
      }
    }
  ];
}

export function PaystubCard({ paystub }: Props): JSX.Element {
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  const toggleRow = (key: string) => {
    const newExpanded = new Set(expandedRows);
    if (newExpanded.has(key)) {
      newExpanded.delete(key);
    } else {
      newExpanded.add(key);
    }
    setExpandedRows(newExpanded);
  };

  return (
    <TogglePanel
      title={`Pay Date: ${formatDate(paystub.payDate)}`}
      subTitle={`Pay Period: ${formatDate(paystub.payPeriodStartDate)} - ${formatDate(paystub.payPeriodEndDate)}`}
      toggleHeaderClassName="paystub-card"
    >
      <Col gap="sm" className="light-gray-panel">
        <h4>Summary</h4>
        <div className="flex-grid">
          {paystub.regularIncome > 0 && <div className="flex-grid-item"><span className="income-category-label">Regular Income</span><br /> {formatCurrency(paystub.regularIncome)}</div>}
          {paystub.overtimeIncome > 0 && <div className="flex-grid-item"><span className="income-category-label">Overtime Income</span><br /> {formatCurrency(paystub.overtimeIncome)}</div>}
          {paystub.commissionIncome > 0 && <div className="flex-grid-item"><span className="income-category-label">Commission Income</span><br /> {formatCurrency(paystub.commissionIncome)}</div>}
          {paystub.bonusIncome > 0 && <div className="flex-grid-item"><span className="income-category-label">Bonus Income</span><br /> {formatCurrency(paystub.bonusIncome)}</div>}
          {paystub.tipIncome > 0 && <div className="flex-grid-item"><span className="income-category-label">Tip Income</span><br /> {formatCurrency(paystub.tipIncome)}</div>}
          {paystub.otherIncome > 0 && <div className="flex-grid-item"><span className="income-category-label">Other Income</span><br /> {formatCurrency(paystub.otherIncome)}</div>}
        </div>
        {paystub.earningItems.length > 0 && (
          <>
            <h4>Earning Items</h4>
            <Table
              className="earning-items"
              data={paystub.earningItems}
              columns={ViewResolver({
                viewModes: [
                  ["Default"],
                  ["Mobile-Portrait", "Mobile-Landscape"]
                ],
                resolvedContent: [
                  getDesktopColumns(),
                  getMobileColumns(expandedRows, toggleRow)
                ]
              })}
              itemKey={(item) => `${item.description}-${item.category}`}
              pageInfo={O.none}
              isLoading={false}
            />
          </>
        )}
      </Col>
    </TogglePanel>
  );
}
