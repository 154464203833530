import {
  BranchId,
  ExtensionRole,
  extensionRolesSetCodec,
  showExtensionRole,
  TeamId
} from "@/data/client";
import { emailStringCodec, nonEmptyStringCodec } from "@/utils/codecs";
import { updateFormField } from "@/utils/formField";
import * as O from "fp-ts/lib/Option";
import { Model } from "./model";

export type Action =
  | {
      type: "FirstNameChanged";
      firstName: string;
    }
  | {
      type: "LastNameChanged";
      lastName: string;
    }
  | {
      type: "EmailChanged";
      email: string;
    }
  | {
      type: "PhoneChanged";
      phone: string;
    }
  | {
      type: "BranchChanged";
      branchId: BranchId;
    }
  | {
      type: "TeamChanged";
      teamId: TeamId;
    }
  | {
      type: "RoleSelected";
      role: Set<ExtensionRole>;
    }
  | {
      type: "FeatureBreakdownOpened";
    }
  | {
      type: "FeatureBreakdownClosed";
    };

export const FirstNameChanged = (firstName: string): Action => ({
  type: "FirstNameChanged",
  firstName,
});

export const LastNameChanged = (lastName: string): Action => ({
  type: "LastNameChanged",
  lastName,
});

export const EmailChanged = (email: string): Action => ({
  type: "EmailChanged",
  email,
});

export const PhoneChanged = (phone: string): Action => ({
  type: "PhoneChanged",
  phone,
});

export const BranchChanged = (branchId: BranchId): Action => ({
  type: "BranchChanged",
  branchId,
});

export const TeamChanged = (teamId: TeamId): Action => ({
  type: "TeamChanged",
  teamId,
});

export const RoleSelected = (role: Set<ExtensionRole>): Action => ({
  type: "RoleSelected",
  role,
});

export const FeatureBreakdownOpened = (): Action => ({
  type: "FeatureBreakdownOpened",
});

export const FeatureBreakdownClosed = (): Action => ({
  type: "FeatureBreakdownClosed",
});

export const update =
  (action: Action) =>
  (model: Model): Model => {
    switch (action.type) {
      case "FirstNameChanged":
        return {
          ...model,
          firstName: updateFormField(nonEmptyStringCodec().decode)(
            action.firstName,
          ),
        };
      case "LastNameChanged":
        return {
          ...model,
          lastName: updateFormField(nonEmptyStringCodec().decode)(
            action.lastName,
          ),
        };
      case "EmailChanged":
        return {
          ...model,
          email: updateFormField(emailStringCodec.decode)(action.email),
        };

      case "PhoneChanged":
        return {
          ...model,
          phone: updateFormField(nonEmptyStringCodec().decode)(action.phone),
        };

      case "BranchChanged":
        return {
          ...model,
          branchId: O.some(action.branchId),
        };
      case "TeamChanged":
        return {
          ...model,
          teamId: O.some(action.teamId),
        };
      case "RoleSelected":
        {
          const asString = showExtensionRole(action.role);
          return {
            ...model,
            role: updateFormField(extensionRolesSetCodec.decode)(asString),
          };
        }
        break;
      case "FeatureBreakdownOpened":
        return {
          ...model,
          featureBreakdownVisible: true,
        };

      case "FeatureBreakdownClosed":
        return {
          ...model,
          featureBreakdownVisible: false,
        };
    }
  };
