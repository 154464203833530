import { ChildProps } from "@/utils/reducerWithEffect";
import { Model, selectedSourcesOfFunds, isCoApplicantOrJointAccountSelected } from "./model";
import {
  Action,
  JointApplicantSelected,
  JointApplicantTypeSelected,
  SourceOfFundsToggled,
} from "./action";
import { Checkbox, Col, Label, RadioButton, Select } from "@/components/basic";
import { useCallback, useMemo } from "react";
import {
  EqSourceOfFunds,
  jointApplicantTypeOptions,
  showJointApplicantType,
  showSourceOfFunds,
  SourceOfFunds,
  sourceOfFundsOptions,
} from "@/data/payload";
import * as S from "fp-ts/Set";
import * as O from "fp-ts/Option";
import { constant, flow } from "fp-ts/lib/function";
import { Eq as EqString } from "fp-ts/string";

export type Props = ChildProps<Model, Action>;

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const selectedSources = useMemo(() => selectedSourcesOfFunds(model), [model]);

  const sourceCheckbox = useCallback(
    (sourceOfFunds: SourceOfFunds) => (
      <Checkbox
        key={sourceOfFunds}
        label={showSourceOfFunds(sourceOfFunds)}
        checked={S.elem(EqSourceOfFunds)(sourceOfFunds)(selectedSources)}
        onChange={flow(constant(sourceOfFunds), SourceOfFundsToggled, dispatch)}
      />
    ),
    [dispatch, selectedSources],
  );

  return (
    <div className="grid-2 gap-lg collapse-gap-md">
      <Col gap="xs">
              <Label>What are your source(s) of funds for down payment and closing costs? (check all that apply) *</Label>

        {sourceOfFundsOptions.map(sourceCheckbox)}
      </Col>

      <Col gap="xs">
        <Label>How are you applying for this mortgage?</Label>

        <RadioButton
          label="Individual applicant"
          checked={model.jointApplicantSelected === false}
          onChange={flow(constant(false), JointApplicantSelected, dispatch)}
        />
        <RadioButton
          label="Joint application"
          checked={model.jointApplicantSelected === true}
          onChange={flow(constant(true), JointApplicantSelected, dispatch)}
              />

              {isCoApplicantOrJointAccountSelected(model) && !model.jointApplicantSelected && (
                  <Col gap="xs" foreground="no50">
                      <span>Joint application must be selected if your source of funds include Co-applicant's individual account or Joint account.</span>
                  </Col>
              )}
      </Col>

      {model.jointApplicantSelected && (
        <Col gap="xs">
          <Label>What is the relationship to the joint applicant? *</Label>
          <Select
            options={jointApplicantTypeOptions}
            selected={O.fromEither(model.jointApplicantType.val)}
            renderLabel={showJointApplicantType}
            valueEq={EqString}
            onChange={flow(JointApplicantTypeSelected, dispatch)}
          />
        </Col>
          )}

          

    </div>
  );
}
