import { ViewResolver } from "@/utils/viewResolver";
import { Row, Label } from "../../basic";
import { TogglePanel, Col, Table } from "../../basic";
import { ScheduleCEntity } from "@/data/coversheet";
import { formatCurrency } from "@/utils/formatters";
import * as O from 'fp-ts/Option';

type Props = {
  entity: ScheduleCEntity;
};

function MonthlyIncomeSummary({ entity }: Props) {
  return (
    <Col gap="sm">
      <h3>Monthly Income Summary</h3>
      <div className="monthly-income-summary">
        <div className="total-monthly-income">
          <span className="income-category-label">Qualifying Monthly Income</span><br />
          {formatCurrency(entity.qualifyingIncome)}
        </div>
      </div>
    </Col>
  );
}

function getDesktopColumns() {
  return [
    {
      columnKey: "year",
      header: <div style={{ textAlign: "center" }}>Year</div>,
      headerContainerClassName: "align-center w-100",
      view: (item: ScheduleCEntity['annualData'][0]) => <div className="text-center">{item.year}</div>
    },
    {
      columnKey: "netProfitOrLoss",
      header: <div className="text-right">Net Profit (Loss)</div>,
      headerContainerClassName: "align-right w-100 p-0",
      view: (item: ScheduleCEntity['annualData'][0]) => <div className="text-right">{formatCurrency(item.netProfitOrLoss)}</div>
    },
    {
      columnKey: "depletion",
      header: <div className="text-right">Depletion</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: ScheduleCEntity['annualData'][0]) => <div className="text-right">{formatCurrency(item.depletion)}</div>
    },
    {
      columnKey: "depreciation",
      header: <div className="text-right">Depreciation</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: ScheduleCEntity['annualData'][0]) => <div className="text-right">{formatCurrency(item.depreciation)}</div>
    },
    {
      columnKey: "meals",
      header: <div className="text-right">Meals</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: ScheduleCEntity['annualData'][0]) => <div className="text-right">{formatCurrency(item.meals)}</div>
    },
    {
      columnKey: "otherExpenses",
      header: <div className="text-right">Other Expenses</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: ScheduleCEntity['annualData'][0]) => <div className="text-right">{formatCurrency(item.otherExpenses)}</div>
    },
    {
      columnKey: "businessUseOfHome",
      header: <div className="text-right">Business Use of Home</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: ScheduleCEntity['annualData'][0]) => <div className="text-right">{formatCurrency(item.businessUseOfHome)}</div>
    },
    {
      columnKey: "businessMileage",
      header: <div className="text-right">Business Mileage</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: ScheduleCEntity['annualData'][0]) => <div className="text-right">{formatCurrency(item.businessMileage)}</div>
    },
    {
      columnKey: "totalIncome",
      header: <div className="text-right">Total Income</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: ScheduleCEntity['annualData'][0]) => <div className="text-right">{formatCurrency(item.totalIncome)}</div>
    },
    {
      columnKey: "monthlyIncome",
      header: <div className="text-right">Monthly Income</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: ScheduleCEntity['annualData'][0]) => <div className="text-right">{formatCurrency(item.monthlyIncome)}</div>
    }
  ];
}

function getMobileColumns() {
    return [
        {
            columnKey: "summary",
            header: <div className="text-left">Annual Data</div>,
            view: (item: ScheduleCEntity['annualData'][0]) => (
                <Col className="data-table-cell mobile-column" padding="sm" gap="xs">
                    <Row>
                        <Label className="text-mbold">{item.year}</Label>
                    </Row>
                    <Row className="space-between">
                        <Label className="data-label">Net Profit (Loss)</Label>
                        <Label className="black">{formatCurrency(item.netProfitOrLoss)}</Label>
                    </Row>
                    {item.depletion > 0 && (
                        <Row className="space-between">
                            <Label className="data-label">Depletion</Label>
                            <Label className="black">{formatCurrency(item.depletion)}</Label>
                        </Row>
                    )}
                    {item.depreciation > 0 && (
                        <Row className="space-between">
                            <Label className="data-label">Depreciation</Label>
                            <Label className="black">{formatCurrency(item.depreciation)}</Label>
                        </Row>
                    )}
                    {item.meals > 0 && (
                        <Row className="space-between">
                            <Label className="data-label">Meals</Label>
                            <Label className="black">{formatCurrency(item.meals)}</Label>
                        </Row>
                    )}
                    {item.otherExpenses > 0 && (
                        <Row className="space-between">
                            <Label className="data-label">Other Expenses</Label>
                            <Label className="black">{formatCurrency(item.otherExpenses)}</Label>
                        </Row>
                    )}
                    {item.businessUseOfHome > 0 && (
                        <Row className="space-between">
                            <Label className="data-label">Business Use of Home</Label>
                            <Label className="black">{formatCurrency(item.businessUseOfHome)}</Label>
                        </Row>
                    )}
                    {item.businessMileage > 0 && (
                        <Row className="space-between">
                            <Label className="data-label">Business Mileage</Label>
                            <Label className="black">{formatCurrency(item.businessMileage)}</Label>
                        </Row>
                    )}
                    <Row className="space-between">
                        <Label className="data-label">Total Income</Label>
                        <Label className="black">{formatCurrency(item.totalIncome)}</Label>
                    </Row>
                    <Row className="space-between">
                        <Label className="data-label">Monthly Income</Label>
                        <Label className="black">{formatCurrency(item.monthlyIncome)}</Label>
                    </Row>
                </Col>
            )
        }
    ];
}

function AnnualDataSummary({ items }: { items: ScheduleCEntity["annualData"] }) {
  return (
    <Col gap="sm">
      <h3>Annual Data</h3>
      <Table 
        data={items}
        columns={ViewResolver({
          viewModes: [
            ["Default"],
            ["Mobile-Portrait", "Mobile-Landscape"]
          ],
          resolvedContent: [
            getDesktopColumns(),
            getMobileColumns()
          ]
        })}
        itemKey={(item) => item.year.toString()}
        pageInfo={O.none}
        isLoading={false}
      />
    </Col>
  );
}

export function ScheduleCEntityCard({ entity }: Props): JSX.Element {
  return (
    <TogglePanel 
      title={entity.entityName}
      subTitle={`Monthly Income: ${formatCurrency(entity.qualifyingIncome)}`}
    >
      <Col gap="md">
        <MonthlyIncomeSummary entity={entity} />
        <AnnualDataSummary items={entity.annualData} />
      </Col>
    </TogglePanel>
  );
}
