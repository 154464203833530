import uploadImgUrl from "@/assets/images/upload.svg";
import "@/assets/styles/Documents/documents.css";
import "@/assets/styles/Tooltip/tooltip.css";
import { Button, Checkbox, Col, Icon, Label, Row } from "@/components/basic";
import {
  ApplicantId,
  ApplicationDocument,
  DocumentId,
  DocumentStatus,
  DocumentTypeId,
  EqApplicantId,
  EqRequirementId,
  getIconAlign,
  getIconType,
  getTagType,
  OrdApplicantId,
  OrdDocumentStatus,
  RequirementId,
  showDocumentStatus,
} from "@/data/applicationDocument";
import { Started } from "@/utils/asyncOperationStatus";
import { ChildProps } from "@/utils/reducerWithEffect";
import { showApiError } from "@/utils/request";
import { ViewResolver } from "@/utils/viewResolver";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import * as M from "fp-ts/lib/Map";
import * as NEA from "fp-ts/lib/NonEmptyArray";
import * as O from "fp-ts/lib/Option";
import * as Ord from "fp-ts/lib/Ord";
import * as S from "fp-ts/lib/Set";
import ProgressBar from "@ramonak/react-progress-bar";
import { useCallback, useEffect, useId, useMemo, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { OptionMenu } from "../basic/Menu/OptionMenu";
import { Tag } from "../basic/Tag";
import { TooltipWrapper } from "../basic/Tooltip/TooltipWrapper";
import {
  Action,
  DeleteDocument,
  DocumentsSelected,
  DownloadAllDocuments,
  DownloadDocument,
  GetDocuments,
  ResetImageUploadStatus,
  SkipDocumentChanged,
  SubmitDocuments,
  UploadImage,
} from "./action";
import { Model } from "./model";
import { TakePicture } from "./take-picture";
import { MoonLoader } from "react-spinners";
import { useRights } from "@/utils/use-rights";

export type Props = ChildProps<Model, Action> & {
  onManualClassification: (documentTypeId: DocumentTypeId) => () => void;
};
export type StatusTextProps = {
  status: DocumentStatus;
};

export function View({
  model,
  dispatch,
  onManualClassification,
}: Props): JSX.Element {
  const pollDocumentsAction = useMemo(
    () => flow(Started, GetDocuments, dispatch),
    [dispatch]
  );

  useEffect(() => {
    pollDocumentsAction();
    const intervalId = setInterval(() => {
      pollDocumentsAction();
    }, 1000 * 15);

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (model.requiredDocuments.status) {
    case "NotStarted":
      return <></>;
    case "InProgress":
      return <div>Loading...</div>;
    case "Resolved":
    case "Updating":
      return E.isRight(model.requiredDocuments.value) ? (
        <DocumentsView
          requiredDocuments={model.requiredDocuments.value.right}
          model={model}
          dispatch={dispatch}
          onManualClassification={onManualClassification}
        />
      ) : (
        <span>{showApiError(model.requiredDocuments.value.left)}</span>
      );
  }
}

type DocumentsProps = {
  requiredDocuments: ApplicationDocument[];
  model: Model;
  dispatch: (action: Action) => void;
  onManualClassification: (documentTypeId: DocumentTypeId) => () => void;
};

function DocumentsView({
  requiredDocuments,
  model,
  dispatch,
  onManualClassification,
}: DocumentsProps): JSX.Element {
  const { isBackOfficeUser } = useRights();
  const [documentBeingSnapped, setDocumentBeingSnapped] = useState<
    O.Option<ApplicationDocument>
  >(O.none);

  const documentsUploadId = useId();

  const sortedDocuments: [ApplicantId, ApplicationDocument[]][] = useMemo(
    () =>
      pipe(
        requiredDocuments,
        A.filter((v) => v.status !== DocumentStatus.Processing),
        A.map((doc): [ApplicantId, ApplicationDocument[]] => [
          doc.applicantId,
          [doc],
        ]),
        M.fromFoldable(
          EqApplicantId,
          A.getMonoid<ApplicationDocument>(),
          A.Foldable
        ),
        M.map(A.sort(OrdDocumentByStatus)),
        M.toArray(OrdApplicantId)
      ),
    [requiredDocuments]
  );
  
  const processingDocs = useMemo(
    () =>
      pipe(
        requiredDocuments,
        A.filter((v) => v.status === DocumentStatus.Processing)
      ),
    [requiredDocuments]
  );

  const TakePictureComponent = pipe(
    documentBeingSnapped,
    O.fold(
      () => <></>,
      () => (
        <TakePicture
          imageUploadStatus={model.imageUploadStatus}
          onClose={() => {
            setDocumentBeingSnapped(O.none);
            flow(ResetImageUploadStatus, dispatch)();
          }}
          onUpload={(imageData: string) =>
            flow(Started, UploadImage(imageData), dispatch)()
          }
        />
      )
    )
  );

  const applicants = [
    model.application.survey.primaryApplicant.applicantId,
    ...pipe(
      model.application.survey.jointApplicants,
      A.map((v) => v.applicant.applicantId)
    ),
  ];
  const DocumentsProgressList = applicants.map((applicantId, index) =>
    pipe(
      sortedDocuments.find((v) => v[0] == applicantId),
      O.fromNullable,
      O.map(([_, documents]) => documents),
      O.fold(constant(<></>), (documents) => (
        <DocumentsProgress
          key={index}
          name={documents[0].applicantName}
          documents={documents}
          applicantIndex={index}
        />
      ))
    )
  );

  const DownloadButton = (
    <Button
      onClick={pipe(
        model.canDownloadDocs,
        O.fromPredicate(identity),
        O.chain(() => O.some(flow(Started, DownloadAllDocuments, dispatch)))
      )}
      disabledTooltip="Restricted access"
      type="secondary"
      only={isBackOfficeUser}
    >
      Download all documents
    </Button>
  );

  const DocumentsGridList = applicants.map((applicantId, index) =>
    pipe(
      sortedDocuments.find((v) => v[0] == applicantId),
      O.fromNullable,
      O.map(([_, documents]) => documents),
      O.fold(constant(<></>), (documents) => {
        const name = documents[0].applicantName;
        return (
          <Fragment key={`${name}-${index}`}>
            <div id={`section-documents-${index}`}>
              <DocumentsGrid
                documents={documents}
                canDownloadDocs={model.canDownloadDocs}
                onManualClassification={onManualClassification}
                skippedDocuments={model.skippedDocuments}
                dispatch={dispatch}
                setDocumentBeingSnapped={(document: ApplicationDocument) =>
                  setDocumentBeingSnapped(O.some(document))
                }
                onDelete={(id) => flow(Started, DeleteDocument(id), dispatch)()}
                processingDocs={processingDocs}
                name={name}
                documentsUploadId={documentsUploadId}
                applicantIndex={index}
              />
            </div>
          </Fragment>
        );
      })
    )
  );

  const DesktopView = (
    <Col gap="lg">
      {TakePictureComponent}
      <Row gap="xl">
        <Col gap="sm" className="w-50">
          {DocumentsProgressList}
        </Col>
        <DocumentsUpload
          model={model}
          dispatch={dispatch}
          documentsUploadId={documentsUploadId}
        />
      </Row>
      <Row alignHorizontal="center" only={isBackOfficeUser}>{DownloadButton}</Row>
      {DocumentsGridList}
    </Col>
  );

  const MobileView = (
    <Col gap="lg">
      {TakePictureComponent}
      <Col gap="sm" alignHorizontal="space-around">
        {DocumentsProgressList}
      </Col>
      <DocumentsUpload
        model={model}
        dispatch={dispatch}
        documentsUploadId={documentsUploadId}
      />
      <Row alignVertical="center" alignHorizontal="center" gap="md" only={isBackOfficeUser}>
        {DownloadButton}
      </Row>
      {DocumentsGridList}
    </Col>
  );
  const ViewWrapper = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [DesktopView, MobileView],
  });
  return <>{ViewWrapper}</>;
}

type DocumentsProgressProps = {
  name: string;
  documents: ApplicationDocument[];
  applicantIndex: number;
};

function DocumentsProgress({
  name,
  documents,
  applicantIndex
}: DocumentsProgressProps): JSX.Element {
  const validatedDocuments = useMemo(
    () =>
      pipe(
        documents,
        A.filter((doc) => doc.status === "Validated")
      ),
    [documents]
  );

  const requiredDocuments = useMemo(
    () =>
      pipe(
        documents,
        A.filter(
          (doc) =>
            doc.status === DocumentStatus.Validated ||
            doc.status === DocumentStatus.UploadRequired
        )
      ),
    [documents]
  );

  const scrollToDocGrid = () => {
    document.getElementById(`${name}-${applicantIndex}`)?.scrollIntoView({behavior: 'smooth'});
  }
  

  return (
    <>
      <Col
        className="w-100 progress-bar-container"
        padding="sm"
        alignHorizontal="center"
        gap="xxs"
      >
        <Label className="text-smd text-bold black pointer" onClick={() => scrollToDocGrid()}>{name}</Label>
        <ProgressBar
          className="w-100 document-progress-bar"
          isLabelVisible={false}
          completed={validatedDocuments.length}
          maxCompleted={requiredDocuments.length}
          baseBgColor="#EFEFEF"
          borderRadius="0"
          bgColor="#006E2B"
        ></ProgressBar>
        <Row alignVertical="center" className="documents-progress-info">
          <span className="text-bold text-smd">
            {validatedDocuments.length}
          </span>
          <span>/</span>
          <span className="text-md">{requiredDocuments.length}</span>
          <span className="uploaded-text">Documents Uploaded</span>
        </Row>
      </Col>
    </>
  );
}

type DocumentsGridProps = {
  documents: ApplicationDocument[];
  skippedDocuments: Set<RequirementId>;
  onDelete: (documentId: DocumentId) => void;
  dispatch: (action: Action) => void;
  onManualClassification: (documentTypeId: DocumentTypeId) => () => void;
  setDocumentBeingSnapped: (document: ApplicationDocument) => void;
  canDownloadDocs: boolean;
  processingDocs: ApplicationDocument[];
  name: string;
  documentsUploadId: string;
  applicantIndex: number;
};

const OrdDocumentByStatus = Ord.contramap(
  (doc: ApplicationDocument) => doc.status
)(OrdDocumentStatus);

function DocumentsGrid({
  documents,
  skippedDocuments,
  onDelete,
  dispatch,
  canDownloadDocs,
  onManualClassification,
  setDocumentBeingSnapped,
  processingDocs,
  name,
  documentsUploadId,
  applicantIndex
}: DocumentsGridProps): JSX.Element {
  const createDownloadHandler = (documentId: O.Option<DocumentId>) => () => {
    return pipe(
      documentId,
      O.chain((id) => {
        return pipe(
          canDownloadDocs,
          O.fromPredicate((val) => val === true),
          O.map(() => id),
          O.map((id) => flow(Started, DownloadDocument(id), dispatch))
        );
      })
    );
  };

  const selectFileHandler = () => {
    document.getElementById(documentsUploadId)?.click();
    return;
  };

  const scrollId = `${name}-${applicantIndex}`

  const DesktopView = (
    <Col gap="md" id={scrollId}>
      {A.isNonEmpty(processingDocs) && (
        <DocumentsProgressListView progressingDocs={processingDocs} />
      )}
      <Label className="applicant-name title-header-color">{name}</Label>
      <div className="grid-4 upload-document-table w-100">
        <span className="text-md text-mbold document-table-header grid-item-1">
          Document type
        </span>
        <span className="text-md text-mbold document-table-header grid-item-1">
          Status
        </span>
        <span className="text-md text-mbold document-table-header grid-item-1">
          File name
        </span>
        <span className="text-md text-mbold document-table-header grid-item-1">
          &nbsp;
        </span>

        {documents.map((document, index) => {
          const downloadHandler = createDownloadHandler(document.documentId);
          // const viewHandler = pipe(
          //   document.documentId,
          //   O.map(() => () => {}),
          // );
          return (
            <Fragment key={index}>
              <hr className="grid-item-4" />
              <Col className="grid-item-1" gap="xs" alignVertical="center">
                <span className="text-xs text-mbold document-name">
                  {document.documentName}
                </span>
                {O.isSome(document.documentSubCaption) ? (
                  <span className="text-xxs text-mbold color-grey-50">
                    {document.documentSubCaption.value}
                  </span>
                ) : (
                  <span></span>
                )}
              </Col>
              <Col alignHorizontal="left" alignVertical="center">
                <StatusTag
                  document={document}
                  onManualClassification={onManualClassification}
                  noBorderAndBg={false}
                  onClick={pipe(
                    O.some(document.status),
                    O.filter((v) => v === DocumentStatus.UploadRequired),
                    O.fold(
                      () => O.none,
                      () => O.some(() => selectFileHandler())
                    )
                  )}
                />
              </Col>
              <Row
                className="grid-item-1"
                gap="xs"
                alignHorizontal="space-between"
              >
                <Col gap="xs">
                  {O.isSome(document.originalFilename) ? (
                    <Button
                      type="flat"
                      onClick={O.some(
                        createDownloadHandler(document.documentId)
                      )}
                      className="ml-left-none"
                    >
                      <span className="document-file-name text-xs">
                        {document.originalFilename.value}
                      </span>
                    </Button>
                  ) : document.isHardRequirement ? (
                    <></>
                  ) : (
                    <Checkbox
                      className="f-14"
                      label="I can't find this document"
                      onChange={flow(
                        SkipDocumentChanged(document.requirementId),
                        dispatch
                      )}
                      checked={S.elem(EqRequirementId)(document.requirementId)(
                        skippedDocuments
                      )}
                    />
                  )}
                  {pipe(
                    document.canTakePhoto &&
                      document.status !== DocumentStatus.Validated &&
                      document.status !== DocumentStatus.Submitted,
                    O.fromPredicate(identity),
                    O.fold(
                      () => <></>,
                      () => (
                        <Button
                          onClick={O.some(() =>
                            setDocumentBeingSnapped(document)
                          )}
                          type="flat"
                          className="link ml-left-none"
                        >
                          <Row
                            gap="xxs"
                            className="text-md text-mbold"
                            wrap={false}
                          >
                            <Icon type="camera" />
                            Take Photo
                          </Row>
                        </Button>
                      )
                    )
                  )}
                </Col>
              </Row>
              <Col
                className="grid-item-1 document-options"
                gap="xs"
                alignHorizontal="space-between"
              >
                <OptionMenu
                  menuItems={[
                    // { icon: "eye", label: "View", onClick: viewHandler },
                    {
                      icon: "download",
                      label: "Download",
                      onClick: downloadHandler(),
                    },
                    {
                      icon: "trash-can",
                      label: "Remove",
                      onClick: pipe(
                        document.documentId,
                        O.map((id) => () => onDelete(id))
                      ),
                    },
                  ]}
                />
              </Col>
            </Fragment>
          );
        })}
      </div>
    </Col>
  );

  const MobileView = (
    <Col gap="md" id={scrollId}>
      {A.isNonEmpty(processingDocs) && (
        <DocumentsProgressListView progressingDocs={processingDocs} />
      )}
      <Label className="applicant-name title-header-color">{name}</Label>
      <div>
        {documents.map((document, index) => (
          <Fragment key={index}>
            <Row
              className="grid-item-1"
              gap="xs"
              alignHorizontal="space-between"
            >
              <Col className="grid-item-1" gap="xs">
                <span className="text-xs text-mbold document-name">
                  {document.documentName}
                </span>
                {O.isSome(document.documentSubCaption) ? (
                  <span className="text-xxs text-mbold">
                    {document.documentSubCaption.value}
                  </span>
                ) : (
                  <></>
                )}
                <StatusTag
                  document={document}
                  onManualClassification={onManualClassification}
                  noBorderAndBg={true}
                  onClick={pipe(
                    O.some(document.status),
                    O.filter((v) => v === DocumentStatus.UploadRequired),
                    O.fold(
                      () => O.none,
                      () => O.some(() => selectFileHandler())
                    )
                  )}
                />
              </Col>
              <Row
                className="grid-item-1"
                gap="xs"
                alignHorizontal="space-between"
              >
                {pipe(
                  document.canTakePhoto,
                  O.fromPredicate(identity),
                  O.fold(
                    () => <></>,
                    () => (
                      <>
                        <span onClick={() => selectFileHandler()}>
                          <Icon type="circle-upload" />
                        </span>
                        <span onClick={() => setDocumentBeingSnapped(document)}>
                          <Icon type="circle-camera" />
                        </span>
                      </>
                    )
                  )
                )}
              </Row>
            </Row>
            <hr className="grid-item-4" />
          </Fragment>
        ))}
      </div>
    </Col>
  );

  const ViewWrapper = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [DesktopView, MobileView],
  });

  return <>{ViewWrapper}</>;
}

type DocumentsUploadProps = Omit<Props, "onManualClassification"> & {
  documentsUploadId: string;
};

function DocumentsUpload(props: DocumentsUploadProps): JSX.Element {
  const { model, dispatch, documentsUploadId } = props;

  // State to track the status of the selected files
  const [fileStatus, setFileStatus] = useState<"selected" | "uploaded" | null>(
    null
  );

  // Set count when document selected and uploaded for message display
  const [count, setCount] = useState<number>(0);
  const dragOverHandler = useCallback((evt: React.DragEvent) => {
    evt.preventDefault();
  }, []);

  const dropHandler = useCallback(
    (evt: React.DragEvent) => {
      evt.preventDefault();
      const files = pipe(
        O.fromNullable(evt.dataTransfer.items),
        O.chain((itemsList) =>
          pipe(
            Array.from(itemsList),
            A.filterMap((item) => O.fromNullable(item.getAsFile())),
            NEA.fromArray
          )
        ),
        O.alt(() => pipe(Array.from(evt.dataTransfer.files), NEA.fromArray))
      );

      if (O.isSome(files)) {
        pipe(files.value, DocumentsSelected, dispatch);
        setCount(files.value.length);
        setFileStatus("selected"); // Set status to "selected"
      }
    },
    [dispatch]
  );

  const filesChangedHandler = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const files = pipe(
        evt.target.files,
        O.fromNullable,
        O.chain((fileList) => pipe(Array.from(fileList), NEA.fromArray))
      );

      if (O.isSome(files)) {
        pipe(files.value, DocumentsSelected, dispatch);
        setCount(files.value.length);
        setFileStatus("selected"); // Set status to "selected"
      }
    },
    [dispatch]
  );

  const uploadDocuments = () => {
    if (O.isSome(model.selectedDocuments)) {
      setCount(model.selectedDocuments.value.length);
      return flow(
        Started,
        SubmitDocuments(model.selectedDocuments.value),
        dispatch
      )();
    }
  };

  // Use effect to change the status to "uploaded" after files are successfully uploaded
  useEffect(() => {
    if (
      model.documentsSubmission.status === "Resolved" &&
      O.isNone(model.selectedDocuments)
    ) {
      setFileStatus("uploaded");
      setTimeout(() => {
        setFileStatus(null);
        setCount(0);
      }, 1000 * 3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.documentsSubmission.status]);

  const a = 0;
  useEffect(() => {
    setFileStatus('uploaded');
  }, [a])
  const DesktopView = (
    <div
      className="drag-n-drop-area pointer w-50"
      onDragOver={dragOverHandler}
      onDrop={dropHandler}
    >
      <Col
        gap="xxs"
        padding="xs"
        alignVertical="center"
        alignHorizontal="center"
      >
        <img src={uploadImgUrl} alt="Upload" />

        <div className="drag-drop-message-header">
          Drag and drop files so we can auto-classify
        </div>
        <div className="drag-drop-message-content text-sm">
          Drop files here or{" "}
          <label htmlFor={documentsUploadId} className="upload-label text-sm">
            click browse
          </label>{" "}
          thorough your machine. <br />
          Accepted formats are PDF, JPEG, PNG, GIF, TIF and BMP files supported
          (200MB limit).
        </div>
        <input
          id={documentsUploadId}
          type="file"
          className="upload-input"
          multiple
          onChange={filesChangedHandler}
        />
        {O.some(count) && count > 0 && (
          <>
            {/* <span> */}
            {fileStatus === "uploaded" ? (
              <>
                {count} {count === 1 ? "file" : "files"} uploaded
              </>
            ) : (
              <>
                <>
                  {count} {count === 1 ? "file" : "files"} selected
                </>
                <Button
                  type="primary"
                  onClick={O.some(() => {
                    uploadDocuments();
                  })}
                >
                  Upload
                </Button>
              </>
            )}
            {/* </span> */}
          </>
        )}
      </Col>
    </div>
  );

  const MobileView = (
    <>
      <input
        id={documentsUploadId}
        type="file"
        className="upload-input"
        onChange={filesChangedHandler}
      />
    </>
  );
  const ViewWrapper = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [DesktopView, MobileView],
  });

  switch (model.documentsSubmission.status) {
    case "NotStarted":
    case "Resolved":
      return <>{ViewWrapper}</>;

    case "InProgress":
    case "Updating":
      return (
        <Col
          className="drag-n-drop-area w-50"
          gap="xs"
          padding="xs"
          alignVertical="center"
          alignHorizontal="center"
        >
          <img src={uploadImgUrl} alt="Upload" />
          <label className="text-md">Uploading...</label>
        </Col>
      );
  }
}

type DocumentStatusProps = {
  document: ApplicationDocument;
  onManualClassification?: (documentTypeId: DocumentTypeId) => () => void;
  noBorderAndBg: boolean;
  onClick: O.Option<() => void>;
};

function StatusTag({
  document,
  onManualClassification,
  noBorderAndBg,
  onClick,
}: DocumentStatusProps): JSX.Element {
  const renderTooltipText = (document: ApplicationDocument) => {
    const shouldShowTooltip = [
      DocumentStatus.Error,
      DocumentStatus.Extra,
      DocumentStatus.Validated,
    ].includes(document.status);

    return pipe(
      shouldShowTooltip,
      O.fromPredicate(() => shouldShowTooltip),
      O.chain(() => document.errorMessage),
      O.map((message) => {
        switch (document.status) {
          case DocumentStatus.Error:
            return (
              <>
                {message}
                <Button
                  type="inline-inverted"
                  className="text-underline"
                  onClick={O.some(() => {
                    if (onManualClassification) {
                      onManualClassification(document.documentTypeId)();
                    }
                  })}
                >
                  Please upload again
                </Button>
              </>
            );
          default:
            return <>{message}</>;
        }
      })
    );
  };

  return (
    <TooltipWrapper
      purpose="tooltip"
      tooltipChildren={renderTooltipText(document)}
    >
      <Tag
        type={getTagType(document.status)}
        iconAlign={getIconAlign(document.status)}
        icon={getIconType(document.status)}
        className={
          `${document.status === DocumentStatus.Submitted ? "submitted" : ""}
          ${document.status === DocumentStatus.UploadRequired ? "upload-required" : ''}
          ${onClick && O.isSome(onClick) ? 'clickable' : ''}
          `
        }
        noBgAndBorder={noBorderAndBg}
        onClick={O.some(() => {
          if (
            O.isSome(onClick) &&
            document.status === DocumentStatus.UploadRequired
          ) {
            onClick.value();
          }
        })}
      >
        {showDocumentStatus(document.status)}
      </Tag>
    </TooltipWrapper>
  );
}

type DocumentsProgressListProps = {
  progressingDocs: ApplicationDocument[];
};

function DocumentsProgressListView({
  progressingDocs,
}: DocumentsProgressListProps) {
  const DesktopView = (
    <div className="grid-3 w-100 upload-document-table">
      {progressingDocs.map((document, index) => {
        return (
          <Fragment key={index}>
            <Col alignHorizontal="left" alignVertical="center">
              <StatusTag
                document={document}
                noBorderAndBg={false}
                onClick={O.none}
              />
            </Col>
            <Col className="grid-item-1" gap="xs" alignVertical="center">
              <span className="text-md label document-name">
                {pipe(
                  document.originalFilename,
                  O.fold(
                    () => <></>,
                    (v) => <>{v}</>
                  )
                )}
              </span>
            </Col>
            <Col>
              <MoonLoader size={20} color="#36F" className="grid-item-1" />
            </Col>
            <hr className="grid-item-3" />
          </Fragment>
        );
      })}
    </div>
  );

  const MobileView = (
    <div>
      {progressingDocs.map((document, index) => {
        return (
          <Fragment key={index}>
            <Row
              className="grid-item-1"
              gap="xs"
              alignHorizontal="space-between"
            >
              <Col className="grid-item-1" gap="xs">
                <span className="text-md label document-name">
                  {pipe(
                    document.originalFilename,
                    O.fold(
                      () => <></>,
                      (v) => <>{v}</>
                    )
                  )}
                </span>
                <StatusTag
                  document={document}
                  noBorderAndBg={true}
                  onClick={O.none}
                />
              </Col>
              <Row className="grid-item-1">
                <Col>
                  {/* <span className="text-md text-mbold document-table-header grid-item-1">
                &nbsp;
              </span> */}
                  <MoonLoader size={20} color="#36F" className="grid-item-1" />
                </Col>
              </Row>
            </Row>
            <hr className="grid-item-3" />
          </Fragment>
        );
      })}
    </div>
  );
  const ViewWrapper = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [DesktopView, MobileView],
  });

  return <>{ViewWrapper}</>;
}
