import {
  ApplicantPayload,
  EmployerInfoPayload,
  FullEmployerInfo,
} from "@/data/payload";
import { ApplicantId } from "../action";
import { ContentProps } from "../view";
import { TogglePanel, Row, Icon, Col } from "@/components/basic";
import { ViewResolver } from "@/utils/viewResolver";
import { constant, pipe } from "fp-ts/lib/function";
import { DateTime } from "luxon";
import { Fragment } from "react/jsx-runtime";
import * as O from "fp-ts/lib/Option";

export type EmployerInfoSection = {
  applicantId: ApplicantId;
  type: "Employer Information";
};

type EmployerInfoInputProps = ContentProps & {
  employerInfoSection: EmployerInfoSection;
};

type EmployerInfoReadOnlyViewProps = {
  applicant: ApplicantPayload;
  applicantType: ApplicantId;
};

export function EmployerInformationSectionView(
  props: EmployerInfoInputProps
): JSX.Element {
  const { model, employerInfoSection } = props;

  if (employerInfoSection.applicantId.type === "Primary") {
    const readOnlyViewProps: EmployerInfoReadOnlyViewProps = {
      applicant: model.application.survey.primaryApplicant,
      applicantType: { type: "Primary" },
    };
    return <EmployerInfoSectionReadOnlyView {...readOnlyViewProps} />;
  } else if (employerInfoSection.applicantId.type === "CoApplicant") {
    const coApplicantIndex = employerInfoSection.applicantId.index;
    const applicantIndex = model.application.survey.jointApplicants.findIndex(
      (app) => app.applicant.applicantId === coApplicantIndex
    );
    if (applicantIndex === -1) {
      return <></>;
    }
    const applicant = model.application.survey.jointApplicants[applicantIndex];
    const readOnlyViewProps: EmployerInfoReadOnlyViewProps = {
      ...applicant,
      applicantType: { type: "CoApplicant", index: coApplicantIndex },
    };
    return <EmployerInfoSectionReadOnlyView {...readOnlyViewProps} />;
  } else {
    return <></>;
  }
}

export function EmployerInfoSectionReadOnlyView(
  props: EmployerInfoReadOnlyViewProps
): JSX.Element {
  const { applicant, applicantType } = props;
  const employers = applicant.employers;

  const togglePanelClasses = ViewResolver({
    viewModes: [["Default"], ["Tablet-Portrait", "Tablet-Landscape"]],
    resolvedContent: ["grid-3", "grid-2"],
  });

  const dobHeaderClass = ViewResolver({
    viewModes: [["Default"], ["Tablet-Portrait", "Tablet-Landscape"]],
    resolvedContent: ["grid-item-1", "column-span-2"],
  });

  return (
    <TogglePanel title={applicant.fullName} subTitle={applicantType.type} defaultOpen={true}>
      <div className={togglePanelClasses}>
        <Row
          gap="xs"
          className="text-smd grid-item-3 padding-xs color-act-70 text-mbold"
        >
          <Icon type="user-tie" />
          <span>Employer Information</span>
        </Row>

        <>
          {employers.map(({ employerInfo, name }, index) => (
            <Fragment key={index}>
              <Col gap="xs" padding="sm" className="grid-item-1">
                <b>Employer Name</b>
                <span>{name}</span>
              </Col>

              {pipe(
                employerInfo,
                O.fold(constant(<></>), (empInfo) =>
                  isFullEmployerInfo(empInfo) ? (
                    <>
                      <Col gap="xs" padding="sm" className="grid-item-1">
                        <b>Start Date</b>
                        <span>
                          {empInfo.startDate.toLocaleString(
                            DateTime.DATE_SHORT
                          ) ?? "-"}
                        </span>
                      </Col>
                      <Col gap="xs" padding="sm" className={dobHeaderClass}>
                        <b>Is Still Employed</b>
                        <span>{empInfo.isStillEmployed ? "Yes" : "No"}</span>
                      </Col>
                      <Col gap="xs" padding="sm" className="grid-item-1">
                        <b>End Date</b>
                        <span>
                          {empInfo.endDate
                            ? pipe(
                                empInfo.endDate,
                                O.chain(O.fromNullable),
                                O.fold(
                                  () => <>{"-"}</>,
                                  (date) => (
                                    <>
                                      {date.toLocaleString(
                                        DateTime.DATE_SHORT
                                      ) ?? "-"}
                                    </>
                                  )
                                )
                              )
                            : "-"}
                        </span>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )
                )
              )}

              <hr className="grid-item-3" />
            </Fragment>
          ))}
        </>
      </div>
    </TogglePanel>
  );
}

function isFullEmployerInfo(
  employerInfo: EmployerInfoPayload
): employerInfo is FullEmployerInfo {
  return employerInfo.employerId != null;
}
