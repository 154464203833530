import { ApplicationType } from "@/data/payload";
import { Model } from "./model";
import * as O from "fp-ts/lib/Option";

export type Action = 
  | { type: "ApplicationTypeSelected"; applicationType: ApplicationType }
  | { type: "ExistingDataModalDismissed" }
  | { type: "ExistingDataCleared" }
  | { type: "ShowExistingDataModal" };

export const ApplicationTypeSelected = (
  applicationType: ApplicationType,
): Action => ({
  type: "ApplicationTypeSelected",
  applicationType,
});

export const ExistingDataModalDismissed = (): Action => ({
  type: "ExistingDataModalDismissed"
});

export const ExistingDataCleared = (): Action => ({
  type: "ExistingDataCleared"
});

export const ShowExistingDataModal = (): Action => ({
  type: "ShowExistingDataModal"
});

export const update = (model: Model, action: Action): Model => {
  switch (action.type) {
    case "ApplicationTypeSelected":
      return {
        ...model,
        page: "PropertyType",
        selectedApplicationType: O.some(action.applicationType),
      };
    case "ExistingDataModalDismissed":
      return { ...model, showExistingDataModal: false };
    case "ExistingDataCleared":
      return { 
        ...model, 
        showExistingDataModal: false,
        selectedApplicationType: O.none,
        page: "ApplicationType"
      };
    case "ShowExistingDataModal":
      return { ...model, showExistingDataModal: true };
  }
};
