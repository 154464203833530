import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { ReactNode } from "react";
import { Icon, IconType } from "../Icon";
import { Row } from "../Row";
import "@/assets/styles/basic/Tag/tag.css";

export type TagTypes =
  | "neutral"
  | "warning"
  | "info"
  | "success"
  | "accent"
  | "error";
export type IconAlign = "none" | "left" | "right";

export type TagProps = {
  type: TagTypes;
  iconAlign: IconAlign;
  icon: O.Option<IconType>;
  children: ReactNode;
  className?: string;
  noBgAndBorder? : boolean;
  fontStyle?: string;
  onClick?: O.Option<() => void>;
};

export function Tag(props: TagProps): JSX.Element {
  const iconToDisplay = (
    <Icon
      type={pipe(
        props.icon,
        O.getOrElse(() => "info" as IconType),
      )}
    ></Icon>
  );

  const iconWrapped = (
    <Row
      gap="xxxs"
      grow={0}
      wrap={false}
      className={`${props.noBgAndBorder ? 'no-bg-border' : ''} tag tag-${props.type} ${props.fontStyle ? props.fontStyle : 'text-xs'} ${props.className}`}
      alignHorizontal="space-between"
      alignVertical="baseline"
      onClick={() => {
        if(props.onClick && O.isSome(props.onClick)) {
          props.onClick.value();
        }
      }}
    >
      {props.iconAlign == "left" ? <>{iconToDisplay}</> : <></>}
      <div>{props.children}</div>
      {props.iconAlign == "right" ? <>{iconToDisplay}</> : <></>}
    </Row>
  );
  
  return <>{iconWrapped}</>;
}
