import "@/assets/styles/basic/Menu/menu.css";
import homePageAppCatcherLogoShort from "@/assets/images/home-page-app-catcher-logo-short.png";
import { ChildProps } from "@/utils/reducerWithEffect";
import { Action } from "../../../app/action";
import { Model } from "../../../app/model";
import { GetDeviceInfo, ViewResolver } from "@/utils/viewResolver";
import { pipe } from "fp-ts/lib/function";
import { RefObject, useContext, useEffect, useRef, useState } from "react";
import { Col } from "../Col";
import { Row } from "../Row";
import * as O from "fp-ts/lib/Option";
import { RouterContext, UserContext } from "@/utils/router-context";
import { _useRights } from "@/utils/use-rights";
import { isLoanOfficer, isSuperUser } from "@/utils/user";
import { Button } from "../Button";
import { Icon } from "../Icon";
import { Label } from "../Label";
import Navigo from "navigo";

export type PrimaryMenuProps = ChildProps<Model, Action> & {
  children: React.ReactNode;
  onMenuOpen?: () => void;
  router: Navigo;
  refContainer?: RefObject<HTMLDivElement>;
};

export type NavigationProps = {
  router: Navigo;
  onClick?: () => void;
};

export type PrimaryMenuWithNavigationProps = PrimaryMenuProps & NavigationProps;

export function PrimaryMenu(props: PrimaryMenuProps): JSX.Element {
  const deviceInfo = GetDeviceInfo();

  return (
    <Row
      refContainer={props.refContainer}
      alignVertical="center"
      alignHorizontal="space-between"
      className="menu-bar"
      gap="xs"
      background="white"
      padding="xs"
    >
      <Row className="app-logo" padding="fallback">
        <img
          src={homePageAppCatcherLogoShort}
          alt="AC"
          style={{ height: "2.5rem" }}
          className="cp"
          onClick={props.onMenuOpen}
        />
      </Row>
      {pipe(
        O.some(deviceInfo),
        O.map((deviceInfo) => {
          switch (deviceInfo) {
            case "Mobile-Portrait":
            case "Mobile-Landscape":
            case "Tablet-Portrait":
            case "Tablet-Landscape":
              return <></>;
            default:
              return <></>;
          }
        }),
        O.getOrElse(() => <></>),
      )}

      <Row className="menu">
        <Row className="menu-content">{props.children}</Row>
      </Row>
    </Row>
  );
}

export function PrimaryMenuWithNavigation(
  props: PrimaryMenuWithNavigationProps,
): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const refContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMenuOpen) {
      const handleClickOutside = (event: MouseEvent) => {
        const clickedInsideContainer =
          refContainer.current &&
          refContainer.current.contains(event.target as Node);

        if (!clickedInsideContainer) {
          setIsMenuOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [refContainer, isMenuOpen]);

  return (
    <>
      <PrimaryMenu
        {...props}
        onMenuOpen={() => setIsMenuOpen(!isMenuOpen)}
        refContainer={refContainer}
      >
        {/* <div ref={refContainer}>
        
      </div> */}
        {isMenuOpen && (
          <Col className="collapse-nav-container">
            <Col className="collapse-nav">
              <NavigationMenu
                router={props.router}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              ></NavigationMenu>
            </Col>
          </Col>
        )}
        {props.children}
      </PrimaryMenu>
    </>
  );
}

export function NavigationMenu(props: NavigationProps): JSX.Element {
  const { isBackOfficeUser } = _useRights();
  const { router } = props;
  return (
    <>
      {pipe(
        isBackOfficeUser,
        O.fold(
          () => <></>,
          () => (
            <Col
              padding="fallback"
              gap="sm"
              background="white"
              alignHorizontal="center"
              grow={1}
              className="app-nav-menu-items-cont"
            >
              <NavigationMenuItems
                router={router}
                onClick={props.onClick}
              ></NavigationMenuItems>
            </Col>
          ),
        ),
      )}
    </>
  );
}

export function NavigationMenuView(props: NavigationProps): JSX.Element {
  return (
    <>
      {ViewResolver({
        viewModes: [
          ["Default"],
          [
            "Mobile-Landscape",
            "Mobile-Portrait",
            "Tablet-Portrait",
            "Tablet-Landscape",
          ],
        ],
        resolvedContent: [<NavigationMenu {...props} />, <></>],
      })}
    </>
  );
}

export function NavigationMenuItems(props: NavigationProps): JSX.Element {
  const { router } = useContext(RouterContext);
  const { user } = useContext(UserContext);

  const navigateToPage = (route: string) => {
    if (route) {
      router.navigate(route, { callHooks: true, updateState: true, callHandler: true });
      router.resolve();
      props.onClick ? props.onClick() : {};
    }
  };

  const match = router.getCurrentLocation();

  const isActiveRoute = (url: string) => {
    return pipe(
      match,
      O.some,
      O.map((value) => value.url.includes(url)),
      O.getOrElse(() => false),
    );
  };

  const getActiveClass = (url: string) => {
    return url && isActiveRoute(url) ? "active-route-item" : "";
  };

  return (
    <>
      <Col
        alignHorizontal="center"
        onClick={() => navigateToPage("applynow")}
        className={getActiveClass("applynow")}
      >
        <Button
          type="tertiary"
          className="ac-nav-icon add-app-btn"
          onClick={O.some(() => {})}
        >
          <Icon type="plus" />
        </Button>
      </Col>
      <Col
        alignHorizontal="center"
        onClick={() => navigateToPage("/dashboard")}
        className={getActiveClass("dashboard")}
      >
        <Button
          type="tertiary"
          className="ac-nav-icon"
          onClick={O.some(() => {})}
        >
          <Icon type="house" />
        </Button>
        <Label className="nav-label cp">Home</Label>
      </Col>
      <Col
        alignHorizontal="center"
        onClick={() => navigateToPage("/applications-list")}
        className={getActiveClass("applications-list")}
      >
        <Button
          type="tertiary"
          className="ac-nav-icon"
          onClick={O.some(() => {})}
        >
          <Icon type="layer-group" />
        </Button>
        <Label className="nav-label cp">Clients</Label>
      </Col>
      <Col
        alignHorizontal="center"
        onClick={() => navigateToPage("/org/branches")}
        className={getActiveClass("org/branches")}
        only={pipe(user, O.map(isSuperUser))}
      >
        <Button
          type="tertiary"
          className="ac-nav-icon"
          onClick={O.some(() => {})}
        >
          <Icon type="users" />
        </Button>
        <Label className="nav-label cp">Users</Label>
      </Col>
      <Col
        alignHorizontal="center"
        onClick={() => navigateToPage("/settings/billing")}
        className={getActiveClass("settings/billing")}
        only={pipe(user, O.map(isSuperUser))}
      >
        {O.isSome(user) && isLoanOfficer(user.value) && (
          <>
            <Button
              type="tertiary"
              className="ac-nav-icon"
              onClick={O.some(() => {})}
            >
              <Icon type="layer-group" />
            </Button>
            <Label className="nav-label cp">Billing</Label>
          </>
        )}
      </Col>
      <Col
        alignHorizontal="center"
        onClick={() => navigateToPage("/settings/general")}
        className={getActiveClass("/settings/general")}
      >
        <Button
          type="tertiary"
          className="ac-nav-icon"
          onClick={O.some(() => {})}
        >
          <Icon type="gear" />
        </Button>
        <Label className="nav-label cp">Settings</Label>
      </Col>
      <Col alignHorizontal="center" className={getActiveClass("")}>
        <Button
          type="tertiary"
          className="ac-nav-icon"
          onClick={O.some(() => navigateToPage(""))}
        >
          <Icon type="gear" />
        </Button>
        <Label className="nav-label cp">Support</Label>
      </Col>
    </>
  );
}

export function PrimaryMenuViewWrapper(props: PrimaryMenuProps): JSX.Element {
  const { model, dispatch, router } = props;
  return ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [
      <PrimaryMenu model={model} dispatch={dispatch} router={router}>
        {props.children}
      </PrimaryMenu>,
      <PrimaryMenuWithNavigation
        model={model}
        dispatch={dispatch}
        router={router}
      >
        {props.children}
      </PrimaryMenuWithNavigation>,
    ],
  });
}
