import '@/assets/styles/Tooltip/tooltip.css';
import 'react-tooltip/dist/react-tooltip.css';
import { useId } from "react";
import { Tooltip } from "react-tooltip";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";


type Props = {
  tooltipChildren: O.Option<JSX.Element>;
  children: JSX.Element;
  purpose: "menu" | "tooltip" 
};
export function TooltipWrapper(props: Props): JSX.Element {
  const id = `a${useId()}b`.replace(/:/g, "");

  return pipe(
    props.tooltipChildren,
    O.map((children) => (
      <>
        <a id={id} className={`${props.purpose}-tooltip`}>{props.children}</a>
        <Tooltip
          className="menu-tooltip"
          clickable
          anchorSelect={`#${id}`}
          style={{ maxWidth: "15rem", fontSize:12 }}
        >
          {children}
        </Tooltip>
      </>
    )),
    O.getOrElse(() => <>{props.children}</>),
  );
}
