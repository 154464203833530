import { Button, Col, Modal } from "@/components/basic";
import * as O from "fp-ts/lib/Option";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import * as EmployerResolver from "../../EmployerResolver";
import { identity, pipe } from "fp-ts/lib/function";
import { flow } from "lodash-es";
import {
  EmployerResolverAction,
  ClearMissingEmployer,
  SetMissingEmployer,
  AcceptExportWarning,
  ResetExportStatus,
  ManualClassificationFileSelected,
  SubmitManualClasification,
  CancelManualDocumentSelection,
} from "../action";
import * as ManualDocClassifier from "@/components/ManualDocClassifier";
import { ContentProps } from "../view";
import { useEffect, useRef } from "react";
import { EmployersPayload } from "@/data/payload";
import { showApiError } from "@/utils/request";
import { BounceLoader } from "react-spinners";
import { Started } from "@/utils/asyncOperationStatus";

export function EmployerResolverModalSectionView(
  props: ContentProps
): JSX.Element {
  const { model, dispatch } = props;

  const employerResolverModalId = "modal-employer-resolver";
  const employerModalRef = useRef<HTMLDivElement>(null);
  const setMissingEmployerAction = flow(SetMissingEmployer, dispatch);
  const clearMissingEmployerAction = flow(ClearMissingEmployer, dispatch);

  useEffect(() => {
    if (model.manualClassification.isOpen) {
      return;
    }
    const missingEmployer = pipe(
      model.employers,
      O.flatMap((employers: EmployersPayload[]) =>
        pipe(
          employers,
          A.filter((employer) => O.isNone(employer.employerInfo)),
          A.head
        )
      ),
      O.map((employer) => {
        if (O.isNone(model.employerResolverModel)) {
          setMissingEmployerAction(employer);
        }
        return employer;
      })
    );

    if (O.isNone(missingEmployer) && O.isSome(model.employerResolverModel)) {
      clearMissingEmployerAction();
    }
  }, [
    model.employers,
    setMissingEmployerAction,
    clearMissingEmployerAction,
    model.employerResolverModel,
    model.manualClassification.isOpen,
  ]);
  return (
    <>
      {pipe(
        model.employerResolverModel,
        O.map((employerResolverModel) => {
          return (
            <Modal
              ref={employerModalRef}
              onClose={O.none}
              title={O.some("We need some information about your employer")}
              id={employerResolverModalId}
            >
              <EmployerResolver.View
                model={employerResolverModel}
                dispatch={flow(EmployerResolverAction, dispatch)}
                onEmployerResolved={flow(ClearMissingEmployer, dispatch)}
                modalId={employerResolverModalId}
                modalRef={employerModalRef.current}
              />
            </Modal>
          );
        }),
        O.getOrElse(() => <></>)
      )}
    </>
  );
}

export function ExportWarningModalSectionView(
  props: ContentProps
): JSX.Element {
  const { model, dispatch } = props;
  return (
    <>
      {
        //show warning on 'incomplete' status exports
        pipe(
          model.exportWarningShown,
          O.chain((v) =>
            pipe(
              v,
              O.fromPredicate((v) => !v)
            )
          ),
          O.map(() => (
            <Modal
              onClose={O.none}
              title={O.some("Export Application")}
              className="export-modal"
            >
              <Col gap="md" alignHorizontal="center">
                <Col className="text-lg text-mbold color-text-warning">
                  Warning!
                </Col>
                <Col>
                  This application is incomplete. You may continue with export,
                  but much of the data will be missing from the exported data.
                </Col>
                <Button
                  type="primary"
                  onClick={O.some(flow(AcceptExportWarning, dispatch))}
                >
                  Ok
                </Button>
              </Col>
            </Modal>
          )),
          O.getOrElse(() => <></>)
        )
      }
    </>
  );
}

export function ExportModalView(props: ContentProps): JSX.Element {
    const { model, dispatch } = props;
    return (
        <>{
            pipe(
                model.exportStatus,
                O.fromPredicate((exportStatus) =>
                  ["InProgress", "Resolved"].includes(exportStatus.status),
                ),
                O.map((exportStatus) => {
                  switch (exportStatus.status) {
                    case "InProgress":
                      return (
                        <Modal
                          onClose={O.none}
                          title={O.some("Export Application")}
                          className="export-modal"
                        >
                          <Col alignHorizontal="center" gap="md">
                            <BounceLoader color="#2A2250" />
                            <Col>Please wait while we are exporting your application.</Col>
                          </Col>
                        </Modal>
                      );
                    case "Resolved": {
                      const { value } = exportStatus;
                      if (E.isLeft(value)) {
                        return (
                          <Modal
                            onClose={O.some(flow(ResetExportStatus, dispatch))}
                            title={O.some("Export Application")}
                            className="export-modal"
                          >
                            <Col gap="md" alignHorizontal="center">
                              <Col className="text-lg text-mbold color-text-failed">
                                Failed!
                              </Col>
                              <Col>
                                There was an error exporting the documents.
                                <div>{showApiError(value.left)}</div>
                              </Col>
                              <Button
                                type="primary"
                                onClick={O.some(flow(ResetExportStatus, dispatch))}
                              >
                                Close
                              </Button>
                            </Col>
                          </Modal>
                        );
                      } else {
                        return (
                          <Modal
                            onClose={O.some(flow(ResetExportStatus, dispatch))}
                            title={O.some("Export Application")}
                            className="export-modal"
                          >
                            <Col gap="md" alignHorizontal="center">
                              <Col className="text-lg text-mbold color-text-success">
                                Success!
                              </Col>
                              <Col>
                                The export package has been successfully exported and
                                downloaded.
                              </Col>
                              <Button
                                type="primary"
                                onClick={O.some(flow(ResetExportStatus, dispatch))}
                              >
                                Close
                              </Button>
                            </Col>
                          </Modal>
                        );
                      }
                    }
                    default:
                      return <></>;
                  }
                }),
                O.getOrElse(() => <></>),
              )
        }</>
    )
}

export function ManualClassificationModalSectionView(props: ContentProps): JSX.Element {
    const { model, dispatch } = props;
    return(
        <>
        {pipe(
                model.manualClassification?.isOpen,
                O.fromPredicate(identity),
                O.map(() => (
                  <ManualDocClassifier.View
                    model={model.manualClassification}
                    onFileSelect={(file: O.Option<File>) =>
                      flow(ManualClassificationFileSelected(file), dispatch)
                    }
                    onDocumentUpload={(file: File) =>
                      flow(Started, SubmitManualClasification(file), dispatch)
                    }
                    onClose={O.some(flow(CancelManualDocumentSelection, dispatch))}
                  />
                )),
                O.getOrElse(() => <></>),
              )}
        </>
    )
}
