import {
  Button,
  Checkbox,
  Col,
  ConfirmSsnInputField,
  Icon,
  InputField,
  Label,
  Row,
  SSNInputField,
  TogglePanel,
} from "@/components/basic";
import { ChildProps } from "@/utils/reducerWithEffect";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import { constant, flow, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import {
  Action,
  ConsentItemChanged,
  ConsentSubmitted,
  DateOfBirthChanged,
  SocialSecurityNumberChanged,
} from "./action";
import { Model } from "./model";

import { SaveConsentsPayload } from "@/data/consents";
import { ApplicationId } from "@/data/payload";
import { OrdSurveyTips } from "@/data/surveyTips";
import { Started } from "@/utils/asyncOperationStatus";
import { ConfirmSocialSecurityNumber } from "@/utils/codecs";
import { deferredToOption } from "@/utils/deferred";
import { FormField } from "@/utils/formField";
import { showApiError } from "@/utils/request";
import { useMemo } from "react";
import { result } from "./model";
import { Lens } from "monocle-ts";
import { ViewResolverProps, ViewResolver } from "@/utils/viewResolver";
import { formatPhoneNumber } from "@/utils/formatters";

export type Props = ChildProps<Model, Action> & {
  onApplicationSelected: (applicationId: ApplicationId) => void;
};

type ViewWrapperProps = {
  children: React.ReactNode;
};

function ViewWrapper(props: ViewWrapperProps): JSX.Element {
  const desktopView = <Row grow={1}>{props.children}</Row>;

  const mobileView = <Col grow={1}>{props.children}</Col>;

  const viewSetup: ViewResolverProps<JSX.Element> = {
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Tablet-Portrait",
        "Mobile-Landscape",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [desktopView, mobileView],
  };
  return ViewResolver(viewSetup);
}

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;
  const { tips } = model;

  const surveyValidationResult = useMemo(
    () => result(model),

    [model],
  );

  const areConsentMethodsOk = useMemo(
    () => surveyValidationResult[1].left.length == 0,
    [surveyValidationResult],
  );

  const surveyPayload = useMemo(
    () =>
      pipe(
        surveyValidationResult[0],
        O.fromEither,
        O.map((payload: SaveConsentsPayload): SaveConsentsPayload => {
          const lens = Lens.fromPath<SaveConsentsPayload>()(["consents"]);
          const pageModel = model;

          return lens.modify((model) =>
            pipe(model, (consentItems) => {
              consentItems.push(
                ...pageModel.form.consentItems
                  .filter((v) => !v.requiredForBorrower)
                  .map((v) => ({
                    consentVersionId: v.consentVersionId,
                    method: null,
                    consentProvided: v.raw,
                  })),
              );
              return consentItems;
            }),
          )(payload);
        }),
      ),
    [surveyValidationResult, model],
  );

  const submitConsentAction = useMemo(
    () =>
      pipe(
        areConsentMethodsOk,
        O.fromPredicate(identity),
        O.chain(constant(surveyPayload)),
        O.map((payload: SaveConsentsPayload) =>
          flow(
            Started,
            ConsentSubmitted(payload, props.onApplicationSelected),
            dispatch,
          ),
        ),
      ),
    [surveyPayload, dispatch, props.onApplicationSelected, areConsentMethodsOk],
  );

  const surveyTips = useMemo(() => {
    return pipe(
      tips,
      deferredToOption,
      O.chain(O.fromEither),
      O.fold(
        () => [],
        flow(
          A.filter(
            ({ pageIdentifier }) => "survey-ssn-consent" === pageIdentifier,
          ),
          A.sort(OrdSurveyTips),
        ),
      ),
    );
  }, [tips]);

  return (
    <ViewWrapper>
      <Col grow={4} basis="0">
        <Col
          gap="xl"
          grow={1}
          className={ViewResolver({
            viewModes: [
              ["Default"],
              [
                "Tablet-Landscape",
                "Tablet-Portrait",
                "Mobile-Portrait",
                "Mobile-Landscape",
              ],
            ],
            resolvedContent: [
              "suvery-page-flow survey-content",
              "suvery-page-flow",
            ],
          })}
        >
          <SurveryContent {...props} />
        </Col>
        <Row background="white" padding="xs" alignHorizontal="right">
          <Button
            type="primary"
            onClick={submitConsentAction}
            className="padding-md"
          >
            Continue
            <i className="fa-solid fa-arrow-right ml-left-xxs" />
          </Button>
        </Row>
      </Col>

      <Col grow={1} basis="10vw">
        <Col grow={1} className="tips tips-content-flow" padding="sm">
          {surveyTips.map((tip) => (
            <Col
              key={tip.pageIdentifier + tip.pageTipId}
              padding="sm"
              gap="sm"
              className="container"
              alignHorizontal="left"
            >
              <Row
                padding="xxxs"
                gap="xxxs"
                className="tip-question"
                alignVertical="center"
              >
                <Icon type="lightbulb" />
                <span>{tip.prompt}</span>
              </Row>
              <Row gap="xs">
                <img height={32} src={tip.avatarUrl} />
                <Label>
                  <b>{tip.title}</b>
                </Label>
              </Row>
              <Label>{tip.content}</Label>
            </Col>
          ))}
        </Col>
        <Col className="loan-officer-info">
          <TogglePanel
            title={model.verificationStatus.loanOfficer.fullName || "--"}
            subTitle="Loan Officer"
            toggleHeaderClassName="lo-info-toggle"
            showIcon={true}
          >
            <Col gap="sm">
              <Row alignVertical="center" gap="sm">
                <Col
                  className="icon-wrapper"
                  alignHorizontal="center"
                  alignVertical="center"
                >
                  <i className="fa-regular fa-envelope"></i>
                </Col>
                <Label className="text-xs black">
                  {model.verificationStatus.loanOfficer.email && (
                    <a
                      href={`mailto:${model.verificationStatus.loanOfficer.email}`}
                    >
                      {model.verificationStatus.loanOfficer.email}
                    </a>
                  )}
                </Label>
              </Row>
              <Row alignVertical="center" gap="sm">
                <Col
                  className="icon-wrapper"
                  alignHorizontal="center"
                  alignVertical="center"
                >
                  <i className="fa-solid fa-phone"></i>
                </Col>
                <Label className="text-xs black">
                  {formatPhoneNumber(
                    model.verificationStatus.loanOfficer.phone,
                  ) || "-"}
                </Label>
              </Row>
              <Row alignVertical="center" gap="sm">
                <Col
                  className="icon-wrapper"
                  alignHorizontal="center"
                  alignVertical="center"
                >
                  <i className="fa-solid fa-location-dot"></i>
                </Col>
                {/* Add address when available in response */}
                <Label className="text-xs black">-</Label>
              </Row>
            </Col>
          </TogglePanel>
        </Col>
      </Col>
    </ViewWrapper>
  );
}

function SurveryContent(props: Props): JSX.Element {
  const { model, dispatch } = props;
  return (
    <>
      <Col padding="xl" grow={1} gap="xl">
        <Col>
          <h3 className="text-lg">
            In order to access your application we have a few steps you need to
            complete.
          </h3>
        </Col>
        <Col gap="xl" grow={1}>
          {model.form?.primaryApplicant && (
            <>
              {model.ssnConsentVerificationFailed ? (
                <Col gap="xs" className="container error" padding="xs">
                  <div className="text-lg">Contact your loan officer</div>
                  <div>
                    Seems like your having issues with providing the details
                    below. Please contact{" "}
                    {model.verificationStatus.loanOfficer.fullName} at{" "}
                    <a
                      href={`mailto:${model.verificationStatus.loanOfficer.email}`}
                    >
                      {model.verificationStatus.loanOfficer.email}
                    </a>
                  </div>
                </Col>
              ) : (
                <></>
              )}

              <div className="grid-2 gap-xs">
                <Label
                  className={ViewResolver({
                    viewModes: [
                      ["Default"],
                      ["Mobile-Portrait", "Mobile-Landscape"],
                    ],
                    resolvedContent: [
                      "applicant-name survey-applicant-name column-span-2",
                      "applicant-name survey-applicant-name",
                    ],
                  })}
                >
                  {model.form.primaryApplicant.name}
                </Label>

                <ConfirmSsnInputField
                  label="Last 4 digits of your Social Security Number"
                  field={
                    model.form.primaryApplicant
                      .socialSecurityNumber as FormField<ConfirmSocialSecurityNumber>
                  }
                  onChange={flow(
                    SocialSecurityNumberChanged("PrimaryApplicant"),
                    dispatch,
                  )}
                  required={true}
                />

                <InputField
                  label="Date Of Birth"
                  type="date"
                  className="column-span-1"
                  field={model.form.primaryApplicant.dateOfBirth}
                  onChange={flow(
                    DateOfBirthChanged("PrimaryApplicant"),
                    dispatch,
                  )}
                  required={true}
                />
                {pipe(
                  model.form.coApplicant,
                  O.map((v) => {
                    return (
                      <>
                        <SSNInputField
                          label="SSN of Joint Applicant"
                          className="column-span-1"
                          field={v.socialSecurityNumber}
                          onChange={flow(
                            SocialSecurityNumberChanged("CoApplicant"),
                            dispatch,
                          )}
                          required={true}
                        />

                        <InputField
                          label="Date Of Birth"
                          type="date"
                          className="column-span-1"
                          field={v.dateOfBirth}
                          onChange={flow(
                            DateOfBirthChanged("CoApplicant"),
                            dispatch,
                          )}
                          required={true}
                        />
                      </>
                    );
                  }),
                  O.getOrElse(() => <></>),
                )}
              </div>
            </>
          )}
          <ConsentView {...props} />
        </Col>
      </Col>
    </>
  );
}

function ConsentView(props: Props): JSX.Element {
  const { model } = props;
  switch (model.consents.status) {
    case "InProgress":
    case "NotStarted":
      return <>"Loading"</>;
    case "Updating":
    case "Resolved":
      return pipe(
        model.consents.value,
        E.map(() => model.form.consentItems),
        E.fold(
          (error) => (
            <Col padding="sm" gap="sm" className="chart-container">
              <Label>Failed to load consents</Label>
              {showApiError(error)}
            </Col>
          ),
          (consentItems) => {
            return (
              <>
                {consentItems.map((v, i) => {
                  return (
                    <Row key={i}>
                      <Col>
                        <Checkbox
                          className="column-span-2"
                          label={`${v.title} ${v.requiredForBorrower ? "*" : ""}`}
                          checked={Boolean(v.raw)}
                          onChange={flow(
                            ConsentItemChanged(v.consentVersionId),
                            props.dispatch,
                          )}
                        />
                        <div
                          className="padleft-lg constent-description"
                          dangerouslySetInnerHTML={{ __html: v.description }}
                        ></div>
                      </Col>
                    </Row>
                  );
                })}
              </>
            );
          },
        ),
      );
  }
}
