import realEstateAgentImage from "@/assets/images/RealEstateAgent.png";
import "@/assets/styles/HomebuyerLanding/homebuyerLanding.css";
import { Button, Col, Icon, Row } from "@/components/basic";
import { SurveyProgress } from "@/components/SurveyProgress";
//import { DocumentStatus } from "@/data/applicationDocument";
import { Application } from "@/data/payload";
import { ViewResolver } from "@/utils/viewResolver";
//import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

export type Props = {
    application: O.Option<Application>;
    completeApplication: () => void;
    doesNotHaveApplication: boolean;
};

export function View(props: Props): JSX.Element {
    const applicationCompleted = useMemo(
        () =>
            pipe(
                props.application,
                O.fold(
                    () => false,
                    (application) =>
                        [
                            "Complete",
                            "Exported",
                            "Archived",
                            "Error"
                        ].includes(application.status)
                )
            ),
        [props.application]
    );


    const surveyInformation = () => {
        const hasCoApplicant = pipe(
            props.application,
            O.fold(
                () => false,
                (application) => application.survey.jointApplicants.length > 0
            )
        );

        return applicationCompleted ? (
            <Col gap="xs" alignHorizontal="stretch" className="survey-information">
                <h1>Congratulations! Your application is complete.</h1>
                <span>
                    Thanks for completing your application. Hang tight - your loan is now in process, and your loan officer will be in contact with you soon.
                </span>
                <ContextButton {...props} context="View" />
            </Col>
        ) : (
            <Col gap="md" alignHorizontal="stretch" className="survey-information">
                <h1>One more step left!</h1>
                <SurveyProgress
                    personal="Completed"
                    financial="Completed"
                    mortgage="Completed"
                    verification="Completed"
                    documentation="InProgress"
                />
                <span>
                    You're almost there! To complete your pre-approval process, we need you to upload your required documents within 24 hours.
                    {hasCoApplicant && (
                        <>
                            {" "}
                            As the primary applicant, you are responsible for uploading both your documents and your co-applicant's documents.
                        </>
                    )}
                    {" "}If you have any questions, feel free to reach out to your loan officer.
                </span>
                <Col alignHorizontal="left">
                    <ContextButton {...props} context="Complete" />
                </Col>
            </Col>
        );
    };


    const adviceItems = (
        <>
            <Col gap="xs">
                <h1>Do</h1>
                <AdviseItem
                    goodAdvice={true}
                    text="Borrow only what you can afford to repay."
                />
                <AdviseItem
                    goodAdvice={true}
                    text="Make all of your payments on time."
                />
                <AdviseItem
                    goodAdvice={true}
                    text="Avoid excessive requests or inquiries for credit."
                />
                <AdviseItem
                    goodAdvice={true}
                    text="Have an emergency account to pay for unexpected expenses."
                />
                <AdviseItem
                    goodAdvice={true}
                    text="Check your credit report annually to contest and remove any erroneous information."
                />
            </Col>
            <Col gap="xs">
                <h1>Don't</h1>
                <AdviseItem
                    goodAdvice={false}
                    text="Don't make large purchases such as cars, furniture, luxury items, etc."
                />
                <AdviseItem
                    goodAdvice={false}
                    text="Don't close any revolving credit accounts."
                />
                <AdviseItem
                    goodAdvice={false}
                    text="Don't run up the balances on your existing credit cards."
                />
                <AdviseItem
                    goodAdvice={false}
                    text="Don't acquire any non-sufficient funds or overdraft fees."
                />
                <AdviseItem
                    goodAdvice={false}
                    text="Don't change jobs, resign, or become self-employed."
                />
            </Col>
        </>
    );

    const noActiveApplicationView = (
        <Col>
            <p className="text-lg">
                {" "}
                You dont seem to have an active application. If you think you have
                applied for a loan, please contact your loan officer.{" "}
            </p>
        </Col>
    );

    const actualView = () => (
        <>
            {props.doesNotHaveApplication ? (
                noActiveApplicationView
            ) : O.isNone(props.application) ? (
                <Col grow={1} width="3/4">
                    <p className="text-lg"> Welcome to AppCactcher! </p>
                    <p> Please wait while we fetch your applicaiton.</p>
                    <Skeleton height={50} />
                </Col>
            ) : (
                surveyInformation()
            )}
        </>
    );

    const DesktopView = (
        <Col padding="sm" gap="sm" grow={1} background="grey10">
            <Row
                padding="lg"
                gap="lg"
                className="container"
                alignHorizontal="space-between"
            >
                {actualView()}

                <img src={realEstateAgentImage} alt="Real Estate Agent" />
            </Row>
            <Row padding="md" gap="md" className="container">
                {adviceItems}
            </Row>
        </Col>
    );

    const MobileView = (
        <Col padding="sm" gap="sm" grow={1} background="grey10">
            <Col
                padding="lg"
                gap="lg"
                className="container"
                alignHorizontal="space-between"
            >
                <img src={realEstateAgentImage} alt="Real Estate Agent" />
                {actualView()}
            </Col>
            <Col padding="md" gap="md" className="container">
                {adviceItems}
            </Col>
        </Col>
    );

    const ViewWrapper = ViewResolver({
        viewModes: [
            ["Default", "Tablet-Portrait", "Tablet-Landscape", "Mobile-Landscape"],

            ["Mobile-Portrait"],
        ],
        resolvedContent: [DesktopView, MobileView],
    });

    return <>{ViewWrapper}</>;
}

type AdviseItemProps = {
    text: string;
    goodAdvice: boolean;
};

function AdviseItem({ text, goodAdvice }: AdviseItemProps): JSX.Element {
    return (
        <Row gap="xs">
            <div className={goodAdvice ? "advice-good" : "advice-bad"}>
                <Icon type={goodAdvice ? "circle-check" : "circle-xmark"} />
            </div>
            <span>{text}</span>
        </Row>
    );
}

type ContextButtonProps = Props & {
    context: "Complete" | "View";
};

function ContextButton(props: ContextButtonProps): JSX.Element {
    return (
        <>
            <Button
                type="primary"
                className={ViewResolver({
                    viewModes: [["Default"], ["Mobile-Portrait", "Mobile-Landscape"]],
                    resolvedContent: ["", "w-100"],
                })}
                onClick={
                    props.context === "Complete"
                        ? O.some(props.completeApplication)
                        : O.none
                }
            >
                {props.context === "Complete"
                    ? "Complete Application"
                    : "View Application"}
            </Button>
        </>
    );
}
