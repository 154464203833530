import * as Address from "@/components/Address";
import * as ContactInformation from "@/components/ContactInformation";
import * as FundsAndCoApplicant from "@/components/FundsAndCoApplicant";
import * as IncomeInformation from "@/components/IncomeInformation";
import * as MaritalAndResidency from "@/components/MaritalAndResidency";
import * as PropertyInformation from "@/components/PropertyInformation";
import * as ReferralInformation from "@/components/ReferralInformation";
import * as RetirementAndObligations from "@/components/RetirementAndObligations";
import * as SsnAndConsent from "@/components/SsnAndConsent";
import { ExtensionRole, User } from "@/data/client";
import { ApplicantType, SurveyFlowPayload } from "@/data/surveyFlowPayload";
import { SurveyPageId, SurveyTip } from "@/data/surveyTips";
import { Started } from "@/utils/asyncOperationStatus";
import { ValidDateTime } from "@/utils/codecs";
import { Deferred, NotStarted } from "@/utils/deferred";
import { Effect, effectOfAction } from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";
import { Action, LoadTips } from "./action";
import { ReferringLoanOfficerInfo, SaveSurveyResult } from "@/data/payload";

export type WizardStep =
  | {
      type: "PropertyInformation";
      propertyInformationModel: PropertyInformation.Model;
    }
  | {
      type: "ContactInformation";
      applicantType: ApplicantType;
      contactInformationModel: ContactInformation.Model;
    }
  | {
      type: "Address";
      addressIndex: number;
      applicantType: ApplicantType;
      addressModel: Address.Model;
    }
  | {
      type: "MaritalAndResidency";
      applicantType: ApplicantType;
      maritalAndResidencyModel: MaritalAndResidency.Model;
    }
  | {
      type: "RetirementAndObligations";
      applicantType: ApplicantType;
      retirementAndObligationsModel: RetirementAndObligations.Model;
    }
  | {
      type: "IncomeInformation";
      applicantType: ApplicantType;
      incomeInformationModel: IncomeInformation.Model;
    }
  | {
      type: "FundsAndCoApplicant";
      fundsAndCoApplicantModel: FundsAndCoApplicant.Model;
    }
  | {
      type: "ReferralInformation";
      referralInformationModel: ReferralInformation.Model;
    }
  | {
      type: "SsnAndConsent";
      ssnAndConsentModel: SsnAndConsent.Model;
    };

export const PropertyInformationStep = (
  propertyInformationModel: PropertyInformation.Model,
): WizardStep => ({
  type: "PropertyInformation",
  propertyInformationModel,
});

export const ContactInformationStep =
  (applicantType: ApplicantType) =>
  (contactInformationModel: ContactInformation.Model): WizardStep => ({
    type: "ContactInformation",
    applicantType,
    contactInformationModel,
  });

export const AddressStep =
  (applicantType: ApplicantType) =>
  (addressIndex: number) =>
  (addressModel: Address.Model): WizardStep => ({
    type: "Address",
    addressIndex,
    applicantType,
    addressModel,
  });

export const MaritalAndResidencyStep =
  (applicantType: ApplicantType) =>
  (maritalAndResidencyModel: MaritalAndResidency.Model): WizardStep => ({
    type: "MaritalAndResidency",
    applicantType,
    maritalAndResidencyModel,
  });

export const RetirementAndObligationsStep =
  (applicantType: ApplicantType) =>
  (
    retirementAndObligationsModel: RetirementAndObligations.Model,
  ): WizardStep => ({
    type: "RetirementAndObligations",
    applicantType,
    retirementAndObligationsModel,
  });

export const IncomeInformationStep =
  (applicantType: ApplicantType) =>
  (incomeInformationModel: IncomeInformation.Model): WizardStep => ({
    type: "IncomeInformation",
    applicantType,
    incomeInformationModel,
  });

export const FundsAndCoApplicantStep = (
  fundsAndCoApplicantModel: FundsAndCoApplicant.Model,
): WizardStep => ({
  type: "FundsAndCoApplicant",
  fundsAndCoApplicantModel,
});

export const ReferralInformationStep = (
  referralInformationModel: ReferralInformation.Model,
): WizardStep => ({
  type: "ReferralInformation",
  referralInformationModel,
});

export const SsnAndConsentStep = (
  ssnAndConsentModel: SsnAndConsent.Model,
): WizardStep => ({
  type: "SsnAndConsent",
  ssnAndConsentModel,
});

export function surveyTipId(step: WizardStep): SurveyPageId {
  switch (step.type) {
    case "PropertyInformation":
      switch (step.propertyInformationModel.page) {
        case "ApplicationType":
          return SurveyPageId.SurveyFinancingType;
        case "PropertyType":
          return SurveyPageId.SurveyPropertyType;
      }
      break;
    case "ContactInformation":
      return SurveyPageId.SurveyNameContact;
    case "Address":
      return step.addressIndex === 0
        ? SurveyPageId.SurveyLivingArrangement
        : SurveyPageId.SurveyPreviousAddress;
    case "MaritalAndResidency":
      return SurveyPageId.SurveyMaritalResidencyStatus;
    case "FundsAndCoApplicant":
      return SurveyPageId.SurveySourceOfFunds;
    case "RetirementAndObligations":
      return SurveyPageId.SurveyBankruptcyRetirementAccounts;
    case "ReferralInformation":
      return SurveyPageId.SurveyReferralAgent;
    case "IncomeInformation":
      return SurveyPageId.SurveyIncomeSources;
    case "SsnAndConsent":
      return SurveyPageId.SurveySsnConsent;
  }
}

export type Model = {
  tips: Deferred<ApiResult<SurveyTip[]>>;
  dateTime: ValidDateTime;
  currentStep: WizardStep;
  payload: SurveyFlowPayload;
  completedSteps: WizardStep[];
  isLoanOfficerInviting: O.Option<boolean>;
  referralLoanOfficerInfo: O.Option<ReferringLoanOfficerInfo>;
  savedSurvey: Deferred<ApiResult<SaveSurveyResult>>;
};

export const init =
  (dateTime: ValidDateTime) =>
  (
    payload: SurveyFlowPayload,
    user: O.Option<User>,
  ): [Model, Effect<Action>] => [
    {
      tips: NotStarted(),
      dateTime,
      currentStep: PropertyInformationStep(PropertyInformation.init()),
      payload,
      isLoanOfficerInviting: pipe(
        user,
        O.map((v) => v.roles.has(ExtensionRole.LoanOfficer)),
      ),
      completedSteps: [],
      referralLoanOfficerInfo: O.none,
      savedSurvey: NotStarted(),
    },
    effectOfAction(flow(Started, LoadTips)()),
  ];
