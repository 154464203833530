import { updateFormField } from "@/utils/formField";
import { Model } from "./model";
import { citizenshipStatusCodec, maritalStatusCodec } from "@/data/payload";

export type Action =
  | {
      type: "MaritalStatusChanged";
      maritalStatus: string;
    }
  | {
      type: "CitizenshipStatusChanged";
      citizenshipStatus: string;
    }
  | {
      type: "MaritalStatusSetFromPrimaryApplicant";
      maritalStatus: string;
    };

export const MaritalStatusChanged = (maritalStatus: string): Action => ({
  type: "MaritalStatusChanged",
  maritalStatus,
});

export const MaritalStatusSetFromPrimaryApplicant = (
  maritalStatus: string,
): Action => ({
  type: "MaritalStatusSetFromPrimaryApplicant",
  maritalStatus,
});

export const CitizenshipStatusChanged = (
  citizenshipStatus: string,
): Action => ({
  type: "CitizenshipStatusChanged",
  citizenshipStatus,
});

export const update = (model: Model, action: Action): Model => {
  switch (action.type) {
    case "MaritalStatusChanged":
      return {
        ...model,
        maritalStatus: updateFormField(maritalStatusCodec.decode)(
          action.maritalStatus,
        ),
      };
    case "CitizenshipStatusChanged":
      return {
        ...model,
        citizenshipStatus: updateFormField(citizenshipStatusCodec.decode)(
          action.citizenshipStatus,
        ),
      };
    case "MaritalStatusSetFromPrimaryApplicant":
      return {
        ...model,
        maritalStatus: updateFormField(maritalStatusCodec.decode)(
          action.maritalStatus,
        ),
        disableMaritalStatusSelection: true,
      };
  }
};
