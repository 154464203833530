import * as t from "io-ts";


const liabilityCodec = t.type({
  source: t.string,
  accountNumber: t.string,
  bankName: t.string,
  description: t.string,
  monthlyPayment: t.number
});

const transactionCodec = t.type({
  transactionDate: t.string,
  amount: t.number,
  description: t.string
});

const bankStatementCodec = t.type({
  statementDate: t.string,
  endingBalance: t.union([t.number, t.null]),
  largeTransactions: t.array(transactionCodec),
  irsTransactions: t.array(transactionCodec),
  buyNowPayLaterTransactions: t.array(transactionCodec),
  childSupportPaymentTransactions: t.array(transactionCodec)
});

const documentClassificationCodec = t.type({
  documentId: t.number,
  documentType: t.string,
  accountNumber: t.union([t.string, t.null]),
  issuingEntity: t.string,
  levelofConfidence: t.number,
  levelOfConfidenceExplanation: t.string
});

const bankAccountCodec = t.type({
  accountNumber: t.string,
  bankName: t.string,
  accountType: t.string,
  mostRecentStatementDate: t.string,
  endingBalance: t.number,
  statements: t.array(bankStatementCodec)
});

export const coverSheetPaystubCodec = t.type({
  employeeName: t.string,
  employerName: t.string,
  //employerPhone: t.union([t.string, t.null]),
  //employerStreetAddress: t.union([t.string, t.null]),
  //employerCity: t.union([t.string, t.null]),
  //employerState: t.union([t.string, t.null]),
  //employerPostalCode: t.union([t.string, t.null]),
  //employerStateAbbreviation: t.union([t.string, t.null]),
  payPeriodStartDate: t.string,
  payPeriodEndDate: t.string,
  payDate: t.string,
  grossIncome: t.number,
  yearToDateGrossIncome: t.number,
  regularHoursWorked: t.number,
  regularHourlyPayRate: t.union([t.number, t.null]),
  regularIncome: t.number,
  yearToDateRegularIncome: t.number,
  overtimeHoursWorked: t.number,
  overtimeHourlyPayRate: t.union([t.number, t.null]),
  overtimeIncome: t.number,
  yearToDateOvertimeIncome: t.number,
  commissionIncome: t.number,
  yearToDateCommissionIncome: t.number,
  bonusIncome: t.number,
  yearToDateBonusIncome: t.number,
  tipIncome: t.number,
  yearToDateTipIncome: t.number,
  otherIncome: t.number,
  yearToDateOtherIncome: t.number,
  earningItems: t.array(t.type({
    description: t.string,
    category: t.string,
    amount: t.number,
    hours: t.number,
    yearToDateAmount: t.number,
    yearToDateHours: t.number
  })),
  classification: t.type({
    documentId: t.number,
    documentType: t.string,
    accountNumber: t.union([t.string, t.null]),
    issuingEntity: t.string
  })
});

const incomeDetailCodec = t.type({
  income: t.number,
  method: t.string,
  explanation: t.string
});

export const coverSheetEmployerCodec = t.type({
  employerId: t.number,
  name: t.string,
  startDate: t.string,
  mostRecentRegularPaystubPayDate: t.string,
  regularPayPeriodType: t.string,
  monthlyTotalIncome: t.number,
  monthlyRegularIncome: incomeDetailCodec,
  monthlyOvertimeIncome: incomeDetailCodec,
  monthlyCommissionIncome: incomeDetailCodec,
  monthlyBonusIncome: incomeDetailCodec,
  monthlyTipIncome: incomeDetailCodec,
  monthlyOtherIncome: incomeDetailCodec,
  explanation: t.string,
  annualIncomeItems: t.array(t.type({
    year: t.number,
    regular: t.number,
    overtime: t.number,
    commission: t.number,
    bonus: t.number,
    tips: t.number,
    other: t.number
  })),
  relatedPayStubs: t.array(coverSheetPaystubCodec)
});

const documentCodec = t.type({
  applicantName: t.string,
  title: t.string,
  subTitle: t.union([t.string, t.null]),
  documentUploaded: t.boolean
});

const annualDataCodec = t.type({
  year: t.number,
  netProfitOrLoss: t.number,
  depletion: t.number,
  depreciation: t.number,
  meals: t.number,
  otherExpenses: t.number,
  businessUseOfHome: t.number,
  businessMileage: t.number,
  totalIncome: t.number,
  monthlyIncome: t.number
});

const sCorporationAnnualDataCodec = t.type({
  taxYear: t.number,
    k1_OrdinaryIncome: t.number,
    w2Income: t.number,
  k1_NetRentalIncome: t.number,
  k1_OtherNetIncome: t.number,
  otherIncome: t.number,
  percentageOfOwnership: t.number,
  depletion: t.number,
  depreciation: t.number,
  nonDeductibleTravelAndEntertainment: t.number,
  amortization: t.number,
  mortgagesNotesBondsPayable: t.number,
  totalIncome: t.number,
  monthlyIncome: t.number
});

export const sCorporationCodec = t.type({
  entityName: t.string,
  qualifyingIncome: t.number,
  annualData: t.array(sCorporationAnnualDataCodec)
});

export const scheduleCEntityCodec = t.type({
  entityName: t.string,
  qualifyingIncome: t.number,
  annualData: t.array(annualDataCodec)
});

export const coverSheetDataCodec = t.type({
  application: t.type({
    documents: t.array(documentCodec),
    liabilities: t.array(liabilityCodec),
    applicants: t.array(t.type({
      applicantId: t.number,
      isPrimaryApplicant: t.boolean,
      fullname: t.string,
      w2Employers: t.array(coverSheetEmployerCodec),
      bankAccounts: t.array(bankAccountCodec),
      scheduleCEntities: t.array(scheduleCEntityCodec),
      sCorporations: t.array(sCorporationCodec)
    }))
  })
});

export type CoverSheetPaystub = t.TypeOf<typeof coverSheetPaystubCodec>;
export type CoverSheetEmployer = t.TypeOf<typeof coverSheetEmployerCodec>;
export type ScheduleCEntity = t.TypeOf<typeof scheduleCEntityCodec>;
export type SCorporation = t.TypeOf<typeof sCorporationCodec>;
export type CoverSheetData = t.TypeOf<typeof coverSheetDataCodec>;

// Legacy types for component compatibility
export type BankTransaction = t.TypeOf<typeof transactionCodec>;
export type BankStatement = t.TypeOf<typeof bankStatementCodec>;
export type BankAccount = t.TypeOf<typeof bankAccountCodec>;
export type DocumentClassification = t.TypeOf<typeof documentClassificationCodec>;

export type Liability = t.TypeOf<typeof liabilityCodec>;

export type Document = {
  applicantName: string;
  title: string;
  subTitle: string | null;
  documentUploaded: boolean;
};

export type CoverSheetApplicant = {
  fullname: string;
  w2Employers: CoverSheetEmployer[];
  bankAccounts: BankAccount[];
  scheduleCEntities: ScheduleCEntity[];
  sCorporations: SCorporation[];
};

export type TransformedCoverSheetData = {
  documents: Document[];
  liabilities: Liability[];
  primaryApplicant: CoverSheetApplicant;
  jointApplicants: CoverSheetApplicant[];
};

export const transformCoverSheetData = (data: CoverSheetData): TransformedCoverSheetData => {
  const applicants = data.application.applicants;
  const primary = applicants.find(a => a.isPrimaryApplicant);
  const joint = applicants.filter(a => !a.isPrimaryApplicant);
  
  return {
    documents: data.application.documents,
    liabilities: data.application.liabilities,
    primaryApplicant: {
      fullname: primary?.fullname ?? '',
      w2Employers: primary?.w2Employers ?? [],
      bankAccounts: primary?.bankAccounts ?? [],
      scheduleCEntities: primary?.scheduleCEntities ?? [],
      sCorporations: primary?.sCorporations ?? []
    },
    jointApplicants: joint.map(j => ({
      fullname: j.fullname,
      w2Employers: j.w2Employers,
      bankAccounts: j.bankAccounts,
      scheduleCEntities: j.scheduleCEntities,
      sCorporations: j.sCorporations
    }))
  };
};
