import { BounceLoader } from "react-spinners";
import { Button, Col, Label } from "../basic";
import { Model } from "./model";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import { FC } from 'react';
import { LiabilitiesSection } from "./components/LiabilitiesSection";
import { BorrowerSection } from "./components/BorrowerSection";
import { IncomeSection } from "./components/IncomeSection";
import { BankAccountCard } from "./components/BankAccountCard";
import { DocumentsSection } from "./components/DocumentsSection";
import { TransformedCoverSheetData } from "@/data/coversheet";

type Props = {
  model: Model;
  onClose: () => void;
};

function LoadingView() {
  return (
    <Col alignHorizontal="center" children={
        <Col alignVertical="center" alignHorizontal="center" gap="xs" children={[
          <BounceLoader key="loader" color="#2A2250" />,
          <Label key="label" className="text-md text-mbold action-70" children="Fetching application summary..." />
        ]} />
    } />
  );
}

function ErrorView() {
  return (
    <Col alignHorizontal="center" children={
        <Col alignVertical="center" alignHorizontal="center" gap="xs" children={
          <Label className="text-lg text-mbold action-70" children="Failed to load the application summary!" />
        } />
    } />
  );
}

export const View: FC<Props> = (props) => {
    const { model } = props;

  
  switch (model.summaryContent.status) {
    case "NotStarted":
    case "InProgress":
    case "Updating":
      return <LoadingView />;
    
    case "Resolved": {
      const { value } = model.summaryContent;
      if (E.isRight(value)) {
        const data = value.right as TransformedCoverSheetData;
        return (
            <Col gap="md" className="summary-modal-container" children={[
              <BorrowerSection
                key="borrower"
                primary={data.primaryApplicant}
                joint={data.jointApplicants}
              />,
              <div key="income-title" className="text-lg">Income</div>,
              <IncomeSection 
                key="income"
                primary={data.primaryApplicant}
                joint={data.jointApplicants}
              />,
              <div key="assets-title" className="text-lg">Assets</div>,
              <Col key="bank-accounts" gap="sm" alignVertical="top" children={[
                <h3 key="bank-title">Bank Accounts</h3>,
                ...data.primaryApplicant.bankAccounts.map((account, index) => (
                  <BankAccountCard key={`primary-${index}`} account={account} />
                )),
                ...data.jointApplicants.flatMap((applicant, applicantIndex) => 
                  applicant.bankAccounts.map((account, accountIndex) => (
                    <BankAccountCard 
                      key={`joint-${applicantIndex}-${accountIndex}`} 
                      account={account}
                    />
                  ))
                )
              ]} />,
              <div key="liabilities-title" className="text-lg">Liabilities</div>,
              <LiabilitiesSection key="liabilities" liabilities={data.liabilities} />,
              <div key="documents-title" className="text-lg">Documents</div>,
              <DocumentsSection key="documents" documents={data.documents} />,
              <Button key="close" type="primary" onClick={O.some(() => props.onClose())}>
                Close
              </Button>
            ]} />
        );
      }
      return <ErrorView />;
    }
  }
}
