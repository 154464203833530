import { TogglePanel, Col, Table } from "../../basic";
import { TableColumn } from "../../basic/Table/table";
import { formatCurrency } from "@/utils/formatters";
import * as O from "fp-ts/Option";
import { ViewResolver } from "@/utils/viewResolver";
import { FC } from 'react';


type Liability = {
  bankName: string;
  accountNumber: string;
  description: string;
  monthlyPayment: number;
};

type Props = {
  liabilities: Liability[];
};

function getDesktopColumns(): TableColumn<Liability>[] {
    return [
        {
            columnKey: "bankName",
            header: <div className="text-left">Bank Name</div>,
            view: (item: Liability) => <div className="text-left">{item.bankName}</div>
        },
        {
            columnKey: "accountNumber",
            header: <div className="text-left">Account Number</div>,
            view: (item) => <div className="text-left">{item.accountNumber}</div>
        },
        {
            columnKey: "description",
            header: <div className="text-left">Description</div>,
            view: (item) => <div className="text-left">{item.description}</div>
        },
        {
            columnKey: "monthlyPayment",
            header: <div className="text-right">Monthly Payment</div>,
            view: (item) => <div className="text-right">{formatCurrency(item.monthlyPayment)}</div>
        }
    ];
}

function getMobileColumns(): TableColumn<Liability>[] {
    return [
        {
            columnKey: "description",
            header: <div className="text-left">Description</div>,
            view: (item) => <div className="text-left">{item.description}</div>
        },
        {
            columnKey: "monthlyPayment",
            header: <div className="text-right">Monthly Payment</div>,
            view: (item) => <div className="text-right">{formatCurrency(item.monthlyPayment)}</div>
        }
    ];
}

export const LiabilitiesSection: FC<Props> = ({ liabilities }) => {
  const totalMonthlyPayment = liabilities.reduce((sum, liability) => sum + liability.monthlyPayment, 0);

  return (
    <TogglePanel 
      title="Liabilities from Bank Accounts"
      subTitle={`Monthly Payment: ${formatCurrency(totalMonthlyPayment)}`}
      children={
        <Col gap="md" children={
          <Table
            className="earning-items"
            data={liabilities}
            columns={ViewResolver({
                viewModes: [
                    ["Default"],
                    ["Mobile-Portrait", "Mobile-Landscape"]
                ],
                resolvedContent: [
                    getDesktopColumns(),
                    getMobileColumns()
                ]
            })}
            itemKey={(item) => `${item.accountNumber}-${item.description}`}
            pageInfo={O.none}
            isLoading={false}
          />
        } />
      } 
    />
  );
}
