import { Col, Icon, Row, TogglePanel } from "@/components/basic";
import { ContentProps } from "../view";
import * as O from "fp-ts/lib/Option";
import {
  AddressPayload,
  ApplicantPayload,
  ApplicationStatusType,
  showCitizenshipStatus,
} from "@/data/payload";
import { ApplicationViewMode } from "../model";
import { Action, ApplicantId, MoveToEditMode, PersonalInformationAction } from "../action";
import { formatConfirmSsn } from "@/utils/formatters";
import { DateTime } from "luxon";
import { ViewResolver } from "@/utils/viewResolver";
import * as PersonalInformation from "@/components/PersonalInformation";
import { flow, pipe } from "fp-ts/lib/function";

export type PersonalInfoSection = {
  applicantId: ApplicantId;
  type: "Personal Information";
};

type PersonalInfoInputProps = ContentProps & {
  personalInfoSection: PersonalInfoSection;
};

type PersonalInfoReadOnlyViewProps = {
  applicant: ApplicantPayload;
  applicantType: ApplicantId;
  onEditSelected: O.Option<() => void>;
};

type PersonalInfoEditViewProps = {
  model: PersonalInformation.Model;
  dispatch: (action: Action) => void;
  applicantType: ApplicantId;
};

export function PersonalInfoSectionView(
  props: PersonalInfoInputProps
): JSX.Element {
  const { model, dispatch, personalInfoSection } = props;
  const status = model.application.status;

  const canSaveApplication = (aStatus: ApplicationStatusType) =>
      ![
        ApplicationStatusType.Complete,
        ApplicationStatusType.Exported,
        ApplicationStatusType.Archived,
      ].includes(aStatus);

  const canEdit = pipe(
    status,
    O.fromPredicate((v) => canSaveApplication(v)),
    O.map(() => flow(MoveToEditMode, dispatch)),
  );

  if (
    personalInfoSection.applicantId.type === "Primary" &&
    model.mode === ApplicationViewMode.Readonly
  ) {
    const readOnlyViewProps: PersonalInfoReadOnlyViewProps = {
        applicant: model.application.survey.primaryApplicant,
        applicantType: {type: "Primary"},
        onEditSelected: canEdit
    };
    return <PersonalInfoSectionReadOnlyView {...readOnlyViewProps} />;
  }else if(personalInfoSection.applicantId.type === 'Primary' && model.mode === ApplicationViewMode.Edit) {
    const editViewProps: PersonalInfoEditViewProps = {
        model: model.primaryApplicant.personalInformation,
        dispatch: dispatch,
        applicantType: {type: "Primary"}
    };
    return <PersonalInfoSectionEditView {...editViewProps}/>;
  }else if(personalInfoSection.applicantId.type === 'CoApplicant' && model.mode === ApplicationViewMode.Readonly) {
    const coApplicantIndex = personalInfoSection.applicantId.index;
    const applicantIndex = model.application.survey.jointApplicants.findIndex((app) => app.applicant.applicantId === coApplicantIndex);
    if(applicantIndex === -1) {
        return <></>
    }
    const applicant = model.application.survey.jointApplicants[applicantIndex];
    const readOnlyViewProps: PersonalInfoReadOnlyViewProps = {
        ...applicant,
        applicantType: {type: 'CoApplicant', index: coApplicantIndex},
        onEditSelected: canEdit
    };
    return <PersonalInfoSectionReadOnlyView {...readOnlyViewProps} />
  }else if(personalInfoSection.applicantId.type === 'CoApplicant' && model.mode === ApplicationViewMode.Edit) {
    const coApplicantIndex = personalInfoSection.applicantId.index;
    const applicantIndex = model.mortgageInformation.coApplicants.findIndex((app) => app.personalInformation.applicantId === coApplicantIndex);
    if(applicantIndex === -1) {
        return <></>
    }
    const personalInfoModel = model.mortgageInformation.coApplicants[applicantIndex].personalInformation;
    const editViewProps: PersonalInfoEditViewProps = {
        model: personalInfoModel,
        dispatch: dispatch,
        applicantType: {type: 'CoApplicant', index: coApplicantIndex}
    };
    return <PersonalInfoSectionEditView {...editViewProps}/>;
  }else {
    return <></>
  }
}

export function PersonalInfoSectionReadOnlyView(
  props: PersonalInfoReadOnlyViewProps
): JSX.Element {
  const { applicant, applicantType } = props;

  const togglePanelClasses = ViewResolver({
    viewModes: [["Default"], ["Tablet-Portrait", "Tablet-Landscape"]],
    resolvedContent: ["grid-3", "grid-2"],
  });

  const dobHeaderClass = ViewResolver({
    viewModes: [["Default"], ["Tablet-Portrait", "Tablet-Landscape"]],
    resolvedContent: ["grid-item-1", "column-span-2"],
  });

  return (
    <TogglePanel title={applicant.fullName} subTitle={applicantType.type} defaultOpen={true}>
      <div className={togglePanelClasses}>
        <Row
          gap="xs"
          className="text-smd grid-item-3 padding-xs color-act-70 text-mbold"
        >
          <Icon type="user" />
          <span>Personal Information</span>
          {O.isSome(props.onEditSelected) &&<i className="fa-regular fa-pen-to-square pointer" onClick={props.onEditSelected.value}></i>}
        </Row>

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Full Name</b>
          <span>{applicant.fullName ?? " - "}</span>
        </Col>

        <Col gap="xs" padding="sm" className={dobHeaderClass}>
          <b>Date of Birth</b>
          <span>
            {applicant.dateOfBirth
              ? applicant.dateOfBirth.toLocaleString(DateTime.DATE_SHORT)
              : " - "}
          </span>
        </Col>

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Social Security Number</b>
          <span>{applicant.ssn ? formatConfirmSsn(applicant.ssn) : "-"}</span>
        </Col>

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Email</b>
          <span className="wb-bw">{applicant.email ?? " - "}</span>
        </Col>

        <Col gap="xs" padding="sm" className={dobHeaderClass}>
          <b>Cell Number</b>
          <span>{applicant.phone ?? " - "}</span>
        </Col>

        <hr className="grid-item-3" />
        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Current Address</b>
          <span
            dangerouslySetInnerHTML={{
              __html:
                applicant.addresses.length > 0
                  ? formatAddress(applicant.addresses[0], true)
                  : " - ",
            }}
          ></span>
        </Col>

        <Col gap="xs" padding="sm" className={dobHeaderClass}>
          <b>Previous Addresses</b>
          {applicant.addresses.length > 1 ? (
            applicant.addresses.slice(1).map((address, index) => (
              <div
                key={index}
                dangerouslySetInnerHTML={{
                  __html: formatAddress(address, false),
                }}
              ></div>
            ))
          ) : (
            <span> - </span>
          )}
        </Col>
        <hr className="grid-item-3" />

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Marital Status</b>
          <span>{applicant.maritalStatus ?? " - "}</span>
        </Col>

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Previously Divorced</b>
          <span>{applicant.divorced ? "Yes" : "No"}</span>
        </Col>

        <hr className="grid-item-3" />

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Citizenship</b>
          <span>
            {showCitizenshipStatus(applicant.citizenshipStatus) ?? " - "}
          </span>
        </Col>
      </div>
    </TogglePanel>
  );
}

export function PersonalInfoSectionEditView(
  props: PersonalInfoEditViewProps
): JSX.Element {
  const { model, dispatch, applicantType } = props;
  return (
    <TogglePanel title={model.fullName.raw} subTitle={applicantType.type}>
      <Row
        gap="xs"
        className="text-smd grid-item-3 padding-vertical-xs color-act-70 text-mbold"
      >
        <Icon type="user" />
        <span id="primary-applicant-personal-info">Personal Information</span>
      </Row>
      <Col gap="xxs">
        <Col>
          <PersonalInformation.View
            model={model}
            dispatch={flow(
              PersonalInformationAction(applicantType),
              dispatch
            )}
          />
        </Col>
      </Col>
    </TogglePanel>
  );
}

function formatAddress(address: AddressPayload, isCurrent: boolean): string {
  return isCurrent
    ? `${address.street},<br />${address.city},<br />${address.state}`
    : `${address.street},<br />${address.city},<br />${address.state}`;
}
