import {
  ConsentItem,
  consentMethodCodec,
  ConsentMethods,
  ConsentType,
  someConsentMethodCodec,
} from "@/data/consents";
import { ApplicantType } from "@/data/surveyFlowPayload";
import { Api } from "@/utils/api";
import { AsyncOperationStatus, Finished } from "@/utils/asyncOperationStatus";
import {
  optionalSocialSecurityNumberCodecWithMessage,
  socialSecurityNumberCodecWithMessage,
  validDateTimeCodecSWithMessage,
} from "@/utils/codecs";
import { NullFromEmptyString } from "@/utils/codecs/nullFromEmptyString";
import { consentFormFields } from "@/utils/consent-form-types";
import { Resolved, updatingDeferred } from "@/utils/deferred";
import {
  initFormField,
  updateCheckboxFormField,
  updateFormField,
} from "@/utils/formField";
import { Effect, effectOfAsync, noEffect } from "@/utils/reducerWithEffect";
import { ApiResult } from "@/utils/request";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import { flow, identity, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Lens } from "monocle-ts";
import { Model } from "./model";
import { ValidErrorMessage } from "../basic";

export type Action =
  | {
      type: "SocialSecurityNumberChanged";
      applicantType: ApplicantType;
      socialSecurityNumber: string;
    }
  | {
      type: "DateOfBirthChanged";
      applicantType: ApplicantType;
      dateOfBirth: string;
    }
  | {
      type: "ConsentMethodChanged";
      method: string;
    }
  | {
      type: "ConsentItemChanged";
      eConsent: boolean;
      itemIndex: number;
    }
  | {
      type: "LoadConsentMeta";
      operation: AsyncOperationStatus<ApiResult<ConsentItem[]>>;
    };

export const SocialSecurityNumberChanged =
  (applicantType: ApplicantType) =>
  (socialSecurityNumber: string): Action => ({
    type: "SocialSecurityNumberChanged",
    applicantType,
    socialSecurityNumber,
  });

export const DateOfBirthChanged =
  (applicantType: ApplicantType) =>
  (dateOfBirth: string): Action => ({
    type: "DateOfBirthChanged",
    applicantType,
    dateOfBirth,
  });
export const LoadConsentMeta = (
  operation: AsyncOperationStatus<ApiResult<ConsentItem[]>>,
): Action => ({
  type: "LoadConsentMeta",
  operation,
});

export const ConsentMethodChanged = (method: string): Action => ({
  type: "ConsentMethodChanged",
  method,
});

export const ConsentItemChanged =
  (itemIndex: number) =>
  (eConsent: boolean): Action => ({
    type: "ConsentItemChanged",
    eConsent,
    itemIndex,
  });

export const adjustDecoderForConsentMethod = (model: Model): Model => {
  const isAnyConsentChecked =
    model.form && model.form.consentItems.some((v) => v.raw);
  const isConstentsMandatory = pipe(
    model.isLoanOfficerInviting,
    O.fold(() => false, identity),
  );
  const codec = isConstentsMandatory
    ? isAnyConsentChecked
      ? someConsentMethodCodec
      : consentMethodCodec
    : consentMethodCodec;

  const updatedValue = isConstentsMandatory
    ? isAnyConsentChecked
      ? model.consentMethod.raw
      : ConsentMethods.None
    : ConsentMethods.None;
  return {
    ...model,
    consentMethod: initFormField(codec.decode)(updatedValue),
  };
};

export const update =
  (api: Api) =>
  (model: Model, action: Action): [Model, Effect<Action>] => {
    switch (action.type) {
      case "SocialSecurityNumberChanged":
        switch (action.applicantType) {
          case "PrimaryApplicant":
            return [
              {
                ...model,
                primaryApplicant: {
                  ...model.primaryApplicant,
                  socialSecurityNumber: updateFormField(
                    socialSecurityNumberCodecWithMessage(ValidErrorMessage('social security number')).decode,
                  )(action.socialSecurityNumber),
                },
              },
              noEffect,
            ];

          case "CoApplicant":
            {
              const decoder = pipe(
                model.isLoanOfficerInviting,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                O.map((v): any => {
                  return v
                    ? optionalSocialSecurityNumberCodecWithMessage(ValidErrorMessage('social security number')).decode
                    : socialSecurityNumberCodecWithMessage(ValidErrorMessage('social security number')).decode;
                }),
                O.getOrElse(() => socialSecurityNumberCodecWithMessage(ValidErrorMessage('social security number')).decode),
              );
              return [
                {
                  ...model,
                  coApplicant: pipe(
                    model.coApplicant,
                    O.map((coApplicant) => ({
                      ...coApplicant,
                      socialSecurityNumber: updateFormField(decoder)(
                        action.socialSecurityNumber,
                      ),
                    })),
                  ),
                },
                noEffect,
              ];
            }
            break;
        }

        break;

      case "DateOfBirthChanged":
        switch (action.applicantType) {
          case "PrimaryApplicant":
            return [
              {
                ...model,
                primaryApplicant: {
                  ...model.primaryApplicant,
                  dateOfBirth: updateFormField(validDateTimeCodecSWithMessage(ValidErrorMessage('date')).decode)(
                    action.dateOfBirth,
                  ),
                },
              },
              noEffect,
            ];
          case "CoApplicant":
            return [
              {
                ...model,
                coApplicant: pipe(
                  model.coApplicant,
                  O.map((coApplicant) => ({
                    ...coApplicant,
                    dateOfBirth: pipe(
                      model.isLoanOfficerInviting,
                      O.fold(() => false, identity),
                      (v) =>
                        v && NullFromEmptyString.is(action.dateOfBirth)
                          ? updateFormField(NullFromEmptyString.decode)(
                              action.dateOfBirth,
                            )
                          : updateFormField(validDateTimeCodecSWithMessage(ValidErrorMessage('date')).decode)(
                              action.dateOfBirth,
                            ),
                    ),
                  })),
                ),
              },
              noEffect,
            ];
        }

        break;
      case "ConsentMethodChanged":
        return [
          adjustDecoderForConsentMethod({
            ...model,
            consentMethod: updateFormField(someConsentMethodCodec.decode)(
              action.method,
            ),
          }),
          noEffect,
        ];
      case "LoadConsentMeta":
        {
          switch (action.operation.status) {
            case "Started":
              return [
                {
                  ...model,
                  consents: updatingDeferred(model.consents),
                },
                effectOfAsync(api.getConsents, flow(Finished, LoadConsentMeta)),
              ];

            case "Finished":
              {
                return [consentLoadedAction(model, action), noEffect];
              }
              break;
          }
        }
        break;

      case "ConsentItemChanged":
        {
          let providedConsentForElectronicBusiness = false;
          const lens = Lens.fromPath<Model>()(["form", "consentItems"]);
          const newModel = lens.modify((model) =>
            pipe(
              model,
              A.mapWithIndex((_, checkboxFormField) => {
                if (checkboxFormField.consentVersionId == action.itemIndex) {
                  if(checkboxFormField.consentType === ConsentType.ElectronicBusiness) {
                    providedConsentForElectronicBusiness = true;
                  }
                  return {
                    ...updateCheckboxFormField(
                      checkboxFormField,
                      action.eConsent,
                    ),
                    consentVersionId: checkboxFormField.consentVersionId,
                    requiredForBorrower: checkboxFormField.requiredForBorrower,
                    consentType: checkboxFormField.consentType
                  };
                }
                return checkboxFormField;
              }),
            ),
          )(model);
          newModel.form.providedConsentForElectronicBusiness = providedConsentForElectronicBusiness ? O.some(true) : O.some(false);
          return [adjustDecoderForConsentMethod(newModel), noEffect];
        }
        break;
    }
  };

function consentLoadedAction(
  model: Model,
  action: {
    type: "LoadConsentMeta";
    operation: AsyncOperationStatus<ApiResult<ConsentItem[]>>;
  },
): Model {
  if (action.operation.status == "Started") {
    return model;
  }
  const consentMeta = action.operation.result;
  return {
    ...model,
    consents: Resolved(action.operation.result),
    form: {
      consentItems: pipe(
        consentMeta,
        E.map((consent) => {
          const formFields = consentFormFields(consent);
          return formFields;
        }),
        E.fold(() => [], identity),
      ),
      providedConsentForElectronicBusiness: O.some(false)
    },
  };
}
