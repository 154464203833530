import {
  ApplicantPayload,
  SavedSurveyPayload,
  showApplicantType,
  showJointApplicantType,
  showSourceOfFunds,
} from "@/data/payload";
import { ApplicantId } from "../action";
import { ContentProps } from "../view";
import { ViewResolver } from "@/utils/viewResolver";
import { TogglePanel, Row, Icon, Col } from "@/components/basic";
import { pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";

export type MortgageInfoSection = {
  applicantId: ApplicantId;
  type: "Mortgage Information";
};

type MortgageInfoInputProps = ContentProps & {
  mortgageInfoSection: MortgageInfoSection;
};

type MortgageInfoReadOnlyViewProps = {
  applicant: ApplicantPayload;
  applicantType: ApplicantId;
  payload: SavedSurveyPayload;
};

export function MortgageInformationSectionView(
  props: MortgageInfoInputProps
): JSX.Element {
  const { model, mortgageInfoSection } = props;

  if (mortgageInfoSection.applicantId.type === "Primary") {
    const readOnlyViewProps: MortgageInfoReadOnlyViewProps = {
      applicant: model.application.survey.primaryApplicant,
      applicantType: { type: "Primary" },
      payload: model.application.survey,
    };
    return <MortgageInfoSectionReadOnlyView {...readOnlyViewProps} />;
  } else if (mortgageInfoSection.applicantId.type === "CoApplicant") {
    const coApplicantIndex = mortgageInfoSection.applicantId.index;
    const applicantIndex = model.application.survey.jointApplicants.findIndex(
      (app) => app.applicant.applicantId === coApplicantIndex
    );
    if (applicantIndex === -1) {
      return <></>;
    }
    const applicant = model.application.survey.jointApplicants[applicantIndex];
    const readOnlyViewProps: MortgageInfoReadOnlyViewProps = {
      ...applicant,
      applicantType: { type: "CoApplicant", index: coApplicantIndex },
      payload: model.application.survey,
    };
    return <MortgageInfoSectionReadOnlyView {...readOnlyViewProps} />;
  } else {
    return <></>;
  }
}

export function MortgageInfoSectionReadOnlyView(
  props: MortgageInfoReadOnlyViewProps
): JSX.Element {
  const { applicant, applicantType, payload } = props;

  const togglePanelClasses = ViewResolver({
    viewModes: [["Default"], ["Tablet-Portrait", "Tablet-Landscape"]],
    resolvedContent: ["grid-3", "grid-2"],
  });

  return (
    <TogglePanel title={applicant.fullName} subTitle={applicantType.type} defaultOpen={true}>
      <div className={togglePanelClasses}>
        <Row
          gap="xs"
          className="text-smd grid-item-3 padding-xs color-act-70 text-mbold"
        >
          <Icon type="house" />
          <span>Mortgage Information</span>
        </Row>

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Source of Funds</b>
          {payload.sourceOfFunds.map((source) => (
            <span key={source}>{showSourceOfFunds(source) ?? " - "}</span>
          ))}
        </Col>

        <hr className="grid-item-3" />

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Application</b>
          <span>
            {showApplicantType(A.isNonEmpty(payload.jointApplicants) ?? "-")}
          </span>
        </Col>

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Relationship</b>
          {A.isNonEmpty(payload.jointApplicants)
            ? payload.jointApplicants.map((relation) => (
                <span className="wb-bw" key={relation.applicantType}>
                  {showJointApplicantType(relation.applicantType) ?? " - "}
                </span>
              ))
            : "-"}
        </Col>

        <Col
          gap="xs"
          padding="sm"
          className={ViewResolver({
            viewModes: [["Default"], ["Tablet-Portrait", "Tablet-Landscape"]],
            resolvedContent: ["grid-item-1", "column-span-2"],
          })}
        >
          <b>Full Name</b>
          {A.isNonEmpty(payload.jointApplicants)
            ? payload.jointApplicants.map((name) => (
                <span key={name.applicant.fullName}>
                  {name.applicant.fullName ?? " - "}
                </span>
              ))
            : "-"}
        </Col>

        <hr className="grid-item-3" />

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Real Estate Agent</b>
          <span>{payload.workingWithRealEstateAgent ? "Yes" : "No"}</span>
        </Col>

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Primary Contact</b>
          <span>
            {pipe(
              payload.realEstateAgentName,
              O.fold(
                () => " - ",
                (name) => name
              )
            )}
          </span>
        </Col>

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Contact number</b>
          <span>
            {pipe(
              payload.realEstateAgentPhone,
              O.fold(
                () => " - ",
                (phone) => phone
              )
            )}
          </span>
        </Col>
      </div>
    </TogglePanel>
  );
}
