import { ReferralSource, referralSourceCodec } from "@/data/payload";
import { ReferralInformationPayload } from "@/data/surveyFlowPayload";
import { PhoneNumber, phoneNumberCodecWithMessage } from "@/utils/codecs/phoneNumber";
import { FormField, initFormField } from "@/utils/formField";
import { NonEmptyString } from "io-ts-types";
import { Option } from "fp-ts/Option";
import * as O from "fp-ts/Option";
import * as t from "io-ts";
import { pipe } from "fp-ts/function";
import { nonEmptyStringCodec } from "@/utils/codecs";
import { sequenceS } from "fp-ts/Apply";
import * as E from "fp-ts/Either";
import { ValidErrorMessage } from "../basic";

export type Model = {
  referralSource: FormField<ReferralSource>;
  isWorkingWithAgent: boolean;
  realEstateAgentName: FormField<NonEmptyString>;
  realEstateAgentPhoneNumber: FormField<PhoneNumber>;
};

export const init = (payload: Option<ReferralInformationPayload>): Model => ({
  referralSource: pipe(
    payload,
    O.fold(
      () => "",
      ({ referralSource }) => referralSource,
    ),
    initFormField(referralSourceCodec.decode),
  ),
  isWorkingWithAgent: pipe(
    payload,
    O.fold(
      () => false,
      ({ workingWithRealEstateAgent }) => workingWithRealEstateAgent,
    ),
  ),
  realEstateAgentName: pipe(
    payload,
    O.chain(({ realEstateAgentName }) => realEstateAgentName),
    O.getOrElseW(() => ""),
    initFormField(nonEmptyStringCodec(false, ValidErrorMessage('name')).decode),
  ),
  realEstateAgentPhoneNumber: pipe(
    payload,
    O.chain(({ realEstateAgentPhoneNumber }) => realEstateAgentPhoneNumber),
    O.getOrElseW(() => ""),
    initFormField(phoneNumberCodecWithMessage(ValidErrorMessage('phone number')).decode),
  ),
});

export const result = (
  model: Model,
): t.Validation<ReferralInformationPayload> =>
  sequenceS(E.Apply)({
    referralSource: model.referralSource.val,
    workingWithRealEstateAgent: E.right(model.isWorkingWithAgent),
    realEstateAgentName: model.isWorkingWithAgent && model.realEstateAgentName.dirty
      ? pipe(model.realEstateAgentName.val, E.map(O.some))
      : E.right(O.none),
    realEstateAgentPhoneNumber: model.isWorkingWithAgent && model.realEstateAgentPhoneNumber.dirty
      ? pipe(model.realEstateAgentPhoneNumber.val, E.map(O.some))
      : E.right(O.none),
  });
