import { applicationDocumentCodec } from "@/data/applicationDocument";
import { stateCodec } from "@/data/states";
import {
  confirmSocialSecurityNumberCodec,
  emailStringCodec,
  nonEmptyStringCodec,
  numberCodecS,
  optionalSocialSecurityNumberCodec,
  optionFromString,
  positiveIntCodec,
  socialSecurityNumberCodec,
  stringEnumCodec,
  toISODate,
  validDateTimeCodecS,
  zipCodeCodec
} from "@/utils/codecs";
import { NullFromEmptyString } from "@/utils/codecs/nullFromEmptyString";
import { phoneNumberCodec } from "@/utils/codecs/phoneNumber";
import { LocalStoragetKeys } from "@/utils/localstorage";
import * as A from "fp-ts/Array";
import * as Eq from "fp-ts/Eq";
import * as M from "fp-ts/Map";
import * as NEA from "fp-ts/NonEmptyArray";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import * as O from "fp-ts/Option";
import * as Ord from "fp-ts/Ord";
import * as SG from "fp-ts/Semigroup";
import { pipe } from "fp-ts/function";
import { Eq as EqNumber, Ord as OrdNumber } from "fp-ts/number";
import { Eq as EqString, Ord as OrdString } from "fp-ts/string";
import * as t from "io-ts";
import * as tt from "io-ts-types";
import {
  branchIdCodec,
  clientIdCodec,
  extensionRoleCodec,
  OrdExtensionRole,
  teamIdCodec,
  userIdCodec,
} from "./client";
import { surveyConsentCodec } from "./consents";

const isoDateCodec = tt.withEncode(validDateTimeCodecS, toISODate);

export enum PropertyType {
  Primary = "Primary",
  SecondHome = "SecondHome",
  Investment = "Investment",
}

export function showPropertyType(propertyType: PropertyType): string {
  switch (propertyType) {
    case PropertyType.Primary:
      return "Primary residence";
    case PropertyType.SecondHome:
      return "Second home";
    case PropertyType.Investment:
      return "Investment property";
  }
}

export const propertyTypeCodec = stringEnumCodec<typeof PropertyType>(
  PropertyType,
  "PropertyType",
);

export enum ApplicationType {
  Purchase = "Purchase",
  Refinance = "Refinance",
  Equity = "Equity",
}

export function showApplicationType(applicationType: ApplicationType): string {
  switch (applicationType) {
    case ApplicationType.Purchase:
      return "Purchase a property";
    case ApplicationType.Refinance:
      return "Refinance my mortgage";
    case ApplicationType.Equity:
      return "Access home equity";
  }
}

export const applicationTypeCodec = stringEnumCodec<typeof ApplicationType>(
  ApplicationType,
  "ApplicationType",
);

export const EqApplicationType: Eq.Eq<ApplicationType> = EqString;
export const OrdApplicationType: Ord.Ord<ApplicationType> = OrdString;

export const livingArrangementCodec = t.union([
  t.type({
    type: t.literal("LivingRentFree"),
  }),
  t.type({
    type: t.literal("Rent"),
    monthlyRent: t.number,
  }),
  t.type({
    type: t.literal("OwnAndMakingPayments"),
  }),
  t.type({
    type: t.literal("OwnAndPaidInFull"),
  }),
]);

export type LivingArrangement = t.TypeOf<typeof livingArrangementCodec>;

export type LivingArrangementType = LivingArrangement["type"];

export enum MaritalStatus {
  Married = "Married",
  Unmarried = "Unmarried",
  Separated = "Separated",
}

export const maritalStatusCodec = stringEnumCodec<typeof MaritalStatus>(
  MaritalStatus,
  "MaritalStatus",
);

export const maritalStatusOptions: MaritalStatus[] = [
  MaritalStatus.Married,
  MaritalStatus.Unmarried,
  MaritalStatus.Separated,
];

export function showMaritalStatus(maritalStatus: MaritalStatus): string {
  switch (maritalStatus) {
    case MaritalStatus.Married:
      return "Married";
    case MaritalStatus.Unmarried:
      return "Unmarried";
    case MaritalStatus.Separated:
      return "Separated";
  }
}

export enum CitizenshipStatus {
  UsCitizen = "US_Citizen",
  PermanentResident = "Permanent_Resident_Alien",
  NonPermanentResident = "Non_Permanent_Resident_Alien",
}

export const citizenshipStatusCodec = stringEnumCodec<typeof CitizenshipStatus>(
  CitizenshipStatus,
  "CitizenshipStatus",
);

export const citizenshipStatusOptions: CitizenshipStatus[] = [
  CitizenshipStatus.UsCitizen,
  CitizenshipStatus.PermanentResident,
  CitizenshipStatus.NonPermanentResident,
];

export function showCitizenshipStatus(
  citizenshipStatus: CitizenshipStatus,
): string {
  switch (citizenshipStatus) {
    case CitizenshipStatus.UsCitizen:
      return "US Citizen";
    case CitizenshipStatus.PermanentResident:
      return "Permanent Resident";
    case CitizenshipStatus.NonPermanentResident:
      return "Non-Permanent Resident";
  }
}

export enum SourceOfFunds {
    JointAccount = "JointAccount",
    IndividualAccount = "IndividualAccount",
    CoApplicantIndividualAccount = "CoApplicantIndividualAccount",
    GiftFromFamilyMember = "GiftFromFamilyMember",
    ProceedsFromRealEstateSale = "ProceedsFromRealEstateSale"
}

export const sourceOfFundsCodec = stringEnumCodec<typeof SourceOfFunds>(
    SourceOfFunds,
    "SourceOfFunds",
);

export const EqSourceOfFunds: Eq.Eq<SourceOfFunds> = EqString;
export const OrdSourceOfFunds: Ord.Ord<SourceOfFunds> = OrdString;

export const sourceOfFundsOptions: SourceOfFunds[] = [
    SourceOfFunds.IndividualAccount,
    SourceOfFunds.CoApplicantIndividualAccount,
    SourceOfFunds.JointAccount,
    SourceOfFunds.GiftFromFamilyMember,
    SourceOfFunds.ProceedsFromRealEstateSale
];

export function showSourceOfFunds(sourceOfFunds: SourceOfFunds): string {
    switch (sourceOfFunds) {
        case SourceOfFunds.IndividualAccount:
            return "Primary applicant's individual account";
        case SourceOfFunds.JointAccount:
            return "Joint account";
        case SourceOfFunds.CoApplicantIndividualAccount:
            return "Co-applicant's individual account";
        case SourceOfFunds.GiftFromFamilyMember:
            return "Gift from a family member";
        case SourceOfFunds.ProceedsFromRealEstateSale:
            return "Proceeds from sale of real estate owned";
    }
}

export const addressPayloadCodec = t.type({
  sequence: t.number,
  street: t.string.pipe(nonEmptyStringCodec()),
  unit: optionFromString(t.string.pipe(nonEmptyStringCodec())),
  city: t.string.pipe(nonEmptyStringCodec()),
  state: stateCodec,
  country: t.string,
  zip: t.string.pipe(zipCodeCodec),
  monthsAtAddress: positiveIntCodec,
  livingArrangement: livingArrangementCodec,
  addressId: tt.optionFromNullable(t.number),
});

export type AddressPayload = t.TypeOf<typeof addressPayloadCodec>;

export const addAddressPayload =
  (payload: AddressPayload) =>
  (addresses: AddressPayload[]): NonEmptyArray<AddressPayload> =>
    pipe(
      addresses,
      A.map((addr): [number, AddressPayload] => [addr.sequence, addr]),
      M.fromFoldable(EqNumber, SG.last<AddressPayload>(), A.Foldable),
      M.upsertAt(EqNumber)(payload.sequence, payload),
      M.toArray(OrdNumber),
      NEA.fromArray,
      // this will never get triggered because we've just upserted the payload into the map
      // but it makes the compiler happy
      O.getOrElse(
        (): NonEmptyArray<[number, AddressPayload]> =>
          NEA.of([payload.sequence, payload]),
      ),
      NEA.map(([_, addr]) => addr),
    );

export const totalMonthsAtAddressRequired = 24;

export const selfEmploymentPayloadCodec = t.union([
    t.type({
        type: t.literal("ScheduleC_or_1099"),
    }),
    t.type({
        type: t.literal("Corporation_S"),
        w2Wages: t.boolean,
    }),
    t.type({
        type: t.literal("Corporation_C"),
        w2Wages: t.boolean,
    }),
    t.type({
        type: t.literal("Partnership"),
    }),
]);

export type SelfEmploymentPayload = t.TypeOf<typeof selfEmploymentPayloadCodec>;

export type SelfEmploymentType = SelfEmploymentPayload["type"];

export const selfEmploymentTypes: SelfEmploymentType[] = [
    "ScheduleC_or_1099",
    "Corporation_C",
    "Corporation_S",
    "Partnership",
];

export function showSelfEmploymentType(
    selfEmploymentType: SelfEmploymentType,
): string {
    switch (selfEmploymentType) {
        case "ScheduleC_or_1099":
            return "1099 or Schedule C";
        case "Partnership":
            return "Partnership";
        case "Corporation_C":
            return "C Corporation";
        case "Corporation_S":
            return "S Corporation";
    }
}

export const workSchedulePayloadCodec = t.union([
  t.type({
    type: t.literal("FullTime"),
  }),
  t.type({
    type: t.literal("PartTime"),
    atLeast2Years: t.boolean,
  }),
  t.type({
    type: t.literal("Both"),
    atLeast2Years: t.boolean,
  }),
]);

export type WorkSchedulePayload = t.TypeOf<typeof workSchedulePayloadCodec>;

export type WorkScheduleType = WorkSchedulePayload["type"];

export const workScheduleTypes: WorkScheduleType[] = [
  "FullTime",
  "PartTime",
  "Both",
];

export function showWorkScheduleType(
  workScheduleType: WorkScheduleType,
): string {
  switch (workScheduleType) {
    case "FullTime":
      return "Full-time";
    case "PartTime":
      return "Part-time";
    case "Both":
      return "Full-time and part-time";
  }
}

export const incomeSourcePayloadCodec = t.union([
  t.type({
    type: t.literal("SelfEmployed"),
    selfEmployment: selfEmploymentPayloadCodec,
  }),
  t.type({
    type: t.literal("W2Wages"),
    workSchedule: workSchedulePayloadCodec,
    receivesBonusOvertimeCommission: t.boolean,
  }),
  t.type({
    type: t.literal("Active_Duty_Military"),
  }),
  t.type({
    type: t.literal("Retired_Disability_Veteran"),
  }),
  t.type({
    type: t.literal("Retirement_Income"),
  }),
  t.type({
    type: t.literal("Social_Security_Disability"),
  }),
  t.type({
    type: t.literal("Other_Alimony_ChildSupport"),
    description: t.string,
  }),
]);

export const yearsAtWorkCodec = tt
  .withMessage(numberCodecS, () => "Should be a number")
  .pipe(positiveIntCodec);

export const fullEmployerInfoCodec = t.type({
  employerInfoId: tt.optionFromNullable(t.number),
  employerId: t.number,
  startDate: t.string.pipe(validDateTimeCodecS),
  endDate: tt.optionFromNullable(t.string.pipe(validDateTimeCodecS)),
  isStillEmployed: t.boolean,
  yearsInLineOfWork: tt.optionFromNullable(t.number),
  linkedEmployerId: tt.optionFromNullable(t.number),
  receivedOvertimeBonusCommission: t.boolean,
});

export const patchEmployerInfoCodec = t.type({
  employerId: t.number,
  startDate: t.string.pipe(isoDateCodec),
  endDate: tt.optionFromNullable(t.string.pipe(isoDateCodec)),
  isStillEmployed: t.boolean,
  receivedOvertimeBonusCommission: t.boolean,
  yearsInLineOfWork: tt.optionFromNullable(t.number),
});

export const mappedEmployerInfoCodec = t.type({
  linkedEmployerId: t.number,
  employerId: t.number,
});

export const employerInfoPayloadCodec = t.union([
  mappedEmployerInfoCodec,
  fullEmployerInfoCodec,
  patchEmployerInfoCodec,
]);

export type FullEmployerInfo = t.TypeOf<typeof fullEmployerInfoCodec>;

export const employerPatchPayloadCodec = t.type({
  employerId: t.number,
  applicantId: t.number,
  name: t.string,
  employerInfo: patchEmployerInfoCodec,
});

export const mappedEmployerPatchPayloadCodec = t.type({
  employerId: t.number,
  applicantId: t.number,
  name: t.string,
  employerInfo: mappedEmployerInfoCodec,
});

export const employersPayloadCodec = t.type({
  employerId: t.number,
  applicantId: t.number,
  name: t.string,
  employerInfo: tt.optionFromNullable(employerInfoPayloadCodec),
});

export type EmployerInfoPayload = t.TypeOf<typeof employerInfoPayloadCodec>;

export type EmployersPayload = t.TypeOf<typeof employersPayloadCodec>;

export const employerGeneralPayload = t.union([
  mappedEmployerPatchPayloadCodec,
  employerPatchPayloadCodec,
]);
export type EmployersPatchPayload = t.TypeOf<typeof employerGeneralPayload>;

export type IncomeSourcePayload = t.TypeOf<typeof incomeSourcePayloadCodec>;

export type IncomeSourceType = IncomeSourcePayload["type"];

export function showIncomeSourceType(
  incomeSourceType: IncomeSourcePayload["type"],
): string {
  switch (incomeSourceType) {
    case "SelfEmployed":
      return "Self-employed";
    case "W2Wages":
      return "W-2 wages";
    case "Active_Duty_Military":
      return "Active duty military";
    case "Retired_Disability_Veteran":
      return "Retired or Disabled Military";
    case "Retirement_Income":
      return "Retirement income";
    case "Social_Security_Disability":
      return "Social Security / Disability";
    case "Other_Alimony_ChildSupport":
      return "Other(Alimony, Child Support)";
  }
}

export const EqIncomeSourceType: Eq.Eq<IncomeSourceType> = EqString;
export const OrdIncomeSourceType: Ord.Ord<IncomeSourceType> = OrdString;

// export const EqIncomeSourceByType: Eq.Eq<IncomeSourcePayload> =
//   Eq.contramap((incomeSource: IncomeSourcePayload) => incomeSource.type)(EqString);

export const OrdIncomeSourceByType: Ord.Ord<IncomeSourcePayload> =
  Ord.contramap((incomeSource: IncomeSourcePayload) => incomeSource.type)(
    OrdString,
  );

export const bankruptcyStatusPayloadCodec = t.union([
  t.type({
    type: t.literal("None"),
  }),
  t.type({
    type: t.literal("Chapter7"),
    discharged: t.boolean,
  }),
  t.type({
    type: t.literal("Chapter13"),
    paymentPlanCompleted: t.boolean,
  }),
]);

export function showBankruptcyStatusType(
  bankruptcyStatusType: BankruptcyStatusType,
): string {
  switch (bankruptcyStatusType) {
    case "None":
      return "None";
    case "Chapter7":
      return "Chapter 7";
    case "Chapter13":
      return "Chapter 13";
  }
}

export type BankruptcyStatusPayload = t.TypeOf<
  typeof bankruptcyStatusPayloadCodec
>;

export type BankruptcyStatusType = BankruptcyStatusPayload["type"];

export const EqBankruptcyStatusType: Eq.Eq<BankruptcyStatusType> = EqString;

export enum RetirementAccount {
  Retirement401k = "Retirement401k",
  Retirement103b = "Retirement103b",
  RothIRA = "RothIRA",
  TraditionalIRA = "TraditionalIRA",
  Annuity = "Annuity",
  Other = "Other",
  None = "None"
}

export const retirementAccountTypes: RetirementAccount[] = [
  RetirementAccount.Retirement401k,
  RetirementAccount.Retirement103b,
  RetirementAccount.RothIRA,
  RetirementAccount.TraditionalIRA,
  RetirementAccount.Annuity,
  RetirementAccount.Other,
  RetirementAccount.None,
];

export function showRetirementAccountType(
  retirementAccountType: RetirementAccount,
): string {
  switch (retirementAccountType) {
    case RetirementAccount.Retirement401k:
      return "Retirement 401(k)";
    case RetirementAccount.Retirement103b:
      return "Retirement 103(b)";
    case RetirementAccount.RothIRA:
      return "Roth IRA";
    case RetirementAccount.TraditionalIRA:
      return "Traditional IRA";
    case RetirementAccount.Annuity:
      return "Annuity";
    case RetirementAccount.Other:
      return "Other";
    case RetirementAccount.None:
        return "None";
  }
}

export const EqRetirementAccount: Eq.Eq<RetirementAccount> = EqString;
export const OrdRetirementAccount: Ord.Ord<RetirementAccount> = OrdString;

export const retirementAccountCodec = stringEnumCodec<typeof RetirementAccount>(
  RetirementAccount,
  "RetirementAccount",
);

export const applicantPersonalInfoCodec = t.type({
  fullName: t.string.pipe(nonEmptyStringCodec()),
  email: t.string.pipe(emailStringCodec),
  ssn: t.union([
    t.string.pipe(socialSecurityNumberCodec),
    t.string.pipe(confirmSocialSecurityNumberCodec),
    t.string.pipe(optionalSocialSecurityNumberCodec),
    t.null,
  ]),
  applicantId: t.number,
  phone: t.string.pipe(phoneNumberCodec),
  dateOfBirth: t.union([t.string.pipe(isoDateCodec), NullFromEmptyString]),
  addresses: tt.nonEmptyArray(addressPayloadCodec),
  maritalStatus: t.string.pipe(maritalStatusCodec),
  citizenshipStatus: t.string.pipe(citizenshipStatusCodec),
  divorced: t.boolean,
});

export type ApplicantPersonalInfo = t.TypeOf<typeof applicantPersonalInfoCodec>;

export const applicantFinancialInfoCodec = t.type({
  incomeSources: t.array(incomeSourcePayloadCodec),
  employers: t.array(employersPayloadCodec),
  retirementAccounts: tt.setFromArray(
    t.string.pipe(retirementAccountCodec),
    OrdRetirementAccount,
  ),
  bankruptcyStatus: bankruptcyStatusPayloadCodec,
  foreclosureOrShortSale: t.boolean,
  hasAlimonyOrChildSupportObligation: t.boolean,
  consents: t.array(surveyConsentCodec),
  providedConsentForElectronicBusiness: t.boolean,
});

export type ApplicantFinancialInfo = t.TypeOf<
  typeof applicantFinancialInfoCodec
>;

export const applicantPayloadCodec = t.intersection([
  applicantPersonalInfoCodec,
  applicantFinancialInfoCodec,
]);

export type ApplicantPayload = t.TypeOf<typeof applicantPayloadCodec>;

export enum JointApplicantType {
  Spouse = "Spouse",
  SignificantOther = "SignificantOther",
  FamilyMember = "FamilyMember",
  Other = "Other",
}

export const jointApplicantTypeCodec = stringEnumCodec<
  typeof JointApplicantType
>(JointApplicantType, "JointApplicantType");

export const jointApplicantTypeOptions: JointApplicantType[] = [
  JointApplicantType.Spouse,
  JointApplicantType.SignificantOther,
  JointApplicantType.FamilyMember,
  JointApplicantType.Other,
];

export enum ApplicationStatusType {
  New = "New",
  Complete = "Complete",
  InProgress = "InProgress",
  Attention = "Attention",
  Exported = "Exported",
  Error = "Error",
  Archived = "Archived",
  SurveyIncomplete = "SurveyIncomplete",
  Invited = "Invited",
}

export const applicationStatusTypeCodec = stringEnumCodec<
  typeof ApplicationStatusType
>(ApplicationStatusType, "ApplicationStatusType");

export function showJointApplicantType(
  jointApplicantType: JointApplicantType,
): string {
  switch (jointApplicantType) {
    case JointApplicantType.Spouse:
      return "Spouse";
    case JointApplicantType.SignificantOther:
      return "Significant Other";
    case JointApplicantType.FamilyMember:
      return "Family Member";
    case JointApplicantType.Other:
      return "Other";
  }
}

export enum ReferralSource {
  RealEstateAgent = "RealEstateAgent",
  Builder = "Builder",
  OnlineSocialMedia = "OnlineSocialMedia",
  PastClient = "PastClient",
  FriendsFamily = "FriendsFamily",
  Other = "Other",
}

export const referralSourceCodec = stringEnumCodec<typeof ReferralSource>(
  ReferralSource,
  "ReferralSource",
);

export const jointApplicantPayloadCodec = t.type({
  applicantType: t.string.pipe(jointApplicantTypeCodec),
  applicant: applicantPayloadCodec,
  jointApplicantId: t.number
});

export type JointApplicantPayload = t.TypeOf<typeof jointApplicantPayloadCodec>;

export const surveyTypeInfoCodec = t.type({
  applicationType: t.string.pipe(applicationTypeCodec),
  propertyType: t.string.pipe(propertyTypeCodec),
  initiatedDateTimeUtc: t.string.pipe(validDateTimeCodecS),
});

export type SurveyTypeInfo = t.TypeOf<typeof surveyTypeInfoCodec>;

export const applicationInfoCodec = t.type({
  jointApplicants: t.array(jointApplicantPayloadCodec),
  sourceOfFunds: tt.nonEmptyArray(t.string.pipe(sourceOfFundsCodec)),
  workingWithRealEstateAgent: t.boolean,
  referringLoanOfficerId: tt.optionFromNullable(t.number),
  referralSource: tt.optionFromNullable(t.string.pipe(referralSourceCodec)),
  realEstateAgentName: tt.optionFromNullable(t.string),
  realEstateAgentPhone: tt.optionFromNullable(t.string),
  loanOfficerNote: tt.optionFromNullable(t.string),
});

export type ApplicationInfo = t.TypeOf<typeof applicationInfoCodec>;

export const primaryApplicantInfoCodec = t.type({
  primaryApplicant: applicantPayloadCodec,
});

export type PrimaryApplicantInfo = t.TypeOf<typeof primaryApplicantInfoCodec>;

export const surveyPayloadCodec = t.intersection([
  surveyTypeInfoCodec,
  applicationInfoCodec,
  primaryApplicantInfoCodec,
]);

export const savedSurveyPayload = t.type({
  applicationId: t.number,
  surveyId: t.number,
});

export type SurveyPayload = t.TypeOf<typeof surveyPayloadCodec>;

export const savedSurveyPayloadCodec = t.intersection([
  surveyPayloadCodec,
  savedSurveyPayload,
]);

export type SavedSurveyPayload = t.TypeOf<typeof savedSurveyPayloadCodec>;

interface ApplicationIdBrand {
  readonly ApplicationId: unique symbol;
}

export const applicationIdCodec = t.brand(
  t.Int,
  (_): _ is t.Branded<t.Int, ApplicationIdBrand> => true,
  "ApplicationId",
);

export type ApplicationId = t.TypeOf<typeof applicationIdCodec>;

export const saveLoanOfficerNotesPayloadCodec = t.type({
  applicationId: applicationIdCodec,
  content: t.string,
});

export type SaveLoanOfficerNotesPayload = t.TypeOf<
  typeof saveLoanOfficerNotesPayloadCodec
>;

export const saveSurveyResultCodec = t.type({
  applicationId: applicationIdCodec,
  isExistingSurvey: t.boolean,
});

export const loanOfficerInfoCodec = t.type({
  email: t.string,
  fullName: t.string,
  nmls: t.string,
  phone: t.string,
  userId: t.number,
});

export type LoanOfficerInfo = t.TypeOf<typeof loanOfficerInfoCodec>;

export type SaveSurveyResult = t.TypeOf<typeof saveSurveyResultCodec>;

export const applicationIdEq: Eq.Eq<ApplicationId> = EqNumber;
export const applicationIdOrd: Ord.Ord<ApplicationId> = OrdNumber;

export const applicationCodec = t.type({
  applicationId: applicationIdCodec,
  requiredDocuments: t.array(applicationDocumentCodec),
  survey: savedSurveyPayloadCodec,
  createdDateUtc: t.string.pipe(validDateTimeCodecS),
  submittedDateUtc: tt.optionFromNullable(t.string.pipe(validDateTimeCodecS)),
  archivedDateUtc: tt.optionFromNullable(t.string.pipe(validDateTimeCodecS)),
  invitedDateUtc: tt.optionFromNullable(t.string.pipe(validDateTimeCodecS)),
  exportedDateUtc: tt.optionFromNullable(t.string.pipe(validDateTimeCodecS)),
  status: t.string.pipe(applicationStatusTypeCodec),
  loanOfficer: tt.optionFromNullable(loanOfficerInfoCodec),
});

export type Application = t.TypeOf<typeof applicationCodec>;

export const branchPayloadCodec = t.type({
  name: t.string.pipe(nonEmptyStringCodec()),
});

export type BranchPayload = t.TypeOf<typeof branchPayloadCodec>;

export const editBranchPayloadCodec = t.intersection([
  branchPayloadCodec,
  t.type({ branchId: branchIdCodec, clientId: clientIdCodec }),
]);

export const teamPayloadCodec = t.type({
  branchId: branchIdCodec,
  name: t.string.pipe(nonEmptyStringCodec()),
});

export type TeamPayload = t.TypeOf<typeof teamPayloadCodec>;

export const editTeamPayloadCodec = t.intersection([
  teamPayloadCodec,
  t.type({ teamId: teamIdCodec }),
]);

export const userPayloadCodec = t.type({
  firstName: t.string.pipe(nonEmptyStringCodec()),
  lastName: t.string.pipe(nonEmptyStringCodec()),
  email: t.string.pipe(emailStringCodec),
  mobilePhone: t.string.pipe(nonEmptyStringCodec()),
  branchId: tt.optionFromNullable(branchIdCodec),
  teamId: tt.optionFromNullable(teamIdCodec),
  roles: tt.setFromArray(t.string.pipe(extensionRoleCodec), OrdExtensionRole),
});

export type UserPayload = t.TypeOf<typeof userPayloadCodec>;

export enum VerificationFlowType {
  reset = "reset",
  signIn = "sign-in",
  InvalidToken = "invalid-token",
}

export const verificationFlowTypeCodec = stringEnumCodec<
  typeof VerificationFlowType
>(VerificationFlowType, "VerificationFlowType");

export const borrowerVerifyTokenResponseCodec = t.type({
  flow: t.string.pipe(verificationFlowTypeCodec),
  email: t.string.pipe(emailStringCodec),
  phone: t.string.pipe(phoneNumberCodec),
  clientLogoUrl: tt.optionFromNullable(t.string),
});

export type BorrowerVerifyTokenResponse = t.TypeOf<
  typeof borrowerVerifyTokenResponseCodec
>;

export const editUserPayloadCodec = t.intersection([
  userPayloadCodec,
  t.type({ userId: userIdCodec }),
]);

export const accountUserPayloadCodec = t.type({
  userId: userIdCodec,
  lastName: t.string.pipe(nonEmptyStringCodec()),
  email: t.string.pipe(emailStringCodec),
  mobilePhone: t.string.pipe(nonEmptyStringCodec()),
  nmlsNumber: t.string.pipe(nonEmptyStringCodec()),
  address: t.string.pipe(nonEmptyStringCodec()),
  address2: t.string,
  city: t.string.pipe(nonEmptyStringCodec()),
  state: t.string.pipe(stateCodec),
  zipCode: t.string.pipe(nonEmptyStringCodec()),
});

export type AccountUserPayload = t.TypeOf<typeof accountUserPayloadCodec>;

export const clientSettingsFormPayloadCodec = t.type({
  companyName: t.string.pipe(nonEmptyStringCodec()),
  websiteUrl: t.string.pipe(nonEmptyStringCodec()),
  phone: t.string.pipe(nonEmptyStringCodec()),
  nmlsNumber: t.string.pipe(nonEmptyStringCodec()),
});

export type ClientSettingsFormPayload = t.TypeOf<
  typeof clientSettingsFormPayloadCodec
>;

export type ClientSettingsPayload = {
  form: ClientSettingsFormPayload;
  files: File[];
};

export const accountSettingsPayloadCodec = t.type({
  userId: userIdCodec,
  smsNotificationsDisabled: t.boolean,
});

export type AccountSettingsPayload = t.TypeOf<
  typeof accountSettingsPayloadCodec
>;

export const createClientPayloadCodec = t.type({
  firstName: t.string.pipe(nonEmptyStringCodec()),
  lastName: t.string.pipe(nonEmptyStringCodec()),
  email: t.string.pipe(emailStringCodec),
  phone: t.string.pipe(nonEmptyStringCodec()),
  companyName: t.string.pipe(nonEmptyStringCodec()),
  websiteUrl: t.string.pipe(nonEmptyStringCodec()),
  companyType: t.string.pipe(nonEmptyStringCodec()),
  headCount: t.string.pipe(nonEmptyStringCodec()),
  subdomain: t.string.pipe(nonEmptyStringCodec()),
  companyNMLS: t.string.pipe(nonEmptyStringCodec()),
  loanOfficerNMLS: t.string.pipe(nonEmptyStringCodec()),
});

export type CreateClientPayload = t.TypeOf<typeof createClientPayloadCodec>;

export const createClientResponseCodec = t.type({
  createPaymentCustomerSubscriptionResult: t.type({
    paymentMethodSetupClientSecret: t.string.pipe(nonEmptyStringCodec()),
  }),
});

export type CreateClientResponse = t.TypeOf<typeof createClientResponseCodec>;

export const EqReferralSource: Eq.Eq<ReferralSource> = EqString;
export const OrdReferralSource: Ord.Ord<ReferralSource> = OrdString;

export const referralSourceOptions: ReferralSource[] = [
  ReferralSource.RealEstateAgent,
  ReferralSource.Builder,
  ReferralSource.OnlineSocialMedia,
  ReferralSource.PastClient,
  ReferralSource.FriendsFamily,
  ReferralSource.Other,
];

export const showReferralSource = (referralSource: ReferralSource): string => {
  switch (referralSource) {
    case ReferralSource.RealEstateAgent:
      return "Real Estate Agent";
    case ReferralSource.Builder:
      return "Builder";
    case ReferralSource.OnlineSocialMedia:
      return "Online Social Media";
    case ReferralSource.PastClient:
      return "Past Client";
    case ReferralSource.FriendsFamily:
      return "Friends/Family";
    case ReferralSource.Other:
      return "Other";
  }
};

export const saveSurveyToLocalStorage = (payload: SurveyPayload) =>
  pipe(surveyPayloadCodec.encode(payload), (value) =>
    localStorage.setItem(
      LocalStoragetKeys.BorrowerSignup,
      JSON.stringify(value),
    ),
  );

export function showApplicantType(hasJointApplicants: boolean): string {
  return hasJointApplicants ? "Joint application" : "Individual applicant";
}

export enum ApplicationModifyStatus {
  Modified = "Modified",
  NotModified = "NotModified",
}

export const applicationModifyStatusCodec = stringEnumCodec<
  typeof ApplicationModifyStatus
>(ApplicationModifyStatus, "ApplicationModifyStatus");

export const applicationModifyPayloadCodec = t.type({
  status: t.string.pipe(applicationModifyStatusCodec),
  applicationId: applicationIdCodec,
});

export type ApplicationModifyPayload = t.TypeOf<
  typeof applicationModifyPayloadCodec
>;

export const referringLoanOfficerInfoCodec = t.type({
  fullname: t.string,
  email: t.string,
  phoneNumber: t.string
})

export type ReferringLoanOfficerInfo = t.TypeOf<typeof referringLoanOfficerInfoCodec>
