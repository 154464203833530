import { ApplicationsFilterPayload } from "@/data/applicationsList";
import { UserId } from "@/data/client";
import {
  ApplicationId,
  ApplicationStatusType,
  ApplicationType,
} from "@/data/payload";
import { IsoDate } from "@/utils/codecs";
import { ChildProps } from "@/utils/reducerWithEffect";
import { Eq } from "fp-ts/lib/Eq";
import { Ord as OrdNumber } from "fp-ts/lib/number";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";
import * as Ord from "fp-ts/lib/Ord";
import { Eq as EqString } from "fp-ts/lib/string";
import { Ord as OrdString } from "fp-ts/string";
import { DateTime, DurationLike } from "luxon";
import { Action } from "./action";
import { Model } from "./model";

export type Props = ChildProps<Model, Action> & {
  onApplicationSelected: (applicationId: ApplicationId) => void;
  showFilters?: boolean;
  onSummaryOptionSelection: (applicationId: ApplicationId) => void;
};

export const OrdStatusFilter: Ord.Ord<ApplicationStatusType> = OrdString;
export const OrdApplicationTypeFilter: Ord.Ord<ApplicationType> = OrdString;
export const OrdAssigneFilter: Ord.Ord<UserId> = OrdNumber;

export type ApplicationAssignees = [UserId, string][];

export const noneIfEmpty = <A>(set: Set<A>): Option<Set<A>> =>
  set.size === 0 ? O.none : O.some(set);

export enum SubmissionDateFilterOption {
  Last24Hours = "Last 24 hours",
  LastWeek = "Last week",
  Last2Weeks = "Last 2 weeks",
  LastMonth = "Last month",
}

export enum CreditCheckConsentFilterOption {
  Authorized  = "Authorized",
  NotAuthorized = "Not Authorized",  
}

export const EqSubmissionDateFilterOption: Eq<SubmissionDateFilterOption> =
  EqString;

export const EqCreditCheckConsentFilterOption: Eq<CreditCheckConsentFilterOption> =
EqString;

export const dateRanges = (
  date: SubmissionDateFilterOption,
): Partial<ApplicationsFilterPayload> => {
  let subtractedUnit: DurationLike = { days: 0 };
  switch (date) {
    case SubmissionDateFilterOption.Last24Hours:
      subtractedUnit = { hours: 24 };
      break;
    case SubmissionDateFilterOption.Last2Weeks:
      subtractedUnit = { week: 2 };
      break;
    case SubmissionDateFilterOption.LastWeek:
      subtractedUnit = { week: 1 };
      break;
    case SubmissionDateFilterOption.LastMonth:
      subtractedUnit = { month: 1 };
      break;
  }
  return {
    startDate: DateTime.now().minus(subtractedUnit) as IsoDate,
    endDate: DateTime.now() as IsoDate,
  };
};
