import { ViewResolver } from "@/utils/viewResolver";
import { Row, Label } from "../../basic";
import { TogglePanel, Col, Table } from "../../basic";
import { SCorporation } from "@/data/coversheet";
import { formatCurrency } from "@/utils/formatters";
import * as O from 'fp-ts/Option';

const formatNegativeNumber = (value: number): JSX.Element => {
    if (value < 0) {
        return <span style={{ color: '#d81819' }}>({formatCurrency(Math.abs(value))})</span>;
    }
    return <span>{formatCurrency(value)}</span>;
};
const formatAlwaysNegative = (value: number): JSX.Element => {
    return <span style={{ color: '#d81819' }}>({formatCurrency(Math.abs(value))})</span>;
};

type Props = {
  entity: SCorporation;
};

function MonthlyIncomeSummary({ entity }: Props) {
  return (
    <Col gap="sm">
      <h3>Monthly Income Summary</h3>
      <div className="monthly-income-summary">
        <div className="total-monthly-income">
          <span className="income-category-label">Qualifying Monthly Income</span><br />
          {formatCurrency(entity.qualifyingIncome)}
        </div>
      </div>
    </Col>
  );
}

// Helper type for transposed table data
type PropertyRow = {
  propertyName: string;
  values: { [year: string]: string | number };
};

// Create a type-safe wrapper for the ViewResolver
type TableColumnType<T> = Array<{
  columnKey: string;
  header: JSX.Element;
  headerContainerClassName?: string;
  view: (item: T) => JSX.Element;
}>;

function getDesktopColumns(items: SCorporation["annualData"]): TableColumnType<PropertyRow> {
  // Create columns with years as headers
  const columns: TableColumnType<PropertyRow> = [
    {
      columnKey: "propertyName",
          header: <div style={{ textAlign: "left", paddingLeft: "12px" }}>Property</div>,
          headerContainerClassName: "align-left w-100 text-left",
          view: (item: PropertyRow) => <div className="text-left" style={{ paddingLeft: "12px" }}>{item.propertyName}</div>
    }
  ];
  
  // Add a column for each year in the data
  items.forEach(yearData => {
    columns.push({
      columnKey: `year_${yearData.taxYear}`,
        header: <div style={{ textAlign: "right", paddingRight: "18px" }}>{yearData.taxYear}</div>,
        headerContainerClassName: "align-right w-100 text-right",
        view: (item: PropertyRow) => <div className="text-right" style={{ paddingRight: "12px" }}>{
            typeof item.values[yearData.taxYear] === 'number' && 
        typeof item.values[yearData.taxYear] === 'number' && 
        item.propertyName === 'Percentage Ownership' ? 
        `${item.values[yearData.taxYear]}%` : 
        typeof item.values[yearData.taxYear] === 'number' ? 
                    (
                        item.propertyName === 'K-1 earnings' ?
                            formatNegativeNumber(item.values[yearData.taxYear] as number) :
                            (item.propertyName === 'Other Income' ||
                                item.propertyName === 'Mortgages Notes and Bonds Payable' ||
                                item.propertyName === 'Travel Expenses') ?
                                formatAlwaysNegative(item.values[yearData.taxYear] as number) :
                                formatCurrency(item.values[yearData.taxYear] as number)
                    ) :  
        item.values[yearData.taxYear]
      }</div>
    });
  });
  
  return columns;
}


function AnnualDataSummary({ items }: { items: SCorporation["annualData"] }) {
  // Check if items array is empty
  if (!items || items.length === 0) {
    return (
      <Col gap="sm">
        <h3>Annual Data</h3>
        <div>No annual data available</div>
      </Col>
    );
  }

  // Transform data for desktop view
  const propertyRows: PropertyRow[] = [
    { propertyName: "Year", values: {} },
    { propertyName: "K-1 earnings", values: {} },
    { propertyName: "W2 Income", values: {} },
    { propertyName: "Other Income", values: {} },
    { propertyName: "Percentage Ownership", values: {} },
    { propertyName: "Depletion", values: {} },
    { propertyName: "Depreciation", values: {} },
    { propertyName: "Travel Expenses", values: {} },
    { propertyName: "Amortization", values: {} },
    { propertyName: "Mortgages Notes and Bonds Payable", values: {} },
    { propertyName: "Total Income", values: {} },
    { propertyName: "Monthly Income", values: {} }
  ];
  
  // Populate values for each year
  items.forEach(yearData => {
    if (yearData && yearData.taxYear) {
      propertyRows[0].values[yearData.taxYear] = yearData.taxYear;
      propertyRows[1].values[yearData.taxYear] = yearData.k1_OrdinaryIncome || 0;
      propertyRows[2].values[yearData.taxYear] = yearData.w2Income || 0;
      propertyRows[3].values[yearData.taxYear] = yearData.otherIncome || 0;
      propertyRows[4].values[yearData.taxYear] = yearData.percentageOfOwnership || 0;
      propertyRows[5].values[yearData.taxYear] = yearData.depletion || 0;
      propertyRows[6].values[yearData.taxYear] = yearData.depreciation || 0;
      propertyRows[7].values[yearData.taxYear] = yearData.nonDeductibleTravelAndEntertainment || 0;
      propertyRows[8].values[yearData.taxYear] = yearData.amortization || 0;
      propertyRows[9].values[yearData.taxYear] = yearData.mortgagesNotesBondsPayable || 0;
      propertyRows[10].values[yearData.taxYear] = yearData.totalIncome || 0;
      propertyRows[11].values[yearData.taxYear] = yearData.monthlyIncome || 0;
    }
  });

  // Use device orientation to determine which view to show
  const deviceInfo = ViewResolver({
    viewModes: [
      ["Default"],
      ["Mobile-Portrait", "Mobile-Landscape"]
    ],
    resolvedContent: [
      "desktop",
      "mobile"
    ]
  });

  // For mobile view, render each year as a separate card instead of using Table
    if (deviceInfo === "mobile") {
        return (
            <Col gap="sm">
                <h3>Annual Data</h3>
                {items.map((yearData, index) => (
                    <div key={index} className="mobile-year-card">
                        <Col className="data-table-cell mobile-column" padding="sm" gap="xs">
                            <Row>
                                <Label className="text-mbold">{yearData.taxYear}</Label>
                            </Row>
                            <Row className="space-between">
                                <Label className="data-label">K-1 earnings:</Label>
                                <Label className="black">{formatNegativeNumber(yearData.k1_OrdinaryIncome || 0)}</Label>
                            </Row>
                            {(yearData.w2Income || 0) > 0 && (
                                <Row className="space-between">
                                    <Label className="data-label">W2 Income:</Label>
                                    <Label className="black">{formatCurrency(yearData.w2Income || 0)}</Label>
                                </Row>
                            )}
                            {(yearData.otherIncome || 0) !== 0 && (
                                <Row className="space-between">
                                    <Label className="data-label">Other Income:</Label>
                                    <Label className="black">{formatAlwaysNegative(yearData.otherIncome || 0)}</Label>
                                </Row>
                            )}
                            <Row className="space-between">
                                <Label className="data-label">Percentage Ownership:</Label>
                                <Label className="black">{(yearData.percentageOfOwnership || 0)}%</Label>
                            </Row>
                            {(yearData.depletion || 0) > 0 && (
                                <Row className="space-between">
                                    <Label className="data-label">Depletion:</Label>
                                    <Label className="black">{formatCurrency(yearData.depletion || 0)}</Label>
                                </Row>
                            )}
                            {(yearData.depreciation || 0) > 0 && (
                                <Row className="space-between">
                                    <Label className="data-label">Depreciation:</Label>
                                    <Label className="black">{formatCurrency(yearData.depreciation || 0)}</Label>
                                </Row>
                            )}
                            {(yearData.nonDeductibleTravelAndEntertainment || 0) !== 0 && (
                                <Row className="space-between">
                                    <Label className="data-label">Travel Expenses:</Label>
                                    <Label className="black">{formatAlwaysNegative(yearData.nonDeductibleTravelAndEntertainment || 0)}</Label>
                                </Row>
                            )}
                            {(yearData.amortization || 0) > 0 && (
                                <Row className="space-between">
                                    <Label className="data-label">Amortization:</Label>
                                    <Label className="black">{formatCurrency(yearData.amortization || 0)}</Label>
                                </Row>
                            )}
                            {(yearData.mortgagesNotesBondsPayable || 0) !== 0 && (
                                <Row className="space-between">
                                    <Label className="data-label">Mortgages Notes and Bonds Payable:</Label>
                                    <Label className="black">{formatAlwaysNegative(yearData.mortgagesNotesBondsPayable || 0)}</Label>
                                </Row>
                            )}
                            <Row className="space-between">
                                <Label className="data-label">Total Income</Label>
                                <Label className="black">{formatCurrency(yearData.totalIncome || 0)}</Label>
                            </Row>
                            <Row className="space-between">
                                <Label className="data-label">Monthly Income:</Label>
                                <Label className="black">{formatCurrency(yearData.monthlyIncome || 0)}</Label>
                            </Row>
                        </Col>
                    </div>
                ))}
            </Col>
        );
    }

  // Desktop view uses the Table component
  return (
    <Col gap="sm">
      <h3>Annual Data</h3>
      <Table 
        data={propertyRows}
        columns={getDesktopColumns(items)}
        itemKey={(item) => item.propertyName}
        pageInfo={O.none}
              isLoading={false}
              className="annual-data-table"
      />
    </Col>
  );
}

export function SCorporationCard({ entity }: Props): JSX.Element {
  return (
    <TogglePanel 
      title={entity.entityName}
      subTitle={`Monthly Income: ${formatCurrency(entity.qualifyingIncome)}`}
    >
      <Col gap="md">
        <MonthlyIncomeSummary entity={entity} />
        <AnnualDataSummary items={entity.annualData} />
      </Col>
    </TogglePanel>
  );
}
