import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import { Button } from "../Button";
import { Col } from "../Col";
import { Icon } from "../Icon";
import { Row } from "../Row";
import "@/assets/styles/basic/Modal/modal.css";
import { ForwardedRef, forwardRef, useEffect } from "react";
import { DialogType } from "@/app/model";

export type ModalProps = {
  children: React.ReactNode;
  onClose: O.Option<() => void>;
  title: O.Option<string>;
  id?: string;
  type?: DialogType;
  className?: string
};

export const Modal = forwardRef(
  (props: ModalProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
    const shouldShowCloseButton = pipe(
      O.isSome(props.onClose),
      O.fromPredicate((onClose) => onClose || O.isSome(props.title)),
    );

    useEffect(() => {
      const scrollListener = (e: Event) => {
        e.stopPropagation();
      };
      document.addEventListener("scroll", scrollListener);
      document.querySelector("body")?.setAttribute("style", "overflow: hidden");

      return () => {
        document.removeEventListener("scroll", scrollListener);
        document.querySelector("body")?.setAttribute("style", "overflow: auto");
      };
    }, []);
    return (
      <Col
        className={`modal-dialog ${props.className || ''}` }
        alignVertical="center"
        alignHorizontal="center"
        id={props.id ? props.id : ""}
      >
        <div ref={ref} />
        <Col className="modal-content-parent">
          {pipe(
            shouldShowCloseButton,
            O.fold(
              () => <Col className="modal-content">{props.children}</Col>,
              () => (
                <Col>
                  <Row
                    alignHorizontal="space-between"
                    grow={1}
                    className="modal-header"
                    background="action70"
                    alignVertical="center"
                  >
                    <Row
                      className="modal-title"
                      gap="xs"
                      alignVertical="center"
                    >
                      {pipe(
                        props.type,
                        O.fromNullable,
                        O.fromPredicate(
                          (type) =>
                            O.isSome(type) &&
                            type.value === "UnFinishedApplicationExit",
                        ),
                        O.fold(
                          () => <></>,
                          () => (
                            <Col
                              className="icon-wrapper"
                              alignVertical="center"
                              alignHorizontal="center"
                            >
                              <Icon type="triangle-exclamation" />
                            </Col>
                          ),
                        ),
                      )}
                      <p>
                        {pipe(
                          props.title,
                          O.getOrElse(() => "AppCatcher"),
                        )}
                      </p>
                    </Row>
                    <Col padding="xs">
                      {pipe(
                        props.onClose,
                        O.fold(
                          () => <></>,
                          () => (
                            <Button
                              type="inline-inverted"
                              onClick={props.onClose}
                            >
                              <Icon type="xmark" />
                            </Button>
                          ),
                        ),
                      )}
                    </Col>
                  </Row>
                  <Col padding="xs" className="modal-content">
                    {props.children}
                  </Col>
                </Col>
              ),
            ),
          )}
        </Col>
      </Col>
    );
  },
);
