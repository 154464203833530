import {
  CitizenshipStatus,
  citizenshipStatusCodec,
  MaritalStatus,
  maritalStatusCodec,
} from "@/data/payload";
import { MaritalAndResidencyPayload } from "@/data/surveyFlowPayload";
import { FormField, initFormField } from "@/utils/formField";
import { pipe } from "fp-ts/function";
import { sequenceS } from "fp-ts/Apply";
import { Option } from "fp-ts/Option";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import { Validation } from "io-ts";

export type Model = {
  maritalStatus: FormField<MaritalStatus>;
  citizenshipStatus: FormField<CitizenshipStatus>;
  disableMaritalStatusSelection: boolean;
};

export const init = (payload: Option<MaritalAndResidencyPayload>): Model => ({
  maritalStatus: pipe(
    payload,
    O.fold(
      () => "",
      ({ maritalStatus }) => maritalStatus,
    ),
    initFormField(maritalStatusCodec.decode),
  ),
  citizenshipStatus: pipe(
    payload,
    O.fold(
      () => "",
      ({ citizenshipStatus }) => citizenshipStatus,
    ),
    initFormField(citizenshipStatusCodec.decode),
  ),
  disableMaritalStatusSelection: false,
});

export const result = (model: Model): Validation<MaritalAndResidencyPayload> =>
  sequenceS(E.Apply)({
    maritalStatus: model.maritalStatus.val,
    citizenshipStatus: model.citizenshipStatus.val,
    divorced: E.right(false),
  });
