import { PositiveInt } from "@/utils/codecs";
import { FormField } from "@/utils/formField";
import * as O from "fp-ts/lib/Option";
import { constant, identity, pipe } from "fp-ts/lib/function";
import { useCallback, useMemo } from "react";
import { Row } from "../Row";
import { not } from "fp-ts/lib/Predicate";
import { Col } from "../Col";
import { Label } from "../Label";
import { ViewResolver } from "@/utils/viewResolver";

const stringToNumber = (v: string) =>
  pipe(
    Number(v),
    O.fromPredicate(pipe(isNaN, not)),
    O.fold(constant(0), identity),
  );

export function View(props: {
  field: FormField<PositiveInt>;
  onChange: (months: string) => void;
}): JSX.Element {
  const { field, onChange } = props;
  const { months, years } = useMemo(() => {
    const monthsAsNumber = Number(field.raw);
    return {
      years: Math.floor(monthsAsNumber / 12),
      months: monthsAsNumber % 12,
    };
  }, [field.raw]);

  const toMonths = useCallback(
    (months: number, years: number) => months + years * 12 + "",
    [],
  );

  const yearsAndMonthsView = (
    <>
       <Col gap="xs" grow={1}>
        <Label className="text-sm">Years</Label>
        <input
          className="input text-md"
          type="number"
          value={years || ''}
          onChange={(e) => {
            onChange(toMonths(months, stringToNumber(e.target.value)));
          }}
          required={true}
        />
      </Col>
      <Col gap="xs" grow={1}>
      <Label className="text-sm">Months</Label>
      <input
        type="number"
        className="input text-md"
        value={months || ''}
        onChange={(e) => {
          onChange(toMonths(stringToNumber(e.target.value), years));
        }}
        required={true}
      />
      </Col>
    </>
  )

  const normalView = (
    <Row gap="xs" grow={1}>
    {yearsAndMonthsView}
  </Row>
  );

  const collapseView = (
    <Col gap="xs" grow={1}>
      {yearsAndMonthsView}
    </Col>
  );

  const viewWrapper = ViewResolver({
    viewModes: [["Default"], ["Mobile-Portrait", "Mobile-Landscape"]],
    resolvedContent: [normalView, collapseView]
  })
  return (
    <>{viewWrapper}</>
  );
}
