import { Col, Icon, IconType, Label, Row } from "@/components/basic";
import { ContentProps } from "../view";
import { Fragment, useMemo, useState } from "react";
import { ApplicantPayload } from "@/data/payload";
import * as A from "fp-ts/lib/Array";
import { flow, pipe } from "fp-ts/lib/function";
import { Section, SectionType } from "../model";
import { ApplicantId, SectionChanged } from "../action";
import * as O from "fp-ts/lib/Option";
import { ViewResolver } from "@/utils/viewResolver";
import * as Documents from "@/components/Documents";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuHeader,
  MenuItem,
} from "@szhsin/react-menu";
import { useRights } from "@/utils/use-rights";

type ApplicationSectionItem = {
  type: SectionType;
  tabId: (id?: number) => string;
  suffixIconType: (document: Documents.Model) => IconType;
  prefixIconType: IconType;
  onClick?: () => void;
};

const borrowerApplicationSections: ApplicationSectionItem[] = [
  {
    type: "Personal Information",
    tabId: (id) => `personal-information-${id}`,
    prefixIconType: "user",
    suffixIconType: () => "circle-check",
    onClick: () => {},
  },
  {
    type: "Mortgage Information",
    tabId: (id) => `mortgage-information-${id}`,
    prefixIconType: "house",
    suffixIconType: () => "circle-check",
    onClick: () => {},
  },
  {
    type: "Financial Information",
    tabId: (id) => `financial-information-${id}`,
    prefixIconType: "piggy-bank",
    suffixIconType: () => "circle-check",
    onClick: () => {},
  },
  {
    type: "Employer Information",
    tabId: (id) => `employer-information-${id}`,
    prefixIconType: "user-tie",
    suffixIconType: () => "circle-check",
    onClick: () => {},
  },
];

const commonApplicationSection: ApplicationSectionItem[] = [
  {
    type: "Upload Documentation",
    tabId: () => `upload-documentation`,
    prefixIconType: "file-lines",
    suffixIconType: (document) => {
      if (document) {
        return Documents.isAnyDocumentError(document)
          ? "triangle-exclamation"
          : Documents.allFilesUploaded(document)
            ? "circle-check"
            : Documents.isAnyDocumentValidated(document)
              ? "spinner"
              : "circle";
      } else {
        return "circle-check";
      }
    },
    onClick: () => {},
  },
  {
    type: "Verification",
    tabId: () => `verification`,
    prefixIconType: "search",
    suffixIconType: () => {
      return "circle-check";
    },
    onClick: () => {},
  },
];

export function ApplicationSectionsView(props: ContentProps): JSX.Element {
  const { dispatch } = props;
  const applicants: ApplicantPayload[] = useMemo(
    () =>
      pipe(
        props.model.application.survey.jointApplicants,
        A.map((jointApplicant) => jointApplicant.applicant),
        A.prepend(props.model.application.survey.primaryApplicant)
      ),
    [props.model.application.survey]
  );

  const [selectedSection, setSelectedSection] = useState<string>(
    commonApplicationSection[0].tabId()
  );

  const [applicantName, setApplicantName] = useState<string>("");

  const { isBackOfficeUser } = useRights();
  const isBorrower = pipe(
    isBackOfficeUser,
    O.map((v) => !v)
  );

  const getSection =
    (section: ApplicationSectionItem, applicantId: O.Option<ApplicantId>) =>
    (): Section => {
      const appId: ApplicantId = pipe(
        applicantId,
        O.fold(
          () => ({ type: "Primary" }),
          (a) => a
        )
      );
      switch (section.type) {
        case "Personal Information":
          return { type: "Personal Information", applicantId: appId };
        case "Employer Information":
          return { type: "Employer Information", applicantId: appId };
        case "Financial Information":
          return { type: "Financial Information", applicantId: appId };
        case "Mortgage Information":
          return { type: "Mortgage Information", applicantId: appId };
        case "Upload Documentation":
          return { type: "Upload Documentation" };
        case "Verification":
          return { type: "Verification" };
      }
    };

  const updateSelectedSection = (sectionId: string) =>
    pipe(sectionId, setSelectedSection);

  const updateApplicantName = (applicantId: O.Option<ApplicantId>) => {
    if (O.isSome(applicantId) && applicantId.value.type === "Primary") {
      setApplicantName(applicants[0].fullName);
    } else if (
      O.isSome(applicantId) &&
      applicantId.value.type === "CoApplicant"
    ) {
      const index = applicantId.value.index;
      pipe(
        applicants,
        A.findFirst((applicant) => applicant.applicantId === index),
        O.fold(
          () => setApplicantName(""),
          (applicant) => setApplicantName(applicant.fullName)
        )
      );
    } else {
      setApplicantName("");
    }
  };

  const onSectionChange = (
    sectionId: string,
    section: ApplicationSectionItem,
    appId?: number,
    isPrimaryApplicant?: boolean
  ) => {
    updateSelectedSection(sectionId);
    let applicantId: O.Option<ApplicantId>;
    if (appId && !isPrimaryApplicant) {
      applicantId = O.some({
        type: "CoApplicant",
        index: appId,
      });
    } else if (isPrimaryApplicant) {
      applicantId = O.some({ type: "Primary" });
    } else {
      applicantId = O.none;
    }
    updateApplicantName(applicantId);
    flow(getSection(section, applicantId), SectionChanged, dispatch)();
  };

  const hasEmployerInfo = (
    sectionType: SectionType,
    hasInfo: boolean
  ): O.Option<boolean> =>
    pipe(
      sectionType,
      (v) => {
        if (v === "Employer Information" && hasInfo) {
          return true;
        } else if (v === "Employer Information" && !hasInfo) {
          return false;
        } else {
          return true;
        }
      },
      O.fromNullable
    );

  const DesktopView = (
    <Col gap="md">
      <Col gap="xs" only={isBorrower}>
        <Label className="text-smd text-mbold action-70">Upload & Review</Label>
        <Row>
          Please upload all relevant documentation and review the information
          you have provided.
        </Row>
      </Col>
      <Col gap="xs">
        <Col gap="xxs">
          {commonApplicationSection.map((sectionItem) => (
            <SectionItemView
              key={sectionItem.type}
              {...props}
              selectedSection={selectedSection}
              onSelection={() =>
                onSectionChange(sectionItem.tabId(), sectionItem)
              }
              sectionItem={sectionItem}
            />
          ))}
        </Col>
        {applicants.map((applicant) => (
          <Col key={applicant.applicantId}>
            <Label className="text-md text-mbold action-70 mb-xs">
              {applicant.fullName}
            </Label>

            <Col>
              {borrowerApplicationSections.map((sectionItem) => (
                <SectionItemView
                  key={sectionItem.type}
                  {...props}
                  selectedSection={selectedSection}
                  onSelection={() =>
                    onSectionChange(
                      sectionItem.tabId(applicant.applicantId),
                      sectionItem,
                      applicant.applicantId,
                      applicant === applicants[0]
                    )
                  }
                  sectionItem={sectionItem}
                  id={applicant.applicantId}
                  only={hasEmployerInfo(
                    sectionItem.type,
                    A.isNonEmpty(applicant.employers)
                  )}
                  isBorrowerSection={true}
                />
              ))}
            </Col>
          </Col>
        ))}
      </Col>
    </Col>
  );

  const MobileView = (
    <Col gap="md">
      <Col gap="xs" only={isBorrower}>
        <Label className="text-smd text-mbold action-70">Upload & Review</Label>
        <Row>
          Please upload all relevant documentation and review the information
          you have provided.
        </Row>
      </Col>
      <Col className="overflow-hidden">
        <Menu
          menuButton={
            <MenuButton className="button button-secondary w-100 section-btn no-padding">
              <Row
                gap="xs"
                alignVertical="center"
                alignHorizontal="space-between"
              >
                <Row gap="xxxs" alignVertical="center">
                  {SectionLabel(props.model.section.type)}
                  <>
                    {applicantName && (
                      <span className="text-xs">({applicantName})</span>
                    )}
                  </>
                </Row>
                <Icon type="chevron-down"></Icon>
              </Row>
            </MenuButton>
          }
          submenuCloseDelay={40000}
          transition
          direction="bottom"
          align="center"
          viewScroll="auto"
          position="anchor"
          gap={12}
        >
          <>
            {commonApplicationSection.map((sectionItem) => (
              <MenuItem
                key={sectionItem.type}
                onClick={() =>
                  onSectionChange(sectionItem.tabId(), sectionItem)
                }
              >
                <Row gap="xs" padding="sm" alignVertical="center" className="section-item">
                  <Icon type={sectionItem.prefixIconType} />
                  <span className="flex-grow-1">
                    {SectionLabel(sectionItem.type)}
                  </span>
                  <Icon
                    type={sectionItem.suffixIconType(props.model.documents)}
                  />
                </Row>
              </MenuItem>
            ))}
            {applicants.map((applicant) => (
              <Fragment key={applicant.applicantId}>
                <MenuHeader>{applicant.fullName}</MenuHeader>
                <>
                  {borrowerApplicationSections.map(
                    (sectionItem) => (
                      <Col
                        key={sectionItem.type}
                        only={hasEmployerInfo(
                          sectionItem.type,
                          A.isNonEmpty(applicant.employers)
                        )}
                      >
                        <MenuItem
                          onClick={() =>
                            onSectionChange(
                              sectionItem.tabId(applicant.applicantId),
                              sectionItem,
                              applicant.applicantId,
                              applicant === applicants[0]
                            )
                          }
                        >
                          <Row gap="xs" padding="sm" alignVertical="center" className="section-item">
                            <Icon type={sectionItem.prefixIconType} />
                            <span className="flex-grow-1">
                              {SectionLabel(sectionItem.type)}
                            </span>
                            <Icon
                              type={sectionItem.suffixIconType(
                                props.model.documents
                              )}
                            />
                          </Row>
                        </MenuItem>
                      </Col>
                    )
                  )}
                  {applicant !== applicants[A.size(applicants) - 1] && <MenuDivider />}
                </>
              </Fragment>
            ))}
          </>
        </Menu>
      </Col>
    </Col>
  );
  const ViewWrapper = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Portrait",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [DesktopView, MobileView],
  });
  return <>{ViewWrapper}</>;
}

type SectionItemProps = ContentProps & {
  selectedSection: string;
  onSelection: () => void;
  sectionItem: ApplicationSectionItem;
  id?: number;
  only?: O.Option<boolean>;
  isBorrowerSection?: boolean;
};

function SectionItemView(props: SectionItemProps): JSX.Element {
  return (
    <Row
      gap="xs"
      padding="sm"
      className={`text-md pointer
        ${
          props.selectedSection === props.sectionItem.tabId(props.id)
            ? "highlight-selected-menu"
            : ""
        } ${props.isBorrowerSection ? "ml-left-sm" : ""}`}
      onClick={props.onSelection}
      only={props.only}
    >
      <Icon type={props.sectionItem.prefixIconType} />
      <span className="flex-grow-1">
        {SectionLabel(props.sectionItem.type)}
      </span>
      <Icon type={props.sectionItem.suffixIconType(props.model.documents)} />
    </Row>
  );
}

function SectionLabel(sectionType: SectionType): string {
  switch (sectionType) {
    case "Personal Information":
      return "Personal Info";
    case "Employer Information":
      return "Employer Info";
    case "Financial Information":
      return "Financial Info";
    case "Mortgage Information":
      return "Mortgage Info";
    case "Upload Documentation":
      return "Documents";
    case "Verification":
      return "Verification";
  }
}
