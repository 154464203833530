import "@/assets/styles/basic/Button/button.css";
import { Deferred, isLoading } from "@/utils/deferred";
import { useOnly } from "@/utils/new-only";
import { constFalse, identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Option, isNone, isSome } from "fp-ts/lib/Option";
import { ReactNode } from "react";
import { Icon } from "../Icon";
import { TooltipWrapper } from "../Tooltip/TooltipWrapper";

export type ButtonProps<T> = {
  type: "primary" | "secondary" | "tertiary" | "flat" | "inline-inverted";
  children: ReactNode;
  className?: string;
  onClick: Option<() => void>;
  disabledTooltip?: string;
  derferredStatus?: Deferred<T>;
    only?: O.Option<boolean>;
    disabled?: boolean;
};

export function Button<T extends object>(props: ButtonProps<T>): JSX.Element {
  const shouldDisplay = useOnly(props.only);

  const isActionLoading = pipe(
    props.derferredStatus,
    O.fromNullable,
    O.map((v) => isLoading(v)),
    O.fold(constFalse, identity),
  );

  const button = (
    <button
      className={`button button-${props.type} text-md ${props.className ? props.className : ""}`}
          disabled={props.disabled || isNone(props.onClick) || isActionLoading}
      onClick={() =>
        isSome(props.onClick) ? props.onClick.value() : undefined
      }
    >
      {props.children} {isActionLoading ? <Icon type="spinner" /> : <> </>}
    </button>
  );

  const parent = pipe(
    props.disabledTooltip,
    O.fromNullable,
    O.chain((v) => (O.isNone(props.onClick) ? O.some(v) : O.none)),
    O.fold(
      () => button,
      (disabledMessage) => {
        return (
          <TooltipWrapper
            tooltipChildren={O.some(<span>{disabledMessage}</span>)}
            purpose="tooltip"
          >
            {button}
          </TooltipWrapper>
        );
      },
    ),
  );
  return shouldDisplay ? <>{parent}</> : <></>;
}
