// import { useMemo } from "react";
import { Button, Col, Icon, Row } from "@/components/basic";
import { ChildProps, ToLoadingProps } from "@/utils/reducerWithEffect";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import {
  Action,
} from "./action";
import { Model } from "./model";

import "@/assets/styles/basic/Skeleton/style.css";
import {
  ApplicationId,
} from "@/data/payload";
import { FormField } from "@/utils/formField";
import { FlashAdder } from "@/utils/page-types";
import { ViewResolver } from "@/utils/viewResolver";
import { ContentViewV2 } from "./view2";

export type ContentProps = ChildProps<Model, Action> & {
  completeApplication: (applicationId: ApplicationId) => void;
  onFlashAdded: FlashAdder;
  onSummarySheetSelected: (applicationId: ApplicationId) => void;
};

export type Props = ToLoadingProps<ContentProps, Model>;

export type SubPageProps = {
  onScrollToDocuments: O.Option<() => void>;
  children: JSX.Element;
  loNotesFormField: FormField<O.Option<string>>;
  onSaveLoNotes: () => void;
  onLoNotesChanged: (o: string) => void;
  isCreditCheckAuthorized: boolean;
  onExportApplication: O.Option<() => void>;
};

export function View(props: Props): JSX.Element {
  const resolvedView = ViewResolver({
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Mobile-Landscape",
        "Tablet-Landscape",
        "Tablet-Portrait",
      ],
    ],
    resolvedContent: [<DesktopLoadingView />, <MobileLoadingView />],
  });

  return pipe(
    props.model,
    O.fold(
      () => <>{resolvedView}</>,
      (model) => (
        // <ContentView {...{ ...props, model }}></ContentView>
        <ContentViewV2 {...{ ...props, model }}></ContentViewV2>
      ),
    ),
  );
}

function DesktopLoadingView(): JSX.Element {
  return (
    <>
      <Row
        gap="xs"
        className="w-100"
        alignVertical="center"
        alignHorizontal="space-between"
      >
        <Col padding="sm" className="summary-navigation w-25">
          <Button
            className="text-md padding-vertical-lg go-back-btn"
            onClick={O.some(() => window.history.back())}
            type="flat"
          >
            <Icon type="arrow-left" /> Go Back
          </Button>
        </Col>
        <Col alignHorizontal="right" width="2/4">
          <Row gap="xs">
            <div
              className="skeleton"
              style={{ width: "6em", height: "2em" }}
            ></div>
            <div
              className="skeleton"
              style={{ width: "4em", height: "2em" }}
            ></div>
            <div
              className="skeleton"
              style={{ width: "4em", height: "2em" }}
            ></div>
          </Row>
        </Col>
      </Row>
      <div className="grid-2" style={{ gridTemplateColumns: "25% 75%" }}>
        <Col gap="md" padding="md">
          <div className="skeleton" style={{ height: "2em" }}></div>
          <div className="skeleton" style={{ height: "2em" }}></div>
          <div className="skeleton" style={{ height: "2em" }}></div>
        </Col>
        <Col gap="md" padding="md">
          <div className="skeleton" style={{ height: "5em" }}></div>
          <div className="skeleton" style={{ height: "7em" }}></div>
          <div className="skeleton" style={{ height: "4em" }}></div>
        </Col>
      </div>
    </>
  );
}
function MobileLoadingView(): JSX.Element {
  return (
    <>
      <div>
        <Col gap="md" padding="md">
          <div className="skeleton" style={{ height: "4em" }}></div>
          <div className="skeleton" style={{ height: "16em" }}></div>
          <div className="skeleton" style={{ height: "20em" }}></div>
        </Col>
      </div>
    </>
  );
}

