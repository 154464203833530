import { ViewResolver } from "@/utils/viewResolver";
import { Row, Label } from "../../basic";
import { TogglePanel, Col, Table } from "../../basic";
import { CoverSheetEmployer } from "@/data/coversheet";
import { formatCurrency } from "@/utils/formatters";
import * as O from 'fp-ts/Option';

import { PaystubList } from "./PaystubList";

type Props = {
  employer: CoverSheetEmployer;
};

function MonthlyIncomeSummary({ employer }: Props) {
  const totalMonthlyIncome = employer.monthlyTotalIncome;

  const incomeCategories = [
    { label: "Regular Income", value: employer.monthlyRegularIncome.income },
    { label: "Overtime Income", value: employer.monthlyOvertimeIncome.income },
    { label: "Commission Income", value: employer.monthlyCommissionIncome.income },
    { label: "Bonus Income", value: employer.monthlyBonusIncome.income },
    { label: "Tip Income", value: employer.monthlyTipIncome.income },
    { label: "Other Income", value: employer.monthlyOtherIncome.income }
  ].filter(category => category.value > 0);

  return (
    <Col gap="sm">
      <h3>Monthly Income Summary</h3>
      <div className="monthly-income-summary">
        <div className="total-monthly-income">
                  <span className="income-category-label">Total Monthly Income</span><br />
                  {formatCurrency(totalMonthlyIncome)}
        </div>
        {incomeCategories.map(({ label, value }) => (
          <div key={label} className="income-category">
            <span className="income-category-label">{label}</span>
            <span className="income-category-value">{formatCurrency(value)}</span>
          </div>
        ))}
      </div>
          <div style={{ whiteSpace: "pre-wrap" }}>Calculation Explanation: {employer.explanation}</div>
    </Col>
  );
}

function getDesktopColumns() {
  return [
    {
      columnKey: "year",
      header: <div style={{ textAlign: "center" }}>Year</div>,
      headerContainerClassName: "align-center w-100",
      view: (item: CoverSheetEmployer['annualIncomeItems'][0]) => <div className="text-center">{item.year}</div>
    },
    {
      columnKey: "regular",
      header: <div className="text-right">Regular</div>,
      headerContainerClassName: "align-right w-100 p-0",
      view: (item: CoverSheetEmployer['annualIncomeItems'][0]) => <div className="text-right">{formatCurrency(item.regular)}</div>
    },
    {
      columnKey: "overtime",
      header: <div className="text-right">Overtime</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: CoverSheetEmployer['annualIncomeItems'][0]) => <div className="text-right">{formatCurrency(item.overtime)}</div>
    },
    {
      columnKey: "commission",
      header: <div className="text-right">Commission</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: CoverSheetEmployer['annualIncomeItems'][0]) => <div className="text-right">{formatCurrency(item.commission)}</div>
    },
    {
      columnKey: "bonus",
      header: <div className="text-right">Bonus</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: CoverSheetEmployer['annualIncomeItems'][0]) => <div className="text-right">{formatCurrency(item.bonus)}</div>
    },
    {
      columnKey: "tips",
      header: <div className="text-right">Tips</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: CoverSheetEmployer['annualIncomeItems'][0]) => <div className="text-right">{formatCurrency(item.tips)}</div>
    },
    {
      columnKey: "other",
      header: <div className="text-right">Other</div>,
      headerContainerClassName: "align-right w-100",
      view: (item: CoverSheetEmployer['annualIncomeItems'][0]) => <div className="text-right">{formatCurrency(item.other)}</div>
    }
  ];
}

function getMobileColumns() {
  return [
    {
      columnKey: "summary",
      header: <div className="text-left">Annual Income</div>,
      view: (item: CoverSheetEmployer['annualIncomeItems'][0]) => (
        <Col className="data-table-cell mobile-column" padding="sm" gap="xs">
          <Row>
            <Label className="text-mbold">{item.year}</Label>
          </Row>
          {item.regular > 0 && (
            <Row className="space-between">
              <Label>Regular</Label>
              <Label>{formatCurrency(item.regular)}</Label>
            </Row>
          )}
          {item.overtime > 0 && (
            <Row className="space-between">
              <Label>Overtime</Label>
              <Label>{formatCurrency(item.overtime)}</Label>
            </Row>
          )}
          {item.commission > 0 && (
            <Row className="space-between">
              <Label>Commission</Label>
              <Label>{formatCurrency(item.commission)}</Label>
            </Row>
          )}
          {item.bonus > 0 && (
            <Row className="space-between">
              <Label>Bonus</Label>
              <Label>{formatCurrency(item.bonus)}</Label>
            </Row>
          )}
          {item.tips > 0 && (
            <Row className="space-between">
              <Label>Tips</Label>
              <Label>{formatCurrency(item.tips)}</Label>
            </Row>
          )}
          {item.other > 0 && (
            <Row className="space-between">
              <Label>Other</Label>
              <Label>{formatCurrency(item.other)}</Label>
            </Row>
          )}
        </Col>
      )
    }
  ];
}

function AnnualIncomeSummary({ items }: { items: CoverSheetEmployer["annualIncomeItems"] }) {
  return (
    <Col gap="sm">
      <h3>Annual Income Summary</h3>
      <Table 
        data={items}
        columns={ViewResolver({
          viewModes: [
            ["Default"],
            ["Mobile-Portrait", "Mobile-Landscape"]
          ],
          resolvedContent: [
            getDesktopColumns(),
            getMobileColumns()
          ]
        })}
        itemKey={(item) => item.year.toString()}
        pageInfo={O.none}
        isLoading={false}
      />
    </Col>
  );
}

export function EmployerCard({ employer }: Props): JSX.Element {
  const totalMonthlyIncome = employer.monthlyTotalIncome;

  return (
    <TogglePanel 
      title={employer.name}
      subTitle={`Total Monthly Income: ${formatCurrency(totalMonthlyIncome)}`}
    >
      <Col gap="md">
        <MonthlyIncomeSummary employer={employer} />
        <AnnualIncomeSummary items={employer.annualIncomeItems} />
        <PaystubList paystubs={employer.relatedPayStubs} />
      </Col>
    </TogglePanel>
  );
}
