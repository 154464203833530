import { ChildProps } from "@/utils/reducerWithEffect";
import { Model } from "./model";
import {
  Action,
  CitizenshipStatusChanged,
  MaritalStatusChanged,
} from "./action";
import { Col, Label, RadioButton, Select } from "@/components/basic";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import { Eq as EqString } from "fp-ts/string";
import {
  citizenshipStatusOptions,
  MaritalStatus,
  showCitizenshipStatus,
} from "@/data/payload";
import { constant, flow } from "fp-ts/function";
import { useCallback } from "react";
import { ApplicantType } from "@/data/surveyFlowPayload";

export type Props = ChildProps<Model, Action> & {
  applicantType: ApplicantType;
};

export function View(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const maritalStatusRadio = useCallback(
    (maritalStatus: MaritalStatus) => (
      <RadioButton
        label={maritalStatus}
        disabled={model.disableMaritalStatusSelection}
        checked={
          E.isRight(model.maritalStatus.val) &&
          model.maritalStatus.val.right === maritalStatus
        }
        onChange={flow(constant(maritalStatus), MaritalStatusChanged, dispatch)}
      />
    ),
    [model.maritalStatus.val, dispatch, model.disableMaritalStatusSelection],
  );

  const labelReplacement =
    props.applicantType == "CoApplicant" ? "your co-applicant's" : "your";

  return (
    <div className="grid-2 gap-xs collapse-gap-md">
      <Col gap="xs">
        <Label>What is {labelReplacement} current marital status? *</Label>
        {maritalStatusRadio(MaritalStatus.Married)}
        {maritalStatusRadio(MaritalStatus.Unmarried)}
        {maritalStatusRadio(MaritalStatus.Separated)}
      </Col>
      <Col gap="sm">
        <Label>What is {labelReplacement} residency status? *</Label>
        <Select
          options={citizenshipStatusOptions}
          selected={O.fromEither(model.citizenshipStatus.val)}
          valueEq={EqString}
          renderLabel={showCitizenshipStatus}
          onChange={flow(CitizenshipStatusChanged, dispatch)}
        />
      </Col>
    </div>
  );
}
