import { ApplicationType } from "@/data/payload";
import * as O from "fp-ts/lib/Option";
import { Option } from "fp-ts/lib/Option";

export type Model = {
  page: "ApplicationType" | "PropertyType";
  selectedApplicationType: Option<ApplicationType>;
  showExistingDataModal: boolean;
};

export const init = (): Model => ({
  page: "ApplicationType",
  selectedApplicationType: O.none,
  showExistingDataModal: false
});
