import { Col, Label, Row } from "@/components/basic";
import { ApplicationListItem } from "@/data/applicationsList";
import { nesHead } from "@/utils/codecs";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { DateTime } from "luxon";
import { ApplicationProgress } from "../ApplicationProgressBar";
import { TableColumn } from "../basic";
import { Tag } from "../basic/Tag";
import { ApplicationRowContextMenu } from "./application-row-context-menu";
import { Props } from "./types";
import { getProfileId } from "@/utils/user";

export function getTableColumns(
  deviceInfo: string,
  props: Props,
): TableColumn<ApplicationListItem>[] {
  return pipe(
    O.some(deviceInfo),
    O.map((deviceInfo) => {
      switch (deviceInfo) {
        case "Mobile-Portrait":
        case "Mobile-Landscape":
          return columnsForMobile(props);
        case "Tablet-Landscape":
        case "Tablet-Portrait":
          return columnsForTablet(props);
        default:
          return columnsForDesktop(props);
      }
    }),
    O.getOrElse(() => columnsForDesktop(props)),
  );
}

const columnsForDesktop = (
  props: Props,
): TableColumn<ApplicationListItem>[] => {
  const { dispatch } = props;
  return [
    {
      columnKey: "client",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Client</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Row
          padding="sm"
          gap="xs"
          className="data-table-cell cell-first client-column"
          alignVertical="center"
          onClick={() => props.onApplicationSelected(app.applicationId)}
        >
          <Col>
            <div className="client-id">
              {pipe(app.applicantsName, getProfileId)}
            </div>
          </Col>
          <Col>
            <Row>
              <Label className="client-name cp">{app.applicantsName}</Label>
            </Row>
            <Row>
              <Label className="client-description cp">RR --</Label>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      columnKey: "status",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Status</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Col
          padding="sm"
          gap="xl"
          alignVertical="center"
          className="data-table-cell"
        >
          <ApplicationProgress
            status={app.status}
            showProgressBar={true}
          ></ApplicationProgress>
        </Col>
      ),
    },
    {
      columnKey: "mortgageType",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Mortgage type</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Col
          padding="sm"
          gap="xl"
          className="data-table-cell mortagage-type-column"
          alignVertical="center"
        >
          <Tag
            type="neutral"
            iconAlign="none"
            icon={O.some("info")}
            className="text-sm font-weight-500"
          >
            {app.mortgageType}
          </Tag>
        </Col>
      ),
    },
    {
      columnKey: "submitted",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Submitted</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Col
          padding="sm"
          gap="xl"
          className="data-table-cell"
          alignVertical="center"
        >
          <Tag
            type="neutral"
            iconAlign="none"
            icon={O.some("info")}
            className="text-sm font-weight-500"
          >
            {app.dateCreated.toLocaleString(DateTime.DATE_MED)}
          </Tag>
        </Col>
      ),
    },
    {
      columnKey: "assignees",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Assignees</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Col
          padding="sm"
          gap="xl"
          className="data-table-cell"
          alignVertical="center"
        >
          <Label className="font-weight-500 cp black">
            <i className="fa-solid fa fa-user-o ml-right-xxs" />
            {app.assignees
              .map(
                (assignee) =>
                  `${nesHead(assignee.firstName)} ${assignee.lastName}`,
              )
              .join(", ")}
          </Label>
        </Col>
      ),
    },
    {
      columnKey: "creditCheckOk",
      sortable: false,
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Credit Check</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Col
          padding="sm"
          gap="xl"
          className="data-table-cell"
          alignVertical="center"
        >
          {app.isCreditCheckAuthorized ? (
            <Tag
              iconAlign="right"
              icon={O.some("check")}
              className="text-sm font-weight-500"
              type="neutral"
            >
              {" "}
              Authorized
            </Tag>
          ) : (
            <Tag
              iconAlign="right"
              icon={O.some("xmark")}
              className="text-sm font-weight-500"
              type="neutral"
            >
              {" "}
              Not Authorized
            </Tag>
          )}
        </Col>
      ),
    },
    {
      columnKey: "actions",
      header: (
        <Row>
          <></>
        </Row>
      ),
      view: (application: ApplicationListItem) => {
        return (
          <Col
            alignHorizontal="right"
            alignVertical="center"
            className="data-table-cell cell-last actions-cell"
            padding="fallback"
          >
            <ApplicationRowContextMenu
              application={application}
              dispatch={dispatch}
              onSummaryOptionSelected={props.onSummaryOptionSelection}
            />
          </Col>
        );
      },
    },
  ];
};

const columnsForTablet = (props: Props): TableColumn<ApplicationListItem>[] => {
  const { dispatch } = props;
  return [
    {
      columnKey: "client",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Client</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Row
          padding="sm"
          gap="xs"
          className="data-table-cell cell-first client-column"
          alignVertical="center"
          onClick={() => props.onApplicationSelected(app.applicationId)}
        >
          <Col>
            <div className="client-id">
              {pipe(app.applicantsName, getProfileId)}
            </div>
          </Col>
          <Col>
            <Row>
              <Label className="client-name cp">{app.applicantsName}</Label>
            </Row>
            <Row>
              <Label className="client-description cp">RR --</Label>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      columnKey: "status",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Status</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Col
          padding="sm"
          gap="xl"
          alignVertical="center"
          className="data-table-cell"
        >
          {app.status}
        </Col>
      ),
    },
    {
      columnKey: "submitted",
      header: (
        <Row padding="xs">
          <Label className="table-header-label">Submitted</Label>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Col
          padding="sm"
          gap="xl"
          className="data-table-cell"
          alignVertical="center"
        >
          <Tag
            type="neutral"
            iconAlign="none"
            icon={O.some("info")}
            className="text-sm font-weight-500"
          >
            {app.dateCreated.toLocaleString(DateTime.DATE_MED)}
          </Tag>
        </Col>
      ),
    },
    {
      columnKey: "actions",
      header: (
        <Row>
          <></>
        </Row>
      ),
      view: (application: ApplicationListItem) => {
        return (
          <Col
            alignHorizontal="right"
            alignVertical="center"
            className="data-table-cell cell-last actions-cell"
            padding="fallback"
          >
            <ApplicationRowContextMenu
              application={application}
              dispatch={dispatch}
              onSummaryOptionSelected={props.onSummaryOptionSelection}
            />
          </Col>
        );
      },
    },
  ];
};

const columnsForMobile = (props: Props): TableColumn<ApplicationListItem>[] => {
  return [
    {
      columnKey: "application-detail",
      header: (
        <Row>
          <></>
        </Row>
      ),
      view: (app: ApplicationListItem) => (
        <Row
          alignVertical="center"
          gap="xs"
          className="data-table-cell mobile-column"
          padding="xs"
        >
          <Col
            gap="xxxs"
            alignVertical="center"
            grow={1}
            onClick={() => props.onApplicationSelected(app.applicationId)}
          >
            <Row>
              <ApplicationProgress
                status={app.status}
                showProgressBar={false}
              ></ApplicationProgress>
            </Row>
            <Row>
              <Label className="client-name cp">{app.applicantsName}</Label>
            </Row>
            <Row>
              <Label className="date cp">
                {app.dateCreated.toLocaleString(DateTime.DATE_MED)}
              </Label>
            </Row>
          </Col>
          <Col>
            <i className="text-smd fa-solid fa-arrow-right padding-horizontal-xs" />
          </Col>
        </Row>
      ),
    },
  ];
};
