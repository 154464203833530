import { Button, Checkbox, Col, Label, Row } from "@/components/basic";
import { deferredToOption, isResolved } from "@/utils/deferred";
import { ChildProps } from "@/utils/reducerWithEffect";
import * as E from "fp-ts/lib/Either";
import { constFalse, constant, flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { useContext, useMemo } from "react";
import {
    Action,
    CientSettingsPayloadPrepared,
    EditCompanyAction,
    EditUserAction,
    SettingsPayloadPrepared,
    SmsNotificationsToggled,
    UserPayloadPrepared,
} from "./action";
import * as EditCompany from "./EditCompany";
import * as EditUser from "./EditUser";
import { Model } from "./model";
import { RouterContext } from "@/utils/router-context";
import { FlashAdder } from "@/utils/page-types";
import { ViewResolver } from "@/utils/viewResolver";
import * as Skeleton from '../basic/Loaders/skeleton'

export type Props = ChildProps<Model, Action> & {
    flashAdder: FlashAdder;
};

export function View(props: Props): JSX.Element {
    const { model } = props;

    const { router } = useContext(RouterContext);

    const classNames = ViewResolver({
        viewModes: [["Default"], ["Mobile-Landscape", "Mobile-Portrait"]],
        resolvedContent: ["", "settings-screen-mb-view"],
    });
    return (
        <Col
            padding="xs"
            gap="xs"
            className={classNames}
        >
            <Row
                gap="xs"
                className={classNames}
            >
                <Button
                    type={model.page.type === "General" ? "primary" : "secondary"}
                    onClick={O.some(flow(constant("/settings/general"), router.navigate))}
                    className="account-settings-btn"
                >
                    General
                </Button>

                {model.canSeeAccountSettings && (
                    <Button
                        type={model.page.type === "Company" ? "primary" : "secondary"}
                        onClick={O.some(
                            flow(constant("/settings/company"), router.navigate),
                        )}
                        className="account-settings-btn"
                    >
                        Account
                    </Button>
                )}

                {model.canSeeBilling && (
                    <Button
                        type={model.page.type === "Billing" ? "primary" : "secondary"}
                        onClick={O.some(
                            flow(constant("/settings/billing"), router.navigate),
                        )}
                        className="account-settings-btn"
                    >
                        Billing
                    </Button>
                )}

                <Button
                    type={model.page.type === "Notifications" ? "primary" : "secondary"}
                    onClick={O.some(
                        flow(constant("/settings/notifications"), router.navigate),
                    )}
                    className="account-settings-btn"
                >
                    Notifications
                </Button>
            </Row>
            <PageView {...props} />
        </Col>
    );
}

export function PageView(props: Props): JSX.Element {
    const { model, dispatch } = props;

    const { page } = model;

    const settings = useMemo(
        () => pipe(model.accountSettings, deferredToOption, O.chain(O.fromEither)),
        [model.accountSettings],
    );

    const paymentProviderUrl = pipe(
        model.paymentProviderMeta,
        O.fromPredicate((v) => v.status == "Resolved"),
        O.chain((v) => O.fromEither(v.value)),
        O.map((v) => v.paymentProviderCustomerPortalUrl),
    );

    let pageContent = <></>;

    const notificationsList = [
        {
            id: 1,
            notification:
                "New assignment notification (when an application has been assigned to you)",
        },
        {
            id: 2,
            notification:
                "Application created (when an application that is assigned to you has been created)",
        },
        {
            id: 3,
            notification:
                "Application submitted (when an application that is assigned to you has been submitted by the borrower)",
        },
    ];

    switch (page.type) {
        case "General":
            {
                switch (model.editUserModel.status) {
                    case "NotStarted":
                        pageContent = <></>;
                        break;
                    case "InProgress":
                    case "Updating":
                        pageContent = <LoaderView />;
                        break;
                    case "Resolved":
                        pageContent = pipe(
                            model.editUserModel.value,
                            E.fold(
                                () => <Label>Failed to load account information</Label>,
                                (editUserModel) => (
                                    <>
                                        <Col className="container mtb-24" padding="lg">
                                            <p className="text-smd">General Information </p>
                                            <Col gap="md">
                                                <label>
                                                    Information populated here will be visible to
                                                    homebuyers.
                                                </label>
                                                <EditUser.View
                                                    model={editUserModel}
                                                    dispatch={flow(EditUserAction, dispatch)}
                                                    onSave={flow(
                                                        UserPayloadPrepared(props.flashAdder),
                                                        dispatch,
                                                    )}
                                                />
                                            </Col>
                                        </Col>
                                    </>
                                ),
                            ),
                        );
                }
            }

            break;
        case "Company":
            {
                switch (model.editCompanyModel.status) {
                    case "NotStarted":
                        pageContent = <></>;
                        break;
                    case "InProgress":
                    case "Updating":
                        pageContent = <LoaderView />;
                        break;
                    case "Resolved":
                        pageContent = pipe(
                            model.editCompanyModel.value,
                            E.fold(
                                () => <Label>Failed to load account information</Label>,
                                (editCompanyModel) => (
                                    <>
                                        <Col className="container mtb-24" padding="lg">
                                            <p className="text-smd">Company Overview </p>
                                            <EditCompany.View
                                                model={editCompanyModel}
                                                dispatch={flow(EditCompanyAction, dispatch)}
                                                onSave={flow(CientSettingsPayloadPrepared, dispatch)}
                                            />
                                        </Col>
                                    </>
                                ),
                            ),
                        );
                        break;
                }
            }
            break;
        case "Notifications":
            {
                pageContent = (
                    <Col
                        padding="lg"
                        gap="sm"
                        className="container mtb-24"
                        width="fit-content"
                    >
                        <Row>
                            <Checkbox
                                checked={pipe(
                                    settings,
                                    O.fold(
                                        constFalse,
                                        ({ smsNotificationsDisabled }) => !smsNotificationsDisabled,
                                    ),
                                )}
                                disabled={!isResolved(model.accountSettings)}
                                label="Allow SMS messaging notifications"
                                onChange={flow(SmsNotificationsToggled, dispatch)}
                            />
                        </Row>
                        <Col>
                            <label className="text-mbold title-header-color">
                                What type of notifications will I receive?
                            </label>
                            {notificationsList.map((itemList) => (
                                <li key={itemList.id} className="wb-bw">
                                    {itemList.notification}
                                </li>
                            ))}
                        </Col>
                        <Row>
                            <Button
                                type="primary"
                                onClick={pipe(
                                    settings,
                                    O.filter((_) => isResolved(model.accountSettings)),
                                    O.map((s) =>
                                        flow(
                                            constant(s),
                                            SettingsPayloadPrepared(props.flashAdder),
                                            dispatch,
                                        ),
                                    ),
                                )}
                            >
                                Save
                            </Button>
                        </Row>
                    </Col>
                );
            }
            break;
        case "Billing": {
            pageContent = (
                <Col className="container mtb-24" padding="lg" width="fit-content">
                    <p className="text-smd">Billing Information </p>
                    <p>
                        Billing information is managed via Stripe. Use the button below to
                        go to the Stripe Customer Portal where you can manage your billing
                        settings and view your billing history.
                    </p>
                    {model.paymentProviderMeta.status == "Resolved" &&
                        pipe(
                            paymentProviderUrl,
                            O.fold(
                                () => (
                                    <p>
                                        There is a problem getting your Stripe account. Please
                                        contact support
                                    </p>
                                ),
                                (v) => (
                                    <a
                                        className="button button-primary text-underline-none"
                                        href={v}
                                    >
                                        Go to Stripe Customer Portal
                                    </a>
                                ),
                            ),
                        )}
                </Col>
            );
        }
    }
    return <>{pageContent}</>;
}
function LoaderView() {
    return <Col padding="sm" gap="sm" grow={1}>
        <Col grow={3} gap="sm" wrap basis="4vh">
            <Skeleton.View></Skeleton.View>
        </Col>
        <Col grow={3} gap="sm" wrap basis="16vh">
            <Skeleton.View></Skeleton.View>
        </Col>
    </Col>
}