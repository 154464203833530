import { Button, Col, Row } from "@/components/basic";
import { flow, identity, pipe } from "fp-ts/lib/function";
import { constant } from "lodash-es";
import * as O from "fp-ts/lib/Option";
import { Action, EditLoNotes, LoNotesChanged, SaveLoNotes } from "../action";
import { Started } from "@/utils/asyncOperationStatus";
import { ApplicationViewMode } from "../model";
import { FormField } from "@/utils/formField";
import { useOnly } from "@/utils/new-only";

type NotesProps = NotesInputProps & {
  dispatch: (action: Action) => void;
  only?: O.Option<boolean>;
  mode: ApplicationViewMode;
};

type NotesInputProps = {
  isEditingLoNotes: boolean;
  loNotesFormField: FormField<O.Option<string>>;
  loanOfficerNote: O.Option<string>;
};

type NotesActionProps = NotesInputProps & {
  onLoNotesChanged: (o: string) => void;
  onSaveLoNotes: () => void;
  onEditLoNotes: () => void;
};

export function LoanOfficerNotesView(props: NotesProps): JSX.Element {
  const { dispatch } = props;

  const shouldDisplay = useOnly(props.only);

  const onEditLoNotes = () => flow(EditLoNotes, dispatch)();
  const onLoNotesChanged = (v: string) =>
    flow(constant(v), LoNotesChanged, dispatch)();
  const onSaveLoNotes = () => flow(Started, SaveLoNotes, dispatch)();
  if (shouldDisplay) {
    switch (props.mode) {
      case ApplicationViewMode.Readonly:
        return (
          <NotesReadOnlyModeView
            isEditingLoNotes={props.isEditingLoNotes}
            loNotesFormField={props.loNotesFormField}
            loanOfficerNote={props.loanOfficerNote}
            onEditLoNotes={onEditLoNotes}
            onLoNotesChanged={onLoNotesChanged}
            onSaveLoNotes={onSaveLoNotes}
          ></NotesReadOnlyModeView>
        );
      case ApplicationViewMode.Edit:
        return (
          <NotesEditModeView
            isEditingLoNotes={props.isEditingLoNotes}
            loNotesFormField={props.loNotesFormField}
            loanOfficerNote={props.loanOfficerNote}
            onEditLoNotes={onEditLoNotes}
            onLoNotesChanged={onLoNotesChanged}
            onSaveLoNotes={onSaveLoNotes}
          ></NotesEditModeView>
        );
    }
  } else {
    
    return <></>;
  }

}

function NotesReadOnlyModeView(props: NotesActionProps): JSX.Element {
  return (
    <Col className="application-section">
      <Row
        gap="xs"
        padding="fallback"
        className="text-smd grid-item-3 color-act-70 text-mbold applicant-name"
      >
        <span>Loan Officer Notes</span>
        {!props.isEditingLoNotes ? (
          <Button onClick={O.some(props.onEditLoNotes)} type="flat">
            Edit Note
          </Button>
        ) : (
          <></>
        )}
      </Row>
      <Row gap="md" padding="md">
        {props.isEditingLoNotes ? (
          <Col className="notes-container" grow={1} gap="xs">
            <NotesField {...props}></NotesField>
            <Button type="primary" onClick={O.some(props.onSaveLoNotes)}>
              Save
            </Button>
          </Col>
        ) : (
          <>
            {pipe(
              props.loanOfficerNote,
              O.fold(
                constant(
                  <>
                    <Col gap="xs">
                      <span className="text-grey">
                        There are no notes. You can add notes about the
                        application. This won't be visible to the borrower .{" "}
                      </span>
                      <Button
                        onClick={O.some(props.onEditLoNotes)}
                        type="secondary"
                      >
                        Add Note
                      </Button>
                    </Col>
                  </>
                ),
                (v) => (
                  <>
                    <div className="notes-container read-only">
                      <div className="notes-read-only text-md">{v}</div>
                    </div>
                  </>
                )
              )
            )}
          </>
        )}
      </Row>
    </Col>
  );
}

function NotesEditModeView(props: NotesActionProps): JSX.Element {
  return (
    <Col className="notes-container" grow={1} gap="xs">
      <Row>
        {" "}
        <span>Loan Officer Notes</span>
        {pipe(
          props.loanOfficerNote,
          O.map((v) => v != props.loNotesFormField.raw),
          O.fold(() => props.loNotesFormField.raw?.length > 0, identity)
        ) ? (
          <Button type="flat" onClick={O.some(props.onSaveLoNotes)}>
            Save Notes
          </Button>
        ) : (
          <></>
        )}
      </Row>
      <NotesField {...props}></NotesField>
    </Col>
  );
}

function NotesField(props: NotesActionProps): JSX.Element {
  return (
    <textarea
      className="notes-text-area text-md"
      value={props.loNotesFormField.raw}
      onChange={(e) => props.onLoNotesChanged(e.target.value)}
      required={true}
    />
  );
}
