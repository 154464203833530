import "@/assets/styles/Documents/documents.css";
import "@/assets/styles/Tooltip/tooltip.css";
import { Icon, IconType, Row } from "@/components/basic";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import * as O from "fp-ts/lib/Option";

export type MenuItemDef = {
  icon: IconType;
  label: string;
  onClick: O.Option<() => void>;
};

type Props = { menuItems: MenuItemDef[] };
export function OptionMenu(props: Props): JSX.Element {
  return (
    <Menu
      menuClassName="tooltip-menu inverted"
      arrow={true}
      menuButton={
        <MenuButton className="button button-flat">
          <Icon type="ellipsis-vertical"></Icon>
        </MenuButton>
      }
      transition
      position="anchor"
      reposition="auto"
    >
      {props.menuItems.map(({ icon, label, onClick }, i) => {
        const menuContent = (
          <Row
            alignHorizontal="space-between"
            gap="xxs"
            grow={1}
            className="text-xs"
          >
            <Row
              alignHorizontal="left"
              gap="xxs"
              grow={1}
              width="auto"
              wrap={false}
            >
              <Icon type={icon} />
              <div>{label}</div>
            </Row>
            <Icon type="chevron-right" />
          </Row>
        );
        return (
          <MenuItem
            key={i}
            disabled={O.isNone(onClick)}
            onClick={() => (O.isSome(onClick) ? onClick.value() : undefined)}
          >
            {menuContent}
          </MenuItem>
        );
      })}
    </Menu>
  );
}
