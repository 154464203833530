import { Button, Icon, Col, Row } from "@/components/basic";
import { ApplicationStatusType } from "@/data/payload";
import { RouteNames } from "@/routing/routes";
import { RouterContext } from "@/utils/router-context";
import { useRights } from "@/utils/use-rights";
import { ViewResolver } from "@/utils/viewResolver";
import { useContext, useMemo } from "react";
import * as O from "fp-ts/lib/Option";
import { ContentProps } from "../view";
import { flow, pipe } from "fp-ts/lib/function";
import {
    ExportDocuments,
    MoveToEditMode,
    SaveSurvey,
    ShowExportWarning,
} from "../action";
import { Started } from "@/utils/asyncOperationStatus";
import { ClientStatus } from "@/data/client";
import { ApplicationViewMode, result, SectionType } from ".././model";
import * as Documents from "@/components/Documents";

export function ActionPanelView(props: ContentProps): JSX.Element {
    const { model, dispatch, onSummarySheetSelected } = props;
    const payload = model.application;
    const { router } = useContext(RouterContext);
    const { isBackOfficeUser } = useRights();
    const surveyValidationResult = useMemo(() => result(model), [model]);
    const status = model.application.status;

    const shouldCollapseView = ViewResolver({
        viewModes: [["Mobile-Portrait"], ["Default"]],
        resolvedContent: [true, false],
    });

    const collapseViewClass = ViewResolver({
        viewModes: [["Default"], ["Mobile-Portrait"]],
        resolvedContent: ["", "w-100"],
    });

    const isApplicationEditable =
        payload.status === ApplicationStatusType.Exported ||
            payload.status === ApplicationStatusType.Archived
            ? false
            : true;

    const onEdit = flow(MoveToEditMode, dispatch);

    const onExportApplication = useMemo(
        () =>
            pipe(
                props.model.clientStatus,
                O.chain((clientStatus) =>
                    clientStatus.status === ClientStatus.Restricted ||
                        clientStatus.status === ClientStatus.Inactive
                        ? O.none
                        : O.some(true)
                ),
                O.chain(() => {
                    if (
                        ![
                            ApplicationStatusType.Complete,
                            ApplicationStatusType.Exported,
                        ].includes(model.application.status)
                    ) {
                        return O.some(flow(ShowExportWarning, dispatch));
                    } else {
                        return O.some(
                            flow(Started, ExportDocuments(model.applicationId), dispatch)
                        );
                    }
                })
            ),
        [
            dispatch,
            props.model.clientStatus,
            model.application.status,
            model.applicationId,
        ]
    );

    const canSaveApplication = (aStatus: ApplicationStatusType) =>
        ![
            ApplicationStatusType.Complete,
            ApplicationStatusType.Exported,
            ApplicationStatusType.Archived,
        ].includes(aStatus);

    const submitSurvey = useMemo(
        () =>
            pipe(
                O.fromPredicate(() => canSaveApplication(status)),
                () => surveyValidationResult,
                O.fromEither,
                O.map((payload) =>
                    flow(Started, SaveSurvey(payload, props.onFlashAdded), dispatch)
                )
            ),
        [status, surveyValidationResult, props.onFlashAdded, dispatch]
    );

    const editableSections: SectionType[] = useMemo(
        () => ["Personal Information", "Financial Information"],
        []
    );

    const { completeApplication: completeApplicationHandler } = props;

    //const completeApplication = useMemo(() =>
    //    pipe(
    //        surveyValidationResult,
    //        O.fromEither,
    //        O.filter(() =>
    //            O.isSome(isBackOfficeUser) // If back-office user, skip document upload check
    //                ? true
    //                : Documents.allFilesUploaded(model.documents) // Borrower must upload all documents
    //        ),
    //        O.filter(() =>
    //            model.mode === ApplicationViewMode.Readonly ||
    //            (model.mode === ApplicationViewMode.Edit && !editableSections.includes(model.section.type))
    //        ),
    //        O.map(() => () => completeApplicationHandler(model.applicationId))
    //    ),
    //    [
    //        surveyValidationResult,
    //        model.documents,
    //        model.applicationId,
    //        completeApplicationHandler,
    //        model.mode,
    //        model.section.type,
    //        editableSections,
    //        isBackOfficeUser, // Ensure re-evaluation when role changes
    //    ]
    //);

    const completeApplication = useMemo(() =>
        pipe(
            surveyValidationResult,
            O.fromEither,
            O.chain(() => {

                const allDocumentsUploaded = Documents.allFilesUploaded(model.documents);

                if (!allDocumentsUploaded && !O.isSome(isBackOfficeUser)) {
                    return O.none;
                }

                const isModeAllowed =
                    model.mode === ApplicationViewMode.Readonly ||
                    (model.mode === ApplicationViewMode.Edit &&
                        !editableSections.includes(model.section.type));


                if (!isModeAllowed) {
                    return O.none;
                }

                return O.some(() => completeApplicationHandler(model.applicationId));
            })
        ),
        [
            surveyValidationResult,
            model.documents,
            model.applicationId,
            completeApplicationHandler,
            model.mode,
            model.section.type,
            editableSections,
        ]
    );


    const borrowerCanSubmit = O.isSome(completeApplication);
    const isBorrower = !O.getOrElse(() => false)(isBackOfficeUser);

    // Disable the button only if the user is NOT a back-office user AND cannot submit
    const submitButtonDisabled = isBorrower && !borrowerCanSubmit;

    const saveBtnOnly = pipe(
        O.some(canSaveApplication(model.application.status)),
        O.map(
            (v) =>
                v &&
                model.mode === ApplicationViewMode.Edit &&
                model.section.type !== "Upload Documentation" &&
                model.section.type !== "Verification"
        )
    );


    const editBtnOnly = pipe(
        model.mode,
        O.fromNullable,
        O.map(
            (v) =>
                v === ApplicationViewMode.Readonly &&
                canSaveApplication(model.application.status) &&
                editableSections.includes(model.section.type)
        )
    );

    const submitBtnHiddenStatus: ApplicationStatusType[] = [
        ApplicationStatusType.Complete,
        ApplicationStatusType.Archived,
        ApplicationStatusType.Exported,
    ];

    const submitBtnOnly = pipe(
        isBackOfficeUser,
        O.fold(
            () => pipe(
                model.application.status,
                O.fromNullable,
                O.chain((status) =>
                    submitBtnHiddenStatus.includes(status) ? O.none : O.some(true) // Ensure None is only returned if status is hidden
                )
            ),
            () => O.some(true) // If back-office user, always show the button
        )
    );

    const exportBtnOnly = pipe(
        isBackOfficeUser,
        O.fold(
            () => O.none, // If isBackOfficeUser is None, hide the button
            (isUser) =>
                isUser
                    ? O.some(
                        model.application.status === ApplicationStatusType.Complete ||
                        model.application.status === ApplicationStatusType.Exported
                    )
                    : O.none // If not a back-office user, hide the button
        )
    );




    const summaryBtnOnly = pipe(
        isBackOfficeUser,
        O.fold(
            () => O.none, // If isBackOfficeUser is None, hide the button
            (isUser) =>
                isUser
                    ? O.some(
                        model.application.status === ApplicationStatusType.Complete ||
                        model.application.status === ApplicationStatusType.Exported
                    )
                    : O.none // If not a back-office user, hide the button
        )
    );

    const buttons = (
        <>
            <Button
                type="flat"
                only={isBackOfficeUser}
                onClick={O.some(() => router.navigate(RouteNames.CLIENTS_LISTING))}
                className={`text-md go-back-btn ${collapseViewClass}`}
            >
                <Icon type="arrow-left" /> Go Back
            </Button>

            {O.isSome(summaryBtnOnly) && (
                <Button
                    type="primary"
                    onClick={O.some(() => {
                        onSummarySheetSelected(model.applicationId);
                    })}
                    only={summaryBtnOnly}
                    className={`${collapseViewClass}`}
                >
                    Summary
                </Button>
            )}



            {O.isSome(exportBtnOnly) && (
                <Button
                    type="primary"
                    onClick={onExportApplication}
                    only={exportBtnOnly}
                    className={`${collapseViewClass}`}
                    disabledTooltip="Restricted mode"
                >
                    Export
                </Button>
            )}


            <Button
                type="secondary"
                onClick={isApplicationEditable ? O.some(onEdit) : O.none}
                className={`${collapseViewClass}`}
                only={editBtnOnly}
            >
                Edit
            </Button>

            <Button
                type="secondary"
                onClick={submitSurvey}
                only={saveBtnOnly}
                className={`${collapseViewClass}`}
            >
                Save
            </Button>

            {O.isSome(submitBtnOnly) && (
                <Button
                    type="primary"
                    onClick={O.isSome(completeApplication) ? completeApplication : O.some(() => { completeApplication })} // Ensure function is always callable
                    only={submitBtnOnly}
                    disabled={ submitButtonDisabled }
                    className={`${collapseViewClass}`}
                >
                    Submit
                </Button>
            )}


        </>
    );

    return shouldCollapseView ? (
        <Col gap="xs">{buttons}</Col>
    ) : (
        <Row gap="xs" alignHorizontal="right">
            {buttons}
        </Row>
    );
}
