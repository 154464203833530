import { TogglePanel, Col, Row, Table } from "../../basic";
import { TransformedCoverSheetData } from "@/data/coversheet";
import * as O from 'fp-ts/Option';

type Props = {
  documents: TransformedCoverSheetData["documents"];
};

export function DocumentsSection({ documents }: Props): JSX.Element {
  const sortedDocuments = [...documents].sort((a, b) => {
    const nameCompare = a.applicantName.localeCompare(b.applicantName);
    if (nameCompare !== 0) return nameCompare;
    return a.title.localeCompare(b.title);
  });

  return (
    <TogglePanel title="Documents">
      <Col gap="md">
        <Table
          className="data-table documents-table"
          data={sortedDocuments}
          columns={[
            {
              columnKey: "title",
              header: <div className="text-left">Title</div>,
              view: (item) => (
                <Row
                  className={`data-table-cell cell-first ${item.documentUploaded ? 'uploaded-document' : ''}`}
                  alignVertical="top"
                >
                  <div className="checkmark-container">
                    {item.documentUploaded ? "✓" : ""}
                  </div>
                  <Col gap="xxs" className="title-column">
                    <div>{item.title}</div>
                    {item.subTitle && (
                      <div className="text-sm text-gray-500">{item.subTitle}</div>
                    )}
                  </Col>
                </Row>
              )
            },
            {
              columnKey: "applicantName",
              header: <div className="text-left">Applicant Name</div>,
              view: (item) => (
                <Row className={`data-table-cell cell-last ${item.documentUploaded ? 'uploaded-document' : ''}`} alignVertical="top">
                  <div className="text-left">
                    {item.applicantName}
                  </div>
                </Row>
              )
            }
          ]}
          itemKey={(item) => `${item.applicantName}-${item.title}-${item.subTitle ?? ''}`}
          pageInfo={O.none}
          isLoading={false}
        />
      </Col>
    </TogglePanel>
  );
}
