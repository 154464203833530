import { flow, pipe } from "fp-ts/lib/function";
import { Props } from "./types";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import { Button, Col, Modal } from "../basic";
import { ResetExportStatus } from "./action";
import { showApiError } from "@/utils/request";
import { BounceLoader } from "react-spinners";

export function ExportModal(props: Props): JSX.Element {
  const { model, dispatch } = props;
  return pipe(
    model.exportStatus,
    O.fromPredicate((exportStatus) =>
      ["InProgress", "Resolved"].includes(exportStatus.status),
    ),
    O.map((exportStatus) => {
      switch (exportStatus.status) {
        case "InProgress":
          return (
            <Modal
              onClose={O.none}
              title={O.some("Export Application")}
              className="export-modal"
            >
              <Col alignHorizontal="center" gap="md">
                <BounceLoader color="#2A2250" />
                <Col>Please wait while we are exporting your application.</Col>
              </Col>
            </Modal>
          );
        case "Resolved": {
          const { value } = exportStatus;
          if (E.isLeft(value)) {
            return (
              <Modal
                onClose={O.some(flow(ResetExportStatus, dispatch))}
                title={O.some("Export Application")}
                className="export-modal"
              >
                <Col gap="md" alignHorizontal="center">
                  <Col className="text-lg text-mbold color-text-failed">
                    Failed!
                  </Col>
                  <Col>
                  There was an error exporting the documents.
                  <div>{showApiError(value.left)}</div>
                  </Col>
                  <Button
                    type="primary"
                    onClick={O.some(flow(ResetExportStatus, dispatch))}
                  >
                    Close
                  </Button>
                </Col>
              </Modal>
            );
          } else {
            return (
              <Modal
                onClose={O.some(flow(ResetExportStatus, dispatch))}
                title={O.some("Export Application")}
                className="export-modal"
              >
                <Col gap="md" alignHorizontal="center">
                  <Col className="text-lg text-mbold color-text-success">
                    Success!
                  </Col>
                  <Col>
                    The export package has been successfully exported and
                    downloaded.
                  </Col>
                  <Button
                    type="primary"
                    onClick={O.some(flow(ResetExportStatus, dispatch))}
                  >
                    Close
                  </Button>
                </Col>
              </Modal>
            );
          }
          return <></>;
        }
        default:
          return <></>;
      }
    }),
    O.getOrElse(() => <></>),
  );
}
