
import { Col } from "../../basic";
import { CoverSheetPaystub } from "@/data/coversheet";
import { PaystubCard } from "./PaystubCard";

type Props = {
  paystubs: CoverSheetPaystub[];
};

export function PaystubList({ paystubs }: Props): JSX.Element {
  return (
    <Col gap="sm">
      <h3>Paystubs</h3>
      {paystubs.map((paystub, index) => (
        <PaystubCard key={index} paystub={paystub} />
      ))}
    </Col>
  );
}
