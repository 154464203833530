import { TogglePanel } from "@/components/basic";
import { DocumentTypeId } from "@/data/applicationDocument";
import { flow } from "lodash-es";
import { DocumentsAction, StartManualDocumentSelection } from "../action";
import { ContentProps } from "../view";
import * as Documents from "@/components/Documents";

export function DocumentSectionView(props: ContentProps): JSX.Element {
  const { model, dispatch } = props;
  return (
    <TogglePanel id="documents-section" title="Documents" defaultOpen={true}>
      <Documents.View
        model={model.documents}
        dispatch={flow(DocumentsAction, dispatch)}
        onManualClassification={(documentTypeId: DocumentTypeId) =>
          flow(StartManualDocumentSelection(documentTypeId), dispatch)
        }
      />
    </TogglePanel>
  );
}
