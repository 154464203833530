import { ContentProps } from "../view";
import { DocumentSectionView } from "./documents-section";
import {
  EmployerInformationSectionView,
  EmployerInfoSection,
} from "./employer-section";
import {
  FinancialInformationSectionView,
  FinancialInfoSection,
} from "./financial-information";
import {
  MortgageInformationSectionView,
  MortgageInfoSection,
} from "./mortgage-information";
import {
  PersonalInfoSection,
  PersonalInfoSectionView,
} from "./personal-info-section";
import { VerificationSectionView } from "./verification-section";

export function SectionView(props: ContentProps): JSX.Element {
  const { model } = props;
  switch (model.section.type) {
    case "Personal Information": {
      const sectionProps: PersonalInfoSection = {
        type: model.section.type,
        applicantId: model.section.applicantId,
      };
      return (
        <PersonalInfoSectionView
          {...props}
          personalInfoSection={sectionProps}
        />
      );
    }

    case "Financial Information": {
      const sectionProps: FinancialInfoSection = {
        type: model.section.type,
        applicantId: model.section.applicantId,
      };
      return (
        <FinancialInformationSectionView
          {...props}
          financialInfoSection={sectionProps}
        />
      );
    }
    case "Employer Information": {
      const sectionProps: EmployerInfoSection = {
        type: model.section.type,
        applicantId: model.section.applicantId,
      };
      return (
        <EmployerInformationSectionView
          {...props}
          employerInfoSection={sectionProps}
        />
      );
    }
    case "Mortgage Information": {
      const sectionProps: MortgageInfoSection = {
        type: model.section.type,
        applicantId: model.section.applicantId,
      };
      return (
        <MortgageInformationSectionView
          {...props}
          mortgageInfoSection={sectionProps}
        />
      );
    }
    case "Upload Documentation":
      return <DocumentSectionView {...props} />;
    case "Verification":
      return <VerificationSectionView {...props} />;
  }
}
