import "@/assets/styles/App.css";
import { useApi } from "@/utils/api";
import {
  Effect,
  effectOfAsync,
  noEffect,
  useReducerWithEffects,
} from "@/utils/reducerWithEffect";
import { constant, constFalse, flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as T from "fp-ts/Task";

import { Button, Col, Modal, Row } from "@/components/basic";
import * as SubmitApplication from "@/components/SubmitApplication";
import * as A from "fp-ts/Array";
import { StrictMode, useEffect, useMemo, useRef, useState } from "react";

import * as BorrowerNotificationSettings from "@/components/BorrowerNotificationSettings";
import * as UnFinishedApplication from "@/components/UnFinishedApplication";
import Navigo from "navigo";
import {
  Action,
  BorrowerSettingsAction,
  BorrowerSettingsClosed,
  BorrowerSettingsSelected,
  FlashMessageRemoved,
  HomebuyerLandingSelected,
  Initialized,
  LogoutInitiated,
  OnSummaryPageResume,
  SubmitApplicationClosed,
  SummarySheetViewClosed,
  UnFinishedApplicationExitAction,
  update,
} from "./app/action";
import { AppContent } from "./app/app-content";
import { init, Model, PageType } from "./app/model";
import { FlashMessages } from "./components/basic/FlashMessage/view";
import * as ApplicationMenu from "./components/basic/Menu/ApplicationMenu";
import {
  ProfileMenu,
  ProfileMenuDef,
} from "./components/basic/Menu/ProfileMenu";
import { ApiCallingModal } from "./components/basic/Modal/ApiCallingModal";
import { initTask } from "./routing/init-app-routing";
import { configNavigo } from "./routing/navigo-routes";
import { deferredToOption } from "./utils/deferred";
import { PageTypes } from "./utils/page-types";
import { RedirectState } from "./utils/request";
import {
  ErrorInfoContext,
  RouterContext,
  UserContext,
} from "./utils/router-context";
import { isBackOfficeUser, loginAuthorityFor } from "./utils/user";
import { ViewResolver } from "./utils/viewResolver";
import { User } from "./data/client";
import * as SummarySheet from "@/components/SummarySheet";

const checkIfLoginSubdomain = () =>
  window.location.host.startsWith("login.appcatcher.com");

function App(): JSX.Element {
  const [api, logoutTask, performUserFlow, handleRedirectTask] = useApi();
  const [isAppInited, setIsAppInited] = useState(false);

  const router = useRef<Navigo>(new Navigo("/"));
  const [message, setMessage] = useState<O.Option<string>>(O.none);

  const initialModelEffects = useMemo((): T.Task<[Model, Effect<Action>]> => {
    if (checkIfLoginSubdomain()) {
      return T.of([init(), noEffect]);
    }
    return initTask(api, handleRedirectTask, router.current);
  }, [api, handleRedirectTask]);

  useEffect(() => {
    //Though navigo documentation says that it calls resolve on popState, it doesn't work for us.
    window.onpopstate = () => router.current.resolve();
    const helloListener = (e: Event) => {
      console.log("Appinited", (e as CustomEvent).detail);
      setIsAppInited(true);
    };
    window.addEventListener("app-init", helloListener);

    return () => {
      window.onpopstate = null;
      window.removeEventListener("app-init", helloListener);
    };
  }, []);

  const [model, dispatch] = useReducerWithEffects(
    update(api, performUserFlow, setMessage, router.current),
    init(),
    effectOfAsync(initialModelEffects, ([m, e]: [Model, Effect<Action>]) => {
      return Initialized(e)(m);
    })
  );

  useEffect(() => {
    if (checkIfLoginSubdomain()) {
      const currentLocation = new URL(window.location.href);

      (async () => {
        try {
          const stateQueryParamValue =
            currentLocation.searchParams.get("state");
          if (!stateQueryParamValue) {
            window.location.assign("https://dev.appcatcher.com");
            return;
          }
          const ourStateParams = JSON.parse(
            decodeURIComponent(
              decodeURIComponent(stateQueryParamValue).split("|")[1]
            )
          );
          const redirectionParams: RedirectState = ourStateParams;
          if (redirectionParams.originDomain.endsWith(".appcatcher.com")) {
            currentLocation.hostname = redirectionParams.originDomain;
            window.location.assign(currentLocation.toString());
          } else {
            window.location.assign("https://dev.appcatcher.com");
          }
        } catch (e) {
          window.location.assign("https://dev.appcatcher.com");
        }
      })();
    } else {
      configNavigo(
        router.current,
        dispatch,
        model,
        isAppInited,
        api,
        performUserFlow,
        handleRedirectTask
      );
      router.current.resolve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.user, isAppInited]);

  const handleBorrowerSettingsSave = (
    model: BorrowerNotificationSettings.Model
  ) =>
    BorrowerNotificationSettings.onBorrowerSettingsSave(
      pipe(model.accountSettings, deferredToOption, O.chain(O.fromEither))
    )(model, flow(BorrowerSettingsAction, dispatch));

  const defaultMenuItems = (user: User): ProfileMenuDef[] => [
    {
      label: "Settings",
      onClick: O.some(
        isBackOfficeUser(user)
          ? flow(constant("/settings/general"), router.current.navigate)
          : flow(constant(user), BorrowerSettingsSelected, dispatch)
      ),
    },

    {
      label: "Logout",
      onClick: O.some(flow(constant(logoutTask), LogoutInitiated, dispatch)),
    },
  ];
  const backOfficeUserMenuItems = (user: User): ProfileMenuDef[] => [
    {
      label: "Copy Link",
      header: "My shareable link",
      showCopyIcon: true,
      onClick: O.some((e, tooltipRef1) => {
        navigator.clipboard.writeText(
          `${window.location.origin}/applynow?referringLoanOfficerId=${user.userId}`
        );
        if (tooltipRef1) {
          tooltipRef1.current?.open({
            anchorSelect: "#my-element",
            content: "Copied!",
          });
          setTimeout(() => tooltipRef1.current?.close(), 500);
        }
        e.stopPropagation = true;
        e.keepOpen = true;
      }),

      hasDivider: true,
    },
    {
      label: "Account",
      onClick: O.some(
        router?.current
          ? flow(constant("/settings/company"), router.current.navigate)
          : flow(constFalse)
      ),
    },
  ];
  const menuItems = pipe(
    model.user,
    O.fold(
      () => [],
      (user) => {
        let items = defaultMenuItems(user);
        // if (isSuperUser(user)) {
        //   items =  A.concat(items)(superUserMenuItems);
        // }else if(isLoanOfficer(user)) {
        //   items = A.concat(items)(loanOfficerMenuItems(user));
        // }
        if (isBackOfficeUser(user)) {
          items = A.concat(items)(backOfficeUserMenuItems(user));
        }
        return items;
      }
    )
  );

  let appLevelModal = <></>;

  const hasParentPadding = (pageType: PageType) =>
    ![PageTypes.ApplyNow, PageTypes.InvitePage].includes(pageType as PageTypes);

  if (O.isSome(model.dialog)) {
    switch (model.dialog.value.type) {
      case "CompleteApplication":
        appLevelModal = (
          <Modal onClose={O.some(() => flow(SubmitApplicationClosed, dispatch)())} title={O.none}>
            <SubmitApplication.View
              model={model.dialog.value.model}
              goToPortal={(application) => {
                flow(SubmitApplicationClosed, dispatch)();
                flow(constant(application), O.some, HomebuyerLandingSelected, dispatch)();
              }}
              onClose={() => flow(SubmitApplicationClosed, dispatch)()}
            />
          </Modal>
        );
        break;
      case "BorrowerSettings":
        appLevelModal = O.isSome(model.user) ? (
          <ApiCallingModal
            actionTitle="Ok"
            title={O.some("Notification Settings")}
            dialogApiResult={model.dialog.value.model.dialogApiResult}
            actionHander={O.some(() => {
              if (
                O.isSome(model.dialog) &&
                model.dialog.value.type === "BorrowerSettings"
              ) {
                return handleBorrowerSettingsSave(model.dialog.value.model);
              }
              return () => {};
            })}
            cancelHandler={O.some(() =>
              flow(BorrowerSettingsClosed, dispatch)()
            )}
            onClose={O.some(() => flow(BorrowerSettingsClosed, dispatch)())}
          >
            <BorrowerNotificationSettings.View
              model={model.dialog.value.model}
              dispatch={flow(BorrowerSettingsAction, dispatch)}
            ></BorrowerNotificationSettings.View>
          </ApiCallingModal>
        ) : (
          <></>
        );
        break;
      case "UnFinishedApplicationExit":
        appLevelModal = O.isSome(model.user) ? (
          <ApiCallingModal
            actionTitle="Yes - Leave application"
            title={O.some("You are leaving an unfinished application")}
            dialogApiResult={model.dialog.value.model.dialogApiResult}
            actionHander={O.some(() => {
              if (
                O.isSome(model.dialog) &&
                model.dialog.value.type === "UnFinishedApplicationExit"
              ) {
                return UnFinishedApplication.onUserUnFinishedExit(
                  flow(UnFinishedApplicationExitAction, dispatch)
                );
              }
              return () => {};
            })}
            cancelHandler={O.some(() => flow(OnSummaryPageResume, dispatch)())}
            cancelTitle="No - Continue uploading documents"
            type="UnFinishedApplicationExit"
            onClose={O.some(() => flow(OnSummaryPageResume, dispatch)())}
          >
            <UnFinishedApplication.View></UnFinishedApplication.View>
          </ApiCallingModal>
        ) : (
          <></>
        );
        break;

      case "SummarySheetView":
        appLevelModal = (
          <Modal
            onClose={O.some(() => flow(SummarySheetViewClosed, dispatch)())}
            title={O.some("Application Summary")}
            className="summary-sheet-view-modal"
          >
            <SummarySheet.View
              model={model.dialog.value.model}
              onClose={() => flow(SummarySheetViewClosed, dispatch)()}
            />
          </Modal>
        );
    }
  }

  return (
    <RouterContext.Provider value={{ router: router.current }}>
      <UserContext.Provider
        value={{ user: model.user, clientStatus: model.clientStatus }}
      >
        <ErrorInfoContext.Provider value={{ message, setMessage }}>
          {pipe(
            message,
            O.fold(
              () => <></>,
              (message) => (
                <Modal
                  onClose={O.some(flow(constant(O.none), setMessage))}
                  title={O.some("You got an eror")}
                >
                  {message}
                  <Button
                    onClick={O.some(flow(constant(O.none), setMessage))}
                    type="primary"
                  >
                    Ok
                  </Button>
                </Modal>
              )
            )
          )}
          <StrictMode>
            <Row className="app-container">
              <FlashMessages
                messages={model.flashMessages}
                onRemoveMessage={(v: number[]) =>
                  flow(constant(v), FlashMessageRemoved, dispatch)()
                }
              />
              <Col grow={1} background="whiteSmoke">
                {O.isSome(model.user) ? (
                  <ApplicationMenu.PrimaryMenuViewWrapper
                    model={model}
                    dispatch={dispatch}
                    router={router.current}
                  >
                    <ProfileMenu
                      user={model.user.value}
                      menuItems={menuItems}
                    />
                  </ApplicationMenu.PrimaryMenuViewWrapper>
                ) : (
                  <></>
                )}
                <Row grow={1}>
                  <Col className="nav-content-flow">
                    <ApplicationMenu.NavigationMenuView
                      router={router.current}
                    ></ApplicationMenu.NavigationMenuView>
                  </Col>
                  <Col
                    grow={1}
                    padding="fallback"
                    basis={ViewResolver({
                      viewModes: [["Default"], ["Desktop-Landscape"]],
                      resolvedContent: ["auto", "fit-content"],
                    })}
                    className={`${O.isSome(model.user) ? `app-content-flow ${hasParentPadding(model.page.type) ? "global-padding" : ""}` : ""}`}
                  >
                    <AppContent
                      model={model}
                      dispatch={dispatch}
                      onSignin={() => {
                        performUserFlow({
                          authority: loginAuthorityFor("TEAM_SIGNIN"),
                          state: {},
                          loginHint: JSON.stringify({}),
                        })();
                      }}
                      router={router.current as Navigo}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {appLevelModal}
          </StrictMode>
        </ErrorInfoContext.Provider>
      </UserContext.Provider>
    </RouterContext.Provider>
  );
}

export default App;
