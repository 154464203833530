import * as FinancialInformation from "@/components/FinancialInformation";
import { Action, ApplicantId, FinancialInformationAction, MoveToEditMode } from "../action";
import {
  ApplicantPayload,
  ApplicationStatusType,
  RetirementAccount,
  showBankruptcyStatusType,
  showIncomeSourceType,
  showRetirementAccountType,
} from "@/data/payload";
import { ContentProps } from "../view";
import { ApplicationViewMode } from "../model";
import { TogglePanel, Row, Icon, Col } from "@/components/basic";
import { ViewResolver } from "@/utils/viewResolver";
import * as A from "fp-ts/lib/Array";
import { flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

export type FinancialInfoSection = {
  applicantId: ApplicantId;
  type: "Financial Information";
};

type FinancialInfoInputProps = ContentProps & {
  financialInfoSection: FinancialInfoSection;
};

type FinancialInfoReadOnlyViewProps = {
  applicant: ApplicantPayload;
  applicantType: ApplicantId;
  onEditSelected: O.Option<() => void>;
};

type FinancialInfoEditViewProps = {
  model: FinancialInformation.Model;
  applicantName: string;
  dispatch: (action: Action) => void;
  applicantType: ApplicantId;
};

export function FinancialInformationSectionView(
  props: FinancialInfoInputProps
): JSX.Element {
  const { model, dispatch, financialInfoSection } = props;

   const status = model.application.status;
  
    const canSaveApplication = (aStatus: ApplicationStatusType) =>
        ![
          ApplicationStatusType.Complete,
          ApplicationStatusType.Exported,
          ApplicationStatusType.Archived,
        ].includes(aStatus);
  
    const canEdit = pipe(
      status,
      O.fromPredicate((v) => canSaveApplication(v)),
      O.map(() => flow(MoveToEditMode, dispatch)),
    );

  if (
    financialInfoSection.applicantId.type === "Primary" &&
    model.mode === ApplicationViewMode.Readonly
  ) {
    const readOnlyViewProps: FinancialInfoReadOnlyViewProps = {
      applicant: model.application.survey.primaryApplicant,
      applicantType: { type: "Primary" },
      onEditSelected: canEdit
    };
    return <FinancialInformationSectionReadOnlyView {...readOnlyViewProps} />;
  } else if (
    financialInfoSection.applicantId.type === "Primary" &&
    model.mode === ApplicationViewMode.Edit
  ) {
    const editViewProps: FinancialInfoEditViewProps = {
      model: model.primaryApplicant.financialInformation,
      dispatch: dispatch,
      applicantType: { type: "Primary" },
      applicantName: model.primaryApplicant.personalInformation.fullName.raw,
    };
    return <FinancialInformationSectionEditView {...editViewProps} />;
  } else if (
    financialInfoSection.applicantId.type === "CoApplicant" &&
    model.mode === ApplicationViewMode.Readonly
  ) {
    const coApplicantIndex = financialInfoSection.applicantId.index;
    const applicantIndex = model.application.survey.jointApplicants.findIndex(
      (app) => app.applicant.applicantId === coApplicantIndex
    );
    if (applicantIndex === -1) {
      return <></>;
    }
    const applicant = model.application.survey.jointApplicants[applicantIndex];
    const readOnlyViewProps: FinancialInfoReadOnlyViewProps = {
      ...applicant,
      applicantType: { type: "CoApplicant", index: coApplicantIndex },
      onEditSelected: canEdit
    };
    return <FinancialInformationSectionReadOnlyView {...readOnlyViewProps} />;
  } else if (
    financialInfoSection.applicantId.type === "CoApplicant" &&
    model.mode === ApplicationViewMode.Edit
  ) {
    const coApplicantIndex = financialInfoSection.applicantId.index;
    const applicantIndex = model.mortgageInformation.coApplicants.findIndex(
      (app) => app.personalInformation.applicantId === coApplicantIndex
    );
    if (applicantIndex === -1) {
      return <></>;
    }
    const financialInfoModel =
      model.mortgageInformation.coApplicants[applicantIndex]
        .financialInformation;
    const personalInfoModel =
      model.mortgageInformation.coApplicants[applicantIndex]
        .personalInformation;
    const editViewProps: FinancialInfoEditViewProps = {
      model: financialInfoModel,
      dispatch: dispatch,
      applicantType: { type: "CoApplicant", index: coApplicantIndex },
      applicantName: personalInfoModel.fullName.raw,
    };
    return <FinancialInformationSectionEditView {...editViewProps} />;
  } else {
    return <></>;
  }
}

export function FinancialInformationSectionReadOnlyView(
  props: FinancialInfoReadOnlyViewProps
): JSX.Element {
  const { applicant, applicantType } = props;

  const togglePanelClasses = ViewResolver({
    viewModes: [["Default"], ["Tablet-Portrait", "Tablet-Landscape"]],
    resolvedContent: ["grid-3", "grid-2"],
  });
  
  return (
    <>
      <TogglePanel title={applicant.fullName} subTitle={applicantType.type} defaultOpen={true}>
      <div className={togglePanelClasses}>
        <Row
          gap="xs"
          className="text-smd grid-item-3 padding-xs color-act-70 text-mbold"
        >
          <Icon type="piggy-bank" />
          <span>Financial Information</span>
          {O.isSome(props.onEditSelected) && <i className="fa-regular fa-pen-to-square pointer" onClick={props.onEditSelected.value}></i>}
        </Row>

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Current Sources of Income</b>
          {A.isNonEmpty(applicant.incomeSources)
            ? applicant.incomeSources
                .filter(
                  (incomeSource) =>
                    incomeSource.type !== "Other_Alimony_ChildSupport"
                )
                .map((source) => (
                  <span key={source.type}>
                    {showIncomeSourceType(source.type) ?? " - "}
                  </span>
                ))
            : "-"}
        </Col>

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Type of work</b>
          <span>
            {showBankruptcyStatusType(applicant.bankruptcyStatus.type) ?? " - "}
          </span>
        </Col>
        {/* TODO: Render only sources with type "Other"*/}
        <Col
          gap="xs"
          padding="sm"
          className={ViewResolver({
            viewModes: [["Default"], ["Tablet-Portrait", "Tablet-Landscape"]],
            resolvedContent: ["grid-item-1", "column-span-2"],
          })}
        >
          <b>Other Sources of Income</b>
          {A.isNonEmpty(applicant.incomeSources)
            ? applicant.incomeSources
                .filter(
                  (incomeSource) =>
                    incomeSource.type === "Other_Alimony_ChildSupport"
                )
                .map((source) => (
                  <span key={source.type}>
                    {showIncomeSourceType(source.type) ?? " - "}
                  </span>
                ))
            : "-"}
        </Col>

        <hr className="grid-item-3" />

        <Row
          gap="xs"
          className="text-smd grid-item-3 padding-xs color-act-70 text-mbold"
        >
          <span>Additional Financial Information</span>
        </Row>

        <Col gap="xs" padding="sm" className="grid-item-1">
          <b>Financial History</b>
          <span>{applicant.foreclosureOrShortSale ? "Yes" : "No"}</span>
        </Col>

        <Col
          gap="xs"
          padding="sm"
          className={ViewResolver({
            viewModes: [["Default"], ["Tablet-Portrait", "Tablet-Landscape"]],
            resolvedContent: ["grid-item-1", "column-span-2"],
          })}
        >
          <b>Retirement Accounts Held</b>
          {applicant.retirementAccounts &&
          applicant.retirementAccounts.size > 0 ? (
            Array.from(applicant.retirementAccounts).map(
              (account: RetirementAccount, index: number) => (
                <span key={index}>
                  {showRetirementAccountType(account) ?? " - "}
                </span>
              )
            )
          ) : (
            <span> - </span>
          )}
        </Col>
        </div>
      </TogglePanel>
    </>
  );
}

export function FinancialInformationSectionEditView(
  props: FinancialInfoEditViewProps
): JSX.Element {
  const { model, dispatch, applicantType, applicantName } = props;
  return (
    <TogglePanel title={applicantName} subTitle={applicantType.type} defaultOpen={true}>
      <Row
        gap="xs"
        className="text-smd grid-item-3 padding-vertical-xs color-act-70 text-mbold"
      >
        <Icon type="piggy-bank" />
        <span>Financial Information</span>
      </Row>
      <Col gap="xxs">
        <Col>
          <FinancialInformation.View
            model={model}
            dispatch={flow(FinancialInformationAction(applicantType), dispatch)}
          />
        </Col>
      </Col>
    </TogglePanel>
  );
}
